import '@mdi/font/css/materialdesignicons.css'

import 'vuetify/styles'
// import '../assets/main.scss'

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { md3 } from 'vuetify/blueprints'

const HypatiaLearningLightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#f9bd3f',
    'primary-darken-1': '#f9bd3f',
    secondary: '#3262C7',
    'secondary-darken-1': '#284e9f',
    error: '#f44336',
    info: '#ffe599',
    success: '#32b432',
    warning: '#ffdd00'
  }
}

const vuetify = createVuetify({
  components,
  directives,
  ssr: true,
  iconfont: 'mdi',
  blueprint: md3,
  theme: {
    defaultTheme: 'HypatiaLearningLightTheme',
    themes: {
      HypatiaLearningLightTheme
    }
  }
})

export default vuetify
