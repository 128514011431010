/*
 * VUEX getters
 */
export const adminGetters = {
  getPicture(state) {
    return state.picture
  },
  getServerUrl(state) {
    return state.serverUrl
  },
  getRegistryUrl(state) {
    return state.registryUrl
  },
  getEduParams(state) {
    console.log('getEduParams got called')
    return state.eduParams
  },
  getEducatorInfo(state) {
    return state.educatorinfo
  },
  getEducatorUid(state) {
    return state.educatoruid
  },
  getLoggedIn(state) {
    return state.loggedin
  },
  getAccessToken(state) {
    return state.accessToken
  },
  getServerAccessToken(state) {
    return state.serverAccessToken
  },
  getIdToken(state) {
    return state.idtoken
  },
  getAccessTokenExpires(state) {
    return state.accessTokenExpires
  },
  getDisplayName(state) {
    return state.displayName
  },
  getUserid(state) {
    return state.userid
  },
  getAffiliation(state) {
    return state.affiliation
  },
  getGroups(state) {
    return state.groups
  },
  getBGroups(state) {
    return state.bgroups
  },
  getUGroups(state) {
    return state.ugroups
  },
  getOrgGroups(state) {
    return state.orggroups
  },
  getFeideOrganisations(state) {
    return state.feideorganisations
  },
  getGrepKompetanseMaal(state) {
    return state.grepKompetanseMaal
  },
  getLearnerKmsData(state) {
    return state.learnerkmsdata
  },
  getShowIntro(state) {
    return state.showIntro
  },
  getLearnersHomeworkTime(state) {
    return state.learnerhomeworktime
  },
  getLearnersReadingTime(state) {
    return state.learnerreadingtime
  },
  getLearnersSchoolGroupAssesment(state) {
    return state.learnerschoolgroupassessment
  },
  getLearnersSubjectFromSchoolClassGroup(state) {
    return state.learnersubjectschoolclass
  },
  getLearnersSubjectAssesment(state) {
    return state.learnersubjectassessment
  },
  getLearnersDoughnutKmsData(state) {
    return state.setlearnersdoughnutkms
  },
  getSubjectWordCloudData(state) {
    return state.setsubjectwordcloud
  },
  getSearchDate(state) {
    return state.searchdate
  },
  getNewGroupSubject(state) {
    return state.groupSubject
  },
  getLearnersSubjectInformationContribution(state) {
    return state.learnerssubjectinformationcontribution
  },
  getUnreadNotesLearner(state) {
    return state.unreadnoteslearner
  },
  getUnreadNotesSubject(state) {
    return state.unreadnotessubject
  },
  getNumberOfUnreadNotes(state) {
    return state.numberofunreadnotes
  },
  getEducatorCurriculum(state) {
    return state.educatorcurriculum
  },
  getProudOfData(state) {
    return state.proudof
  },
  getAppSecret(state) {
    return state.appsecret
  },
  getSid(state) {
    return state.sid
  },
  getEnvironment(state) {
    return state.environment
  },
  getLearningEnvironment(state) {
    return state.learningenvironment
  },
  getSocialCommonGroup(state) {
    return state.socialcommongroupnroup
  },
  getSocialQuestions(state) {
    return state.socialquestions
  },
  getLearnerSocialMap(state) {
    return state.learnersocialmap
  },
  getPublicKey(state) {
    return state.publicKey
  },
  getLang(state) {
    return state.lang
  },
  getLanguage(state) {
    return state.lang
  },
  getLicense(state) {
    return state.license
  },
  getLogInProcess(state) {
    return state.loginprocess && !state.loggedin
  },
  /* ************************************** */
  /*        NEW DESIGN FROM NICHLAS         */
  /* ************************************** */
  getActiveNavItem(state) {
    return state.activeNav
  },
  getMenuTop(state) {
    return state.setmenutop
  },
  getLoggingIn(state) {
    return state.isloggingin
  },
  getSmallScreen(state) {
    return state.smallscreen
  },
  /* V4L */
  getWrittenAssignmentToBeAssessed(state) {
    return state.writtenassignmenttobeassessed
  },
  getAssignmentComments(state) {
    console.log('GET ASSIGNEMENT COMMENTS (%j)', state.assignmentcomments)
    return state.assignmentcomments
  },
  getGrammarFeedback(state) {
    return state.grammarfeedback
  },
  getNotAssessed(state) {
    return state.notassessed
  },
  getLearnerAssess(state) {
    return state.learnerassess
  },
  getOnlyV4L(state) {
    return state.onlyV4L
  },
  getPlagiarismScanId(state) {
    return state.plagiarismscanid
  },
  getPlagiarismData(state) {
    return state.plagiarismdata
  },
  getOpenGuidancePanel(state) {
    return state.openguidancepanel
  },
  getOne(state) {
    return state.addone
  },
  getLearnerWrittenAssignmentUid(state) {
    return state.learnerwrittenassignment
  },
  getAFLAssignmentsMap(state) {
    return state.aflassignmentsmap
  },
  clearGrammarFeedback(state) {
    state.grammarfeedback = []
  },
  clearAssignmentComments(state) {
    state.assignmentcomments = []
  },
  getAi4AFL(state) {
    return state.ai4afl
  },
  isExperimental(state) {
    return state.experimental
  }
}
