// import { ref, computed } from "vue";
import { defineStore } from 'pinia'

import { adminState } from '@/stores/adminstore/adminState.js'
import { adminGetters } from '@/stores/adminstore/adminGetters.js'
import { adminActions } from '@/stores/adminstore/adminActions.js'

export const adminStore = defineStore('adminstore', {
  state: () => adminState,
  getters: adminGetters,
  actions: adminActions
})

export default adminStore