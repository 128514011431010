export const adminState = {
  serversysinfo: {},
  serverUrl: '',
  registryUrl: '',
  accessToken: '',
  serverAccessToken: '',
  idtoken: {},
  accessTokenExpires: '',
  displayName: '',
  userid: '',
  eduParams: {},
  affiliation: '',
  groups: {},
  loggedin: false,
  bgroups: {},
  ugroups: {},
  orggroups: {},
  feideorganisations: {},
  privateKey: ' ',
  publicKey: '',
  appsecret: '',
  sid: '',
  picture: '',
  learnerinfo: {},
  educatorinfo: {},
  grepKompetanseMaal: {},
  learnerkmsdata: {},
  showIntro: true,
  learnerhomeworktime: {},
  learnerreadingtime: {},
  learnerschoolgroupassessment: {},
  learnersubjectschoolclass: {},
  learnersubjectassessment: {},
  setlearnersdoughnutkms: {},
  setsubjectwordcloud: {},
  searchdate: {},
  groupSubject: {},
  setlearnersgroupinformation: {},
  learnerssubjectinformationcontribution: {},
  unreadnoteslearner: new Map(),
  unreadnotessubject: new Map(),
  numberofunreadnotes: 0,
  educatorcurriculum: new Map(),
  proudof: {},
  environment: 'prod',
  learningenvironment: [],
  schoolclasses: {},
  socialcommongroup: {},
  socialquestions: {},
  schoolinfo: {},
  learnersocialmap: {},
  educatoruid: '',
  lang: 'nob',
  license: false,
  loginprocess: false,
  callback: '',
  callbackstate: '',
  /* ************************************** */
  /*        NEW DESIGN FROM NICHLAS         */
  /* ************************************** */
  theme: 'light',
  activeNav: -1,
  setmenutop: false,
  isloggingin: false,
  hasgrepdata: true,
  smallscreen: true,
  /* V4L */
  writtenassignmenttobeassessed: {},
  assignmentcomments: [],
  grammarfeedback: [],
  notassessed: {},
  learnerassess: [],
  onlyV4L: true,
  plagiarismscanid: '',
  plagiarismdata: {},
  openguidancepanel: -1,
  addone: 0,
  learnerwrittenassignment: '',
  aflassignmentsmap: new Map(),
  ai4afl: false,
  experimental: false
}
