<template>
  <v-container :fluid="true">
    <v-card variant="flat">
      <div class="mdtext" v-html="getHTML()" />
    </v-card>
  </v-container>
</template>

<script>
import DOMPurify from 'dompurify'

import nb from '@/assets/md/landingpage_nb.md'
import en from '@/assets/md/landingpage_en.md'

import { marked } from 'marked'

export default {
  name: 'LandingPage',
  components: {},
  props: {},
  data() {
    return {
      doc: {
        nb,
        en
      }
    }
  },
  computed: {},
  mounted() {},
  beforeMount() {},
  created() {},
  methods: {
    getHTML() {
      // return ''
      return DOMPurify.sanitize(marked.parse(this.getMdFile()))
    },
    getMdFile() {
      let locale = this.$root.$i18n.locale
      if (locale === 'nb') {
        return this.doc.nb
      } else if (locale === 'en') {
        return this.doc.en
      }
    }
  }
}
</script>

<style scoped>
.mdtext {
  padding: 1vh 2vw 1vh 2vw;
}

.medtext p a {
  color: red;
}
</style>
