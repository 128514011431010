// Represents the test web app that uses the AppAuthJS library.
// Copied and adapted from  https://github.com/openid/AppAuth-JS/blob/master/src/app/index.ts
// https://authts.github.io/oidc-client-ts/
//
/* package.json
    "oidc-client-ts": "~3.0.0-beta.10",
*/
import { UserManager } from 'oidc-client-ts'

import os from 'os'

/* Some interface declarations for Material design lite. */

/* an example open id connect provider */
const openIdConnectUrl = 'https://auth.dataporten.no'
const clientId = '2855357a-6f92-4f27-908e-cefb276a7997'
const proclusId = '0cb7c606-4bf2-4cb3-ae9e-be59f3777614'

let feideClientId = ''

// const clientSecret = 'a2d5fb15-a0fb-4538-a5ad-cc1751a53c80'
const scope =
  'userid userinfo-name groups-edu groups-org openid longterm userid-feide id_token token refresh_token userinfo-photo'

// 'userid profile groups-edu groups-org openid longterm userid-feide id_token token refresh_token userinfo-photo'

let redirectUri = ''
let silent_redirectUri = ''
if (os.hostname() === 'v4leducator.localhost') {
  redirectUri = 'http://v4leducator.localhost:8090/callback'
  silent_redirectUri = 'http://v4leducator.localhost:8090/silentCallback'
  feideClientId = clientId
} else {
  if (os.hostname() === 'stage-laerer.v4l.no') {
    redirectUri = 'https://stage-laerer.v4l.no/callback'
    silent_redirectUri = 'https://stage-laerer.v4l.no/silentCallback'
    feideClientId = clientId
  } else if (os.hostname() === 'laerer.v4l.no') {
    redirectUri = 'https://laerer.v4l.no/callback'
    silent_redirectUri = 'https://laerer.v4l.no/silentCallback'
    feideClientId = clientId
  } else if (os.hostname() === 'stage-laerer.proclus.no') {
    redirectUri = 'https://stage-laerer.proclus.no/callback'
    silent_redirectUri = 'https://stage-laerer.proclus.no/silentCallback'
    feideClientId = proclusId
  } else if (os.hostname() === 'laerer.proclus.no') {
    redirectUri = 'https://laerer.proclus.no/callback'
    silent_redirectUri = 'https://laerer.proclus.no/silentCallback'
    feideClientId = proclusId
  }
}

console.log('====================== GET OS HOSTNAME : (%s)', os.hostname())
console.log('====================== GET REDIRECT URI  : (%s)', redirectUri)

/*
oauth.Client = {
  client_id: '2855357a-6f92-4f27-908e-cefb276a7997',
  client_secret:'0d1fa311-332e-42cb-92be-47df438181af',
  token_endpoint_auth_method: 'client_secret_basic',
}
*/
export class AppAuth {
  as = null
  code_verifier = null
  code_challenge = null
  authorizationUrl = null
  code_challenge_method = 'S256'
  current_url = null
  client = {}

  code = ''
  state = ''
  userManager = null

  oidcSettings = {
    authority: openIdConnectUrl,
    client_id: feideClientId,
    // client_secret: clientSecret,
    redirect_uri: redirectUri,
    silent_redirect_uri: redirectUri, //silent_redirectUri,
    //      post_logout_redirect_uri: `${os.hostname()}`,
    post_logout_redirect_uri: `${redirectUri}`,
    response_type: 'code',
    scope: scope
  }

  constructor() {
    console.log('AppAuth constructor got called !')
    console.log(this.oidcSettings)
    this.userManager = new UserManager(this.oidcSettings)
  }

  setCodeStatus(code, state) {
    console.log('setCodeStatus got called:')
    console.log('state: ')
    console.log(state)
    this.code = code
    console.log('code: ')
    console.log(this.code)
    this.state = state
  }

  fetchServiceConfiguration() {
    return new Promise((resolve, reject) => {
      console.log('fetchServiceConfiguration got called !')
      resolve('OK')
    }) // promise
  }

  fetchServiceConfigurationToken(code, state) {
    console.log('fetchServiceConfigurationToken got called !')
    /*
    console.log('code:')
    console.log(code)
    console.log('state:')
    console.log(state)
    */
    return new Promise((resolve) => {
      if (this.userManager) {
        this.userManager.signinCallback().then((res) => {
          console.log('====== signinRedirectCallback returned ... (%j)', res)
          if (res) {
            resolve({
              success: true,
              accessToken: res.access_token,
              expiresIn: res.expires_at,
              idToken: res.id_token
            })
          } else {
            resolve({})
          }
        })
      } else {
        resolve({ success: false, error: true, errorMsg: 'No USER MANAGER' })
      }
    })
  }

  makeAuthorizationRequest() {
    console.log('====== makeAuthorizationRequest got called ...')
    return new Promise((resolve) => {
      // create a request
      if (this.userManager) {
        this.userManager
          .signinRedirect()
          .then((res) => {
            console.log('====== signinRedirectCallback got called ...')
            resolve('ok')
          })
          .catch((err) => {
            console.log('***ERROR*** signinRedirectCallback got called ...')
            console.log(err)
            resolve('error')
          })
      } else {
        resolve('noUserManager')
      }
    }) // promise
  }

  makeRefreshTokenRequest(code) {
    console.log('========= makeRefreshTokenRequest got called ! (' + code + ')')
    return new Promise((resolve) => {
      resolve('ok')
    }) // promise
  }

  makeTokenRequest(code) {
    return new Promise((resolve, reject) => {
      console.log(
        '========= MakeTokenRequest(code) got called ! code:(' + code + ')'
      )
      resolve('ok')
    }) // promise
  }

  loggedIn() {
    return !!this.accessTokenResponse && this.accessTokenResponse.isValid()
  }

  async signOut() {
    // forget all cached token state
    await this.userManager.signoutSilent()
    this.code = null
    this.state = null
    this.accessTokenResponse = undefined
    this.userManager = null
    /*   this.userManager.signOutRedirect({redirectMethod:'replace', redirectTarget: 'self'})
      .then(res => {
        console.log('====== signOutSilent got called ...')
        console.log(res)
      })
      */
  }

  checkForAuthorizationResponse() {
    return new Promise((resolve) => {
      console.log('checkForAuthorizationResponse got called !!')

      resolve('ok')
    }) // promise
  }

  silentSigning() {
    console.log('SILENT SIGNIN GOT CALLED ...')
    console.log(this.userManager)
    return new Promise((resolve, reject) => {
      this.userManager
        .signinSilent()
        .then((res) => {
          console.log('====== silentSigningin got called ...')
          console.log(res)
          resolve(res)
        })
        .catch((err) => {
          console.info(
            '***ERROR*** silentSigningin failed - must login ...',
            err.toString()
          )
          reject(err)
        })
    }) // promise
  }
}
