import { AppAuth } from '@/auth/appAuthV2.js'
import { CreateHypatiaJWE } from '@/lib/createJWT'
import { FeideTools } from '@/lib/feideTools'
// import router from '@/router/'
import { UdirTools } from '@/lib/udirTools'
import { encodeLoad, decodeLoad } from '@/lib/base64'

import { adminStore } from '@/stores/adminstore.js'

import router from '@/router/'

import jwt from 'jsonwebtoken'

import dayjs from '@/plugins/dayjs.js'
import { toast } from 'vue3-toastify'
import i18n from '@/i18n/i18n-setup.js'
const { t } = i18n.global

var appAuth = new AppAuth()
var feideTools = new FeideTools()

function checkURL(url, port) {
  console.log('checkURL : (' + url + ') (' + port + ')')
  let newUrl = ''
  if (port === null || port === undefined) {
    port = ''
  }
  if (url.slice(0, 5) === 'https' && port === '') {
    newUrl = url
  } else if (url.slice(0, 5) === 'https' && port !== '') {
    newUrl = url + ':' + port
  } else if (url.slice(0, 4) === 'http' && port === '') {
    newUrl = url
  } else if (url.slice(0, 4) === 'http' && port !== '') {
    newUrl = url + ':' + port
  } else if (port === '443') {
    newUrl = 'https://' + url
  } else if (port !== '') {
    newUrl = 'http://' + url + ':' + port
  } else {
    newUrl = 'http://' + url
  }
  return newUrl
}

function checkRegistryURL(url) {
  console.log(
    'CHECK REGISTRY URL (%s) lastchar(%s)',
    url,
    url.charAt(url.length - 1)
  )

  if (url && url.length > 0) {
    let lastChar = url.charAt(url.length - 1)
    console.log('CHECK REGISTRY URL (%s) lastchar(%s)', url, lastChar)
    if (lastChar === '/') {
      return url
    } else {
      return url + '/'
    }
  } else {
    console.error('NO URL PROVIDED !!! ')
    return ''
  }
}

export const adminActions = {
  addRegistryUrl(serverUrl) {
    console.log('addRegistryUrl got called:(' + serverUrl + ')')
    return new Promise((resolve, reject) => {
      // getURLHost(serverUrl)
      let registryUrl = ''
      this.getHTTPSimple(checkRegistryURL(serverUrl) + 'lsregistry/index.json')
        .then((result) => {
          console.log(
            'REGISTRY Server er: (' + JSON.stringify(result.data) + ')'
          )
          if (result.data.registry_server_port === '') {
            registryUrl = result.data.registry_server
          } else {
            registryUrl =
              result.data.registry_server +
              ':' +
              result.data.registry_server_port
          }
          localStorage.setItem('hypregistry', checkRegistryURL(registryUrl))
          this.registryUrl = checkRegistryURL(registryUrl)
          //          console.log('URL (%s) (%s)',this.registryUrl, registryUrl )
          this.getHTTPSimple(this.registryUrl + 'ls/v4lserver')
            .then((result) => {
              console.log('setServerUrl/v4lServer/get got:')
              console.log(result)
              // console.log(result.data)
              if (result.data.error !== undefined) {
                t.error('error.noregistryservice')
                reject({
                  error: '*** ERROR *** no result from registry service.',
                  errormsg:
                    'The Learning Registry service need to be configured !'
                })
              }
              let serverURL = checkURL(result.data.hostname, result.data.port)
              // console.log('---- serverURL (' + serverURL + ')')
              localStorage.setItem('hypsaserver', checkRegistryURL(serverURL))
              this.serverUrl = serverURL
              resolve({
                result: serverUrl
              })
            })
            .catch((error) => {
              console.error(
                '*** ERROR *** addRegistryUrl/getting v4lserver info (' +
                  error +
                  ')'
              )
              reject({
                error: '*** ERROR *** addRegistryUrl/getting v4lserver info :',
                errormsg: error
              })
            })
        })
        .catch((error) => {
          toast.error(t('error.noregistryservice'), { autoClose: false })
          console.error(
            '*** ERROR *** addRegistryUrl getting registry service: '
          )
          console.error(error)
          reject({
            error: '*** ERROR *** addRegistryUrl/getting registry service: ',
            errormsg: error
          })
        })
    }) // promise
  },
  setServerUrl(newServerUrl) {
    console.log('setServerGot called (%s)', newServerUrl)
    this.serverUrl = newServerUrl
  },
  setRegistryUrl(newRegistryUrl) {
    this.registryUrl = newRegistryUrl
  },
  setEduParams(payload) {
    console.log('setEduParams got called: ' + JSON.stringify(payload))
    this.eduParams = payload
  },
  clientLogout() {
    console.log('======= >>>> LOGGING OUT <<<<<< =======')
    this.loggedin = false
    // localStorage.clear()
    localStorage.removeItem('lid')
    localStorage.removeItem('sid')
    localStorage.removeItem('ssa')
    localStorage.removeItem('tokenexpire')
    localStorage.removeItem('verifierState')
    this.setLogInProcess(false)
    let pLoad = {
      p1: this.sid
    }
    this.postHTTP('logout', pLoad)
      .then((res) => {
        console.log('====>>>> WE HAVE LOGGED OUT <==== (' + res.success + ')')
      })
      .catch((err) => {
        console.error('===== ERROR ===== When logging out: ')
        console.log(err)
      })
    appAuth.signOut()
    this.$reset()
  },
  logout2() {
    return new Promise((resolve, reject) => {
      console.log('======= >>>> LOGGING OUT <<<<<< =======')
      this.loggedin = false
      // localStorage.clear()
      localStorage.removeItem('lid')
      localStorage.removeItem('sid')
      localStorage.removeItem('ssa')
      localStorage.removeItem('tokenexpire')
      localStorage.removeItem('verifierState')
      let pLoad = {
        p1: this.sid
      }
      this.postHTTP('logout', pLoad)
        .then((res) => {
          console.log('====>>>> WE HAVE LOGGED OUT <==== (' + res.success + ')')
          appAuth.signOut()
          localStorage.removeItem('tokenexpire')
        })
        .catch((err) => {
          console.error('===== ERROR ===== When logging out: ')
          console.log(err)
        })
        .finally(() => {
          console.log('===== LOGOUT FINALLY =====')
          this.$reset()
          resolve({ success: true })
        })
      this.$router
        .push({
          name: 'MustLogin',
          replace: true
        })
        .catch(() => {})
    }) // promise
  },
  //login() {
  login() {
    console.log('LOGIN got called !')
    return new Promise((resolve, reject) => {
      // login() // v1..
      // appAuth = new AppAuth()
      appAuth
        .fetchServiceConfiguration()
        .then((res) => {
          console.log('=== LOGIN appAuth.fetchServiceConfiguration() returned')
          console.log(res)
          appAuth.makeAuthorizationRequest().then((res2) => {
            console.log('makeAuthorizationRequest returned (' + res2 + ')')
            //            appAuth.checkForAuthorizationResponse()
            resolve({ success: true, msg: 'is Logged in', data: res2 })
          })
        })
        .catch((error) => {
          console.error(
            'fetchServiceConfiguration() had some problems !',
            error
          )
          reject({ error: true, errorMsg: error.toString() })
          throw new Error(
            'appAuth.fetchServiceConfiguration() had some problems ! ' + error
          )
        })
    }) // promise
  },
  checkSSO() {
    console.log('authSSO got called !')
    return new Promise((resolve, reject) => {
      if (this.loggedin) {
        resolve({ success: true, msg: 'is Logged in' })
      } else {
        appAuth
          .silentSigning()
          .then((res) => {
            console.log('SILENT SIGNING returned (%J)', res)
            resolve(res)
          })
          .catch((err) => {
            console.log('ERROR SILENT SIGNING returned (' + err + ')')
            reject(err)
          })
      }
    }) //promise
  },
  loginCallback(payload) {
    console.log('LOGIN CALLBACK got called !' + JSON.stringify(payload))
    return new Promise((resolve, reject) => {
      this.fixServerURL()
      appAuth.checkForAuthorizationResponse().then((res) => {
        console.log(
          '====== GOT checkForAuthorizationResponse ====== (' + res + ')'
        )
        appAuth.setCodeStatus(payload.callbackCode, payload.callbackState)
        appAuth
          .fetchServiceConfigurationToken(
            payload.callbackCode,
            payload.callbackState
          )
          .then((result) => {
            console.log('==== fetchServiceConfigurationToken RESULT : ')
            console.log(result)
            if (result.error) {
              toast.warning(t('toast.errorlogon'))
              this.clientLogout().finally(() => {
                router.push({ name: 'MustLogin' }).catch(() => {})
              })
            } else if (result.success) {
              console.log('==== CALLBACK RESULT : ')
              console.log(result)

              let accessToken = result.accessToken
              //  let tokenExpiresIn = result.expiresIn  // not used...
              let idToken = result.idToken
              var decoded = jwt.decode(idToken)
              // console.log('==== DECODED (%J)', decoded)
              let tokenExpires = decoded.exp
              let extTime = dayjs.unix(tokenExpires)
              // console.log('TOKEN EXPIRES (%s)', extTime.utc().format())
              this.accessToken = accessToken
              this.accessTokenExpires = extTime
              this.idtoken = idToken
              localStorage.setItem('tokenexpire', tokenExpires)
              resolve(accessToken)
            }
            //     resolve('OK')
          })
          .catch((error) => {
            console.error('*** ERROR *** CALLBACK : ' + error)
            reject({
              errorMsg: '*** ERROR *** CALLBACK : ' + error
            })
          })
      }) // checkForAuthorizationResponse
    }) // promise
  },
  validateServerToken() {
    console.log('VALIDATE SERVER TOKEN GOT CALLED !!!')
    return new Promise((resolve) => {
      let serverUrl = localStorage.getItem('hypsaserver')
      let sid = localStorage.getItem('sid')
      let lid = localStorage.getItem('lid')
      if (
        lid === undefined ||
        lid === null ||
        serverUrl === undefined ||
        serverUrl === null ||
        sid === undefined ||
        sid === null
      ) {
        resolve(false)
      } else {
        this.setServerUrl(checkURL(serverUrl))
        this.setSid(sid)
        let load = `{
            "lid": "${lid}"
         }`
        console.log('sid: ' + sid)
        let pLoad = {
          mutate: encodeLoad(load),
          p1: sid
        }
        this.postHTTP('v4ladmin/checkUserSession/hypatiaValidateServer', pLoad)
          .then((response) => {
            console.log('============================ RESPONSE ER =======')
            // let responseData = response.data.dgraphresult
            console.log(response.data)
            resolve({
              success: response.data.success,
              educatorId: response.data.educatorId,
              bearer: response.data.bearer,
              schoolLevel: response.data.schoolLevel,
              idToken: response.data.idToken
            })
          })
          .catch(() => {
            console.log('*** WARN *** SSO NOT VALIDATED: ')
            resolve({ mustlogin: 'User SSO not validated!' })
          })
      }
    })
  },
  refreshToken() {
    console.log('refresh token got called !')
    return new Promise((resolve) => {
      let callbackCode = this.getCallback()
      let callbackState = this.getCallbackState()
      appAuth.setCodeStatus(callbackCode, callbackState)
      appAuth.fetchServiceConfigurationToken(callbackCode, callbackState)

      resolve({
        success: 'Set new expiry'
      })
    })
  },
  setFeideUserInfo(payload) {
    // profilephoto: https://api.dataporten.no/userinfo/v1/user/media/
    // let feideTools = new FeideTools()
    let newUser = false
    return new Promise((resolve, reject) => {
      console.log('setFeideUserInfo got called : (%j)', payload)
      this.setDisplayName(payload.displayName)
      this.setAffiliation(payload.eduPersonAffiliation)
      this.setPicture(payload.picture)
      payload.serviceServerUrl = this.serverUrl
      let tokenPayload = {
        accessToken: payload.accessToken,
        displayName: payload.displayName,
        eduPersonPrimaryAffiliation: payload.eduPersonPrimaryAffiliation,
        encodedAccessToken: payload.encodedAccessToken,
        name: payload.name,
        serviceServerUrl: payload.serviceServerUrl,
        userid: payload.userid
      }
      createJwtTokenForClient(tokenPayload)
        .then((result) => {
          // last part of the token is the secret key..
          this.setServerAccessToken(result.accessToken)
          this.setPublicKey(result.publicKey)
          this.setAppSecret(result.secret)
          this.setSid(result.sid)
          localStorage.setItem('sid', result.sid)
          localStorage.setItem('ssa', result.accessToken)
          //  let secret = result.secret
          //  $cookies.set('secretuuid', secret, '1d', '/', '.ataa.no', true)
          console.log('createJwtTokenForClient returned')
          console.log(result)
          // })
          // .then(() => {
          this.checkCreateMunicipality(payload)
            .then((result2) => {
              console.log('checkCreateMunicipality returned: (%j)', result2)
              payload.municipalityUid = result2.municipalityUID || ''
              this.checkCreateSchool(payload)
                .then((result3) => {
                  console.log('checkCreateSchoolReturned (%j) ', result3)
                  payload.schoolUID = result3.schoolUID || ''
                  console.log('calling checkUserExist !')
                  this.checkUseridExist(payload) // Set UserID and check for existence of user
                    .then((userExistresult) => {
                      console.log('checkUseridExist returned (%j)', result)
                      if (userExistresult.neweducator) {
                        newUser = true
                      }
                      // })
                      // .then(() => {
                      let groups = payload.allGroups
                      let bGroups = feideTools.getFeideGroups(groups, 'b')
                      // console.log('bGROUPS')
                      // console.log(JSON.stringify(bGroups))
                      this.setBGroups(bGroups)
                      let uGroups = feideTools.getFeideGroups(groups, 'u')
                      // console.log('uGROUPS')
                      // console.log(JSON.stringify(uGroups))
                      let orgGroups = feideTools.getFeideOrgGroups(groups)
                      // console.log('orgGROUPS')
                      // console.log(JSON.stringify(orgGroups))
                      this.setOrgGroups(orgGroups)
                      payload.orgGroups = orgGroups
                      payload.bGroups = bGroups
                      payload.uGroups = uGroups
                      let udirTool = new UdirTools()
                      let isHE = this.checkIfHE()
                      udirTool
                        .getUdirEnrichUGroup(uGroups)
                        .then((res) => {
                          this.setUGroups(res)
                          console.log(
                            '==== SHOULD WE Create new educator ====(' +
                              newUser +
                              ')'
                          )
                          this.checkLicense(payload).then((licenseResult) => {
                            if (licenseResult.success) {
                              // We have license
                              if (newUser) {
                                this.createNewEducator(payload)
                                  .then((newEducatorResult) => {
                                    console.log(
                                      '======= CREATE NEW EDUCATOR RETURNED !! '
                                    )
                                    console.log(newEducatorResult)
                                    if (newEducatorResult.success) {
                                      this.populateEducatorInfo()
                                        .then((populateRes) => {
                                          if (populateRes.success) {
                                            resolve({
                                              success: true,
                                              noschool: false,
                                              schoolUid:
                                                result3.schoolUID || null,
                                              haslicence: licenseResult.success,
                                              newuser: newUser,
                                              subjectsUpdated: true
                                            })
                                          } else if (updateRes.error) {
                                            resolve({
                                              success: false,
                                              error: true,
                                              errorMsg:
                                                'Could not populate educator'
                                            })
                                          }
                                        })
                                        .catch((error) => {
                                          console.error(
                                            'ERROR POPULATING EDUCATOR WHEN CREATING A NEW ONE! (s)',
                                            error
                                          )
                                          resolve({
                                            success: false,
                                            error: true,
                                            errorMsg:
                                              'Could not populate educator'
                                          })
                                        })
                                    } else if (newEducatorResult.noschool) {
                                      resolve({
                                        success: true,
                                        noschool: true,
                                        erturnMsg:
                                          '**ERROR** createNewEducator/noSchool: ' +
                                          newEducatorResult.noschool
                                      })
                                    } else if (
                                      newEducatorResult.error !== undefined
                                    ) {
                                      reject({
                                        success: true,
                                        error: false,
                                        errorMsg:
                                          '**ERROR** createNewEducator/had some problems : ' +
                                          newEducatorResult.error
                                      })
                                    }
                                  })
                                  .catch((createError) => {
                                    console.log(
                                      '==ERROR== createNewEducator :',
                                      createError
                                    )
                                    console.log(createError)
                                    reject({
                                      error:
                                        'createNewEducator had some problems' +
                                        createError
                                    })
                                  })
                              } else {
                                // new User
                                this.checkUpdateSubjects(payload).then(
                                  (updateRes) => {
                                    console.log(
                                      'checkUpdateSubjects returned (%j)',
                                      updateRes
                                    )
                                    if (updateRes.success) {
                                      // If not creating new user
                                      resolve({
                                        success: true,
                                        successMsg: 'OK-Educator exists'
                                      })
                                    }
                                  }
                                )
                              }
                            } else {
                              // license
                              resolve({
                                success: false, // No license
                                nolicense: true
                              })
                            }
                          }) // checkLicense
                        }) // getEnrichUGroups
                        .catch((error) => {
                          console.error(
                            '*** ERROR *** udirTool.getUdirEnrichUGroup/catch had some problems :' +
                              error
                          )
                          reject({
                            success: false,
                            error: true,
                            errorMsg:
                              '*** ERROR *** udirTool.getUdirEnrichUGroup/catch had some problems :' +
                              error
                          })
                        })
                      //   } else { // new educator
                      /*
                             this.checkUpdateSubjects(payload).then(updateRes => {
                                 if(updateRes.success) {
                                   // If not creating new user
                                     resolve({
                                     success: true,
                                     successMsg: 'OK-Educator exists'
                                     })
                                   }
                             })
                             */
                      // } // created new user
                      // let norEduOrgNin = feideTools.getFeideNorEduOrgNin(groups)
                      // console.log('norEduOrgNin')
                      // console.log(JSON.stringify(norEduOrgNin))
                    }) // checkUseridExist
                    .catch((error) => {
                      console.error(
                        '*** ERROR *** checkUserID Exists had some problems : ' +
                          error
                      )
                      reject({
                        success: false,
                        error: true,
                        errorMsg:
                          '*** ERROR *** checkUserID Exists had some problems : ' +
                          error
                      })
                    })
                }) // create Schools
                .catch((errorsc) => {
                  console.error('COULD NOT CREATECHECK SCHOOL ...' + errorsc)
                  reject({
                    success: false,
                    error: true,
                    errorMsg: `*** ERROR *** COULD NOT CREATECHECK SCHOOL (${errorsc})`
                  })
                })
            }) // create municipality
            .catch((errorcm) => {
              console.error('COULD NOT CREATE MUNICIPALITY ...', errorcm)
              reject({
                success: false,
                error: true,
                errorMsg: '*** ERROR *** COULD NOT CREATE MUNICIPALITY...',
                errorcm
              })
            })
        }) // then() createJWT token
        .catch((errorjwt) => {
          console.error(
            '*** ERROR *** createJWT Token For Client had some problems : ',
            errorjwt
          )
          reject({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** createJWT Token For Client had some problems : ',
            errorjwt
          })
        })
    }) // promise
  },
  checkUpdateSubjects(payload) {
    console.log('checkUpdateInSubjects got called (%j)', payload)
    return new Promise((resolve) => {
      let data = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: this.sid
      }
      this.postHTTP('v4leducator/checkUpdateInSubjects/updateSubjects', data)
        .then((response) => {
          console.log('heckUpdateInSubjects RESPONSE ER ======= (%j)', response)
          resolve({ success: true })
        })
        .catch((error) => {
          resolve({ success: false, error: true, errorMsg: error })
        })
    }) // promise
  },
  checkIfHE() {
    let oGroups = this.oGroups || []
    if (oGroups.length > 0) {
      for (let i = 0; i < oGroups.length; i++) {
        let group = oGroups[i]
        if (group.orgType.length > 0) {
          for (let j = 0; j < group.orgType.length; j++) {
            let og = group.orgType[j]
            if (og === 'higher_education') {
              toast.info('--- NOW HAVE HE --- (' + group.displayName + ')')
              return true
            }
          }
        }
      }
    }
    return false
  },
  checkLicense(payload) {
    return new Promise((resolve) => {
      let orgData = {}
      console.log('CHECK LICENSE GOT CALLED :' + JSON.stringify(payload))
      let groups = payload.allGroups || []
      for (let i = 0; i < groups.length; i++) {
        let group = groups[i]
        //  console.log('GROUP ER:' + JSON.stringify(group))
        let groupOrgType = ''
        if (group.orgType) {
          groupOrgType = group.orgType[0]
        }
        console.log('GROUP ORG TYPE: (%s)', groupOrgType)
        if (groupOrgType !== '') {
          switch (groupOrgType) {
            case 'higher_education':
              orgData.schoolName = group.displayName
              orgData.schoolNorEduOrgNin = group.norEduOrgNIN
              console.log('GOT HIGHER EDUCATION (%j)', orgData)
              break
            case 'primary_and_lower_secondary_owner':
              orgData.municipalityName = group.displayName
              // orgData.municipalityNorEduOrgNin = group.norEduOrgNIN
              orgData.schoolNorEduOrgNin = group.norEduOrgNIN
              console.log('GOT SCHOOL/HE OWNER ' + JSON.stringify(orgData))
              break
            case 'primary_and_lower_secondary':
              orgData.schoolName = group.displayName
              orgData.schoolNorEduOrgNin = getOrgNIN(group.id)
              console.log('GOT SCHOOL ' + JSON.stringify(orgData))
              break
            default:
              console.log('GOT DEFAULT (%s)', groupOrgType)
          }
        }
      } // for
      let schoolNIN = orgData.schoolNorEduOrgNin
      let load = `{
           query(func: eq(school_norEduOrgNIN, "${schoolNIN}")) @cascade {
              uid
              school_has_license
              school_norEduOrgNIN
           }
       } `
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      console.log('PLOAD ER: (%j)', pLoad)
      this.postHTTP('dgraph-api/dquery/hypatiaCheckLicense', pLoad)
        .then((response) => {
          console.log(
            '============================ CHECK LICENSE RESPONSE ER ======='
          )
          console.log(JSON.stringify(response.data))
          let responseData = response.data.dgraphresult[0] || {}
          console.log(JSON.stringify(responseData))
          console.log(
            'LOGIC CHECK(' +
              (responseData.school_norEduOrgNIN === schoolNIN) +
              ')'
          )
          console.log('NIN(%s) UID(%s)', schoolNIN, responseData.uid)
          console.log(
            'LOGIC CHECK2 (' + (responseData.school_has_license !== '') + ')'
          )
          console.log(responseData.school_has_license)
          if (
            responseData.school_norEduOrgNIN === schoolNIN &&
            responseData.school_has_license !== ''
          ) {
            this.setLicense(true)
            resolve({
              success: true,
              message: 'We have license',
              hasLicense: true,
              noLicense: false
            })
          } else {
            /* No license information registered */
            this.setLicense(false)
            resolve({
              success: false,
              error: true,
              errorMsg: 'No license for school :' + orgData.schoolName,
              hasLicense: false,
              noLicense: true
            })
          }
        })
        .catch((error) => {
          this.setLicense(false)
          console.error(
            '*** ERROR **** checkLicense had some problems ! : ' + error
          )
          resolve({
            success: false,
            error: true,
            errorMsg: 'No license for school :' + orgData.schoolName,
            hasLicense: false,
            noLicense: true
          })
        })
    }) // promise
  },

  checkUseridExist(payload) {
    console.log('checkUseridExist got called:' + payload.eduPersonPrincipalName)
    return new Promise((resolve, reject) => {
      let userid = payload.userid
      let load = `{
        query(func: eq(educator_userid, "${userid}")) @cascade {
          uid
          educator_userid
        }
      }`
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      // console.log('=== ACCESS CLIENT TOKEN === : ' + accessClientToken)
      this.postHTTP('dgraph-api/dquery/hypatiaUserExist', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult || {}
          console.warn('======= RESPONSE DATA FROM checkUserExist ======')
          console.log(responseData)
          if (responseData.noresult) {
            console.log('== WE NEED TO CREATE A NEW EDUCATOR ==')
            resolve({
              error: true,
              success: false,
              neweducator: true,
              successMsg: '== EDUCATOR DOES NOT EXIST/Need to create new =='
            })
          } else {
            this.educatoruid = responseData[0].uid
            console.log('== EDUCATOR ALREADY EXISTS ==')
            resolve({
              success: true,
              ok: '== EDUCATOR ALREADY EXISTS =='
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** checkUseridExist had some problems ! : ' + error
          )
          reject({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check checkUseridExist had some problems: ' + error
          })
        })
    })
  },
  populateEducatorInfo() {
    //    console.log('populateEducatorInfo got called == ')
    return new Promise((resolve) => {
      let userid = this.educatoruid || this.educatorinfo.uid
      console.log('populateEducatorInfo got called == (%s)', userid)
      /* Query should have @cascade  query(func: eq(educator_userid, "${userid}")) { */
      let dgraphQuery = `{
          query(func: uid("${userid}")) {
            uid
            educator_userid
            educator_name
            educator_displayName
            educator_picture
            educator_eduPersonPrincipalName
            educator_eduPersonPrimaryAffiliation
            educatorHasSubject (orderasc: educator_schoolsubject_displayName)
                               @filter(eq(educator_schoolsubject_active, true)) {
              uid
              educator_schoolsubject_displayName
              educator_schoolsubject_grep_code
              educator_schoolsubject_feide_id
              educator_schoolsubject_grep_kmskode
              educatorHasCommonSubjectGroup @filter(eq(commonsubjectgroup_active, true)) {
                uid
                commonsubjectgroup_active
                hasWrittenAssignment {
                  uid
                  writtenassignment_id
                  writtenassignment_learningdiary
                  writtenassignment_title
                  writtenassignment_start_date
                  writtenassignment_end_date
                  writtenassignment_language
                  writtenassignment_num_words
                  writtenassignment_duration
                  writtenassignment_explanation
                  writtenassignment_reviewguidance
                  writtenassignment_assessedby_type
                  writtenassignment_template_document
                  writtenassignment_success_criteria
                  writtenassignment_afl_type
                  writtenassignment_editor
                  writtenassignment_aiContext
                  writtenassignment_aiGenre
                  writtenassignment_aiSentiment
                  writtenassignment_aiPrompt
                  writtenassignment_aiPrompt1
                  writtenassignment_aiPrompt2
                  writtenassignment_aiSuccesscriteria
                  writtenassignment_aiEducatorsubjectcontext
                  writtenassignment_aiEducatorsubjectprompt
                  writtenassignment_competencyGoal {
                    uid
                    educatorcurriculum_competencygoal
                    educatorcurriculum_grepkode
                  }
                  writtenassignmentHasGuidance {
                    uid
                    writtenguidance_id
                    writtenguidance_type
                    writtenguidance_at
                    writtenguidance_explanation
                  }
                  writtenassignmentHasGroupFeedback {
                    uid
                    writtenfeedback_assessedby_type
                    writtenfeedback_assessed_date
                    writtenfeedback_type
                    writtenfeedback_proposal
                  }
                  learnerAssignments: ~learnerwrittenassignment_assignment {
                    uid
                    learnerwrittenassignment_educator_evaluates
                    learnerwrittenassignment_aflstep
                    learnerwrittenassignment_started
                    learnerwrittenassignment_duration
                    learnerwrittenassignment_htmlBid
                    learnerwrittenassignment_langAIBid
                    learnerwrittenassignment_llmBid
                    learnerwrittenassignment_aflfeedback_blob
                    learnerwrittenassignment_numWrittenWords
                    learnerwrittenassignment_num_words
                    learnerwrittenassignment_ended
                    learnerwrittenassignment_time_left
                    learnerwrittenassignment_isAssessed
                    learnerwrittenassignment_assessedDate
                    learnerwrittenassignment_assessedby {
                      uid
                      educator_userid
                      educator_displayName
                    }
                    learnerSubjectInfo: ~hasLearnerSubjectAssignment {
                      uid
                      learner_schoolsubject_feide_id
                      learnerInfo: ~learnerHasSubject {
                        uid
                        learner_displayName
                        learner_eduPersonPrincipalName
                      }
                    }
                    learnerwrittenassignment_hasIndividualFeedback {
                      uid
                      writtenfeedback_assessedby_type
                      writtenfeedback_assessed_date
                      writtenfeedback_type
                      writtenfeedback_proposal
                    }
                  }
                }
              }
            }
            educatorHasSchoolGroup (orderasc: educator_schoolgroup_name)
                                   @filter(eq(educator_schoolgroup_active, true)) {
              uid
              educator_schoolgroup_name
              educator_schoolgroup_feide_id
              educatorHasCommonSchoolGroup {
                uid
              }
            }
            educatorHasTeached {
              uid
              curriculumCovered (orderasc: curriculumTeachedCodeRegistered) {
                curriculumTeachedCode
                curriculumTeachedCodeRegistered
              }
            }
          }
        }
        `
      let pLoad = {
        query: encodeLoad(dgraphQuery),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/hypatiaPopulateEducator', pLoad)
        .then((response) => {
          let responseData = response.data.dgraphresult || {}
          console.log('populateEducatorInfo returned:')
          console.log(response)
          console.log(responseData)
          if (responseData.noresult) {
            console.log('--- NO DATA RETURNED FROM populateEducatorInfo !')
            this.educatorinfo = {}
            resolve({
              nodata: '--- NO DATA RETURNED FROM populateEducatorInfo!'
            })
          } else if (responseData.error) {
            // Could also mean NoDATA
            console.log('==== PUPULATE EDUCATOR DATA has some value')
            console.log(responseData)
            this.educatorinfo = {}
            this.clientLogout()
            resolve({ error: true, success: false })
          } else if (response.data.error) {
            console.log('ERROR FROM POPULATE EDUCATOR SERVICES', response.data)
            this.logout2()
            console.log('AFTER LOGOUT')
            resolve({ error: true, success: false })
          } else {
            console.log(
              '============================ POPULATE EDUCATOR RESPONSE SET ======= (%j)',
              responseData
            )
            this.educatorinfo = responseData[0] || {}
            this.educatoruid = responseData[0].uid || ''
            this.setAi4AFL(this.educatorinfo)
            resolve({ success: true })
          }
        })
        .catch((error) => {
          console.error(
            '*** SERVER ERROR **** populateEducatorInfo had some problems ! : ' +
              error
          )
          toast.warning(
            'Problems populating educator information - please try login again....'
          )
          this.clientLogout()
          resolve({
            error: true,
            success: false,
            errorMsg:
              'Problems populating educator information - please try again....',
            userid: userid
          })
        })
    }) // promise
  },
  findEducatorInfo(payload) {
    console.log('== findEducatorInfo got called == ')
    // console.log(payload)
    return new Promise((resolve) => {
      this.userid = payload.userid
      let userid = payload.userid
      /* Query should have @cascade  query(func: eq(educator_userid, "${userid}")) { */
      let dgraphQuery = `{
          query(func: eq(educator_userid, "${userid}")) {
            uid
            educator_userid
            educator_name
            educator_displayName
            educator_picture
            educator_eduPersonPrincipalName
            educator_eduPersonPrimaryAffiliation
            educatorHasSubject (orderasc: educator_schoolsubject_displayName)
                               @filter(eq(educator_schoolsubject_active, true)) {
              uid
              educator_schoolsubject_displayName
              educator_schoolsubject_grep_code
              educator_schoolsubject_feide_id
              educator_schoolsubject_grep_kmskode
              educatorHasCommonSubjectGroup @filter(eq(commonsubjectGroup, true)) {
                uid
                commonsubjectgroup_active
                hasWrittenAssignment {
                  uid
                  writtenassignment_id
                  writtenassignment_learningdiary
                  writtenassignment_title
                  writtenassignment_start_date
                  writtenassignment_end_date
                  writtenassignment_language
                  writtenassignment_num_words
                  writtenassignment_duration
                  writtenassignment_explanation
                  writtenassignment_reviewguidance
                  writtenassignment_assessedby_type
                  writtenassignment_afl_type
                  writtenassignment_language_ignore_categories
                  writtenassignment_success_criteria
                  writtenassignment_template_document
                  writtenassignment_editor
                  writtenassignment_aiGenre
                  writtenassignment_aiContext
                  writtenassignment_aiSentiment
                  writtenassignment_aiPrompt
                  writtenassignment_aiPrompt1
                  writtenassignment_aiPrompt2
                  writtenassignment_aiSuccesscriteria
                  writtenassignment_aiEducatorsubjectcontext
                  writtenassignment_aiEducatorsubjectprompt
                  writtenassignment_competencyGoal {
                    uid
                    educatorcurriculum_competencygoal
                    educatorcurriculum_grepkode
                  }
                  writtenassignmentHasGuidance {
                    uid
                    writtenguidance_id
                    writtenguidance_type
                    writtenguidance_at
                    writtenguidance_explanation
                  }
                  learnerAssignments: ~learnerwrittenassignment_assignment {
                    uid
                    learnerwrittenassignment_started
                    learnerwrittenassignment_ended
                    learnerwrittenassignment_isAssessed
                    learnerwrittenassignment_assessedby
                    learnerwrittenassignment_assessedDate
                    learnerSubjectInfo: ~hasLearnerSubjectAssignment {
                      uid
                      learner_schoolsubject_feide_id
                      learnerInfo: ~learnerHasSubject {
                        uid
                        learner_displayName
                        learner_eduPersonPrincipalName
                      }
                    }
                  }
                }
              }
            }
            educatorHasSchoolGroup (orderasc: educator_schoolgroup_name){
              uid
              educator_schoolgroup_name
              educator_schoolgroup_feide_id
              educatorHasCommonSchoolGroup {
                uid
              }
            }
            educatorHasTeached {
              uid
              curriculumCovered (orderasc: curriculumTeachedCodeRegistered) {
                curriculumTeachedCode
                curriculumTeachedCodeRegistered
              }
            }
          }
        }
        `
      let pLoad = {
        query: encodeLoad(dgraphQuery),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/findEducatorInfo', pLoad)
        .then((response) => {
          let responseData = response.data.dgraphresult
          console.log('findEducatorInfo returned:')
          console.log(responseData)
          if (responseData.length === 0) {
            console.log('--- NO DATA RETURNED FROM findEducatorInfo !')
            this.educatorinfo = {}
            resolve({
              nodata: '--- NO DATA RETURNED FROM findEducatorInfo!'
            })
          } else if (responseData.error !== undefined) {
            // Could also mean NoDATA
            console.log('==== PUPULATE EDUCATOR DATA has some value')
            console.log(responseData)
            this.educatorinfo = {}
            resolve({
              error: '*** ERROR *** ERROR in query: ' + responseData.error
            })
          }
          console.log(
            '============================ FIND EDUCATOR RESPONSE SET ======= (%j)',
            responseData
          )
          this.educatorinfo = responseData[0]
          this.setEducatorUid(responseData[0].uid || '')
          resolve('OK')
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** findEducatorInfo had some problems ! : ' + error
          )
          resolve({
            error:
              '*** ERROR **** findEducatorInfo had some problems !!' + error
          })
        })
    }) // promise
  },
  createTeachedCurriculum(payload) {
    console.log('createTeachedCurriculum got called')
    return new Promise((resolve) => {
      let load = `{
        "grepCode": "${payload.grepCode}",
        "educatorUid": "${payload.educatorUid}",
        "teachedUid": "${payload.teachedUid}"
      }`
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP(
        'v4leducator/createEducatorTeachedCurriculum/hypatiaCreateEducatedCurriculumTopic',
        pLoad
      )
        .then((response) => {
          console.log('====== CREATE createTeachedCurriculum GOT RESPONSE :')
          console.log(JSON.stringify(response.data))
          let responseData = response.data
          resolve(responseData)
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** createTeachedCurriculum had some problems : ',
            error
          )
          resolve({
            error: '*** ERROR *** createTeachedCurriculum had some problems : ',
            errormsg: error
          })
        })
    }) // promise
  },
  createNewEducator(payload) {
    // let aStore = this // adminStore()
    console.log('createNewEducator got called (%j', payload)
    return new Promise((resolve, reject) => {
      let feideTools = new FeideTools()
      let now = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
      let newID = Date.now()
      /*
      let uGroup = aStore.ugroups
      let bGroup = aStore.bgroups
      let orgGroup = aStore.orggroups
      */
      let uGroup = payload.uGroups
      let bGroup = payload.bGroups
      let orgGroup = payload.orgGroups
      let feideOrgInfo = feideTools.getFeideNorEduOrgNin(orgGroup)
      let feideSchoolClassName = feideTools.getFeideSchoolClass(bGroup)
      let newUGroup = []
      let uLength = uGroup.length
      for (var i = 0; i < uLength; i++) {
        newUGroup.push(uGroup[i])
      }
      console.log('newUGROUP (' + uLength + ')')
      console.log(newUGroup)
      let uGroups = JSON.stringify(newUGroup)

      let newBGroup = []
      let bLength = bGroup.length
      for (var j = 0; j < bLength; j++) {
        newBGroup.push(bGroup[j])
      }
      console.log('newBGROUP (' + bLength + ')')
      console.log(newBGroup)
      let bGroups = JSON.stringify(newBGroup)

      // console.log('UGROUP ER STRINGIFY:')
      // console.log(JSON.stringify(uGroup))

      let pLoad = `{
          "educator_displayName": "${payload.displayName}",
          "educator_eduPersonPrimaryAffiliation": "${payload.eduPersonPrimaryAffiliation}",
          "educator_eduPersonPrincipalName": "${payload.eduPersonPrincipalName}",
          "educator_userid": "${payload.userid}",
          "educator_name": "${payload.name}",
          "educator_id": "${newID}",
          "educator_picture": "${payload.picture}",
          "educator_now": "${now}",
          "educator_schoolOrgNin": "${feideOrgInfo.schoolNorEduOrgNin}",
          "educator_municipalityOrgNin": "${feideOrgInfo.municipalityNorEduOrgNin}",
          "educator_schoolClassName": "${feideSchoolClassName}"
           }`
      let data = {
        mutate: encodeLoad(pLoad),
        p1: this.sid
      }
      this.postHTTP('v4leducator/createEducator/hypatia', data)
        .then((response) => {
          console.log('====== CREATE NEW EDUCATOR GOT RESPONSE :')
          console.log(response.data)
          if (response.data.noschool !== undefined) {
            resolve({
              noschool: 'We did not find any schools...'
            })
          } else if (response.data.error !== undefined) {
            resolve({
              error: 'error: creating educator ' + response.data.error
            })
          } else if (response.data.strange !== undefined) {
            resolve({
              error:
                'error: stranger things have happened... ' +
                response.data.strange
            })
          } else if (uGroups.length > 0 && bGroups.length > 0) {
            // We only create a new classes and subjects if there is anything to create...
            /*
                Since we have successfully created an educator, we create the Undervisning Groups (ugroup),
                and the Basis Groups (bgroup).
                */
            console.log(response.data)
            let newEducatorId = response.data.newEducatoruid
            this.setEducatorUid(newEducatorId)
            let pUgroupLoad = `{
                    "educator_uid": "${newEducatorId}",
                    "educator_ugroup": ${uGroups}
                  }`
            let pBgroupLoad = `{
                  "educator_uid": "${newEducatorId}",
                  "educator_bgroup": ${bGroups}
                 }`
            let uData = {
              mutate: encodeLoad(pUgroupLoad),
              p1: this.sid
            }
            this.postHTTP('v4leducator/createEducatorUgroup/ugroups', uData)
              .then((res) => {
                console.log('=============== UGROUP =======')
                console.log(res.data)
                console.log(res.data.success)
                console.log(res.data.success !== undefined)
                console.log(res.data.success !== '')
                if (res.data.success !== undefined && res.data.success !== '') {
                  console.log('WE WOULD THEN DO BGROUPS')
                  let bData = {
                    mutate: encodeLoad(pBgroupLoad),
                    p1: this.sid
                  }
                  this.postHTTP(
                    'v4leducator/createEducatorBgroup/bgroups',
                    bData
                  )
                    .then((res) => {
                      console.log('=============== BGROUP =======')
                      console.log(res.data)
                      console.log(res.data.success)
                      console.log(res.data.success !== undefined)
                      console.log(res.data.success !== '')
                      if (res.data.error !== undefined) {
                        resolve({
                          error: 'error: in creating uGroups for educator...'
                        })
                      } else if (res.data.success !== undefined) {
                        /* Success in creating uGroups connections */
                        console.log('===== BGROUPS CREATED...')
                      }
                    })
                    .catch((uerror) => {
                      console.log('BGROUP ERROR ', uerror)
                      reject({
                        errorMsg: 'createNewEducatorUGroups has an error: ',
                        uerror
                      })
                    })
                    .finally(() => {
                      console.log('>>> FINALLY -1- BGROUP <<<<<<')
                      resolve({
                        success:
                          'success: we have created educator ' +
                          response.data.success
                      })
                    })
                } else {
                  // uGROUP !Success
                  console.log('***** ERROR ****** in creating UGROUPS ')
                  resolve({
                    error: 'error: in creating uGroups for educator...'
                  })
                }
              }) // createEducatorUgroups
              .catch((uerror) => {
                reject({
                  erorMsg: 'createNewEducatorUGroups has an error: ',
                  uerror
                })
              })
              .finally(() => {
                console.log('>>> FINALLY -2- UGROUP <<<<<<')
                resolve({
                  success:
                    'success: we have created educator ' + response.data.success
                })
              })
              // newTeacherUid = response.data.dgraphresult
              .catch((err) => {
                console.error('createNewEducator had an error: ', err)
                reject({ errorMsg: 'createNewEducator had an error: ', err })
              })
          } // else - we have sucecss
        }) // .then - create EDUCATOR
        .catch((errEducator) => {
          console.log('==== ERROR CREATING EDUCATOR ====', errEducator)
        })
    }) // promise
  },
  setSubjectVisibility(payload) {
    /*
        {"uid":"0x3cbb1d",
        "educator_schoolsubject_displayName":"Matematikk 4A",
        "educator_schoolsubject_grep_code":"MAT0004",
        "educator_schoolsubject_feide_id":"fc:gogroup:spusers.feide.no:u:NO856326499:4amat:2019-08-01:2020-06-15",
        "educator_schoolsubject_grep_kmskode":"KMS928",
        "educatorHasCommonSubjectGroup":{"uid":"0x3c6fa3"}}
        */
    console.log('setSubjectVisibility got called')
    console.log(JSON.stringify(payload))
    return new Promise((resolve) => {
      let load = ` <${payload.educatorHasCommonSubjectGroup.uid}> <commonsubjectgroup_active> "${payload.visibility}" . `
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dmutatenquad/hypatiaSetVisibility', pLoad)
        .then((response) => {
          console.log('====== CREATE setSubjectVisibility GOT RESPONSE :')
          console.log(JSON.stringify(response.data.dgraphresult))
          if (response.data.dgraphresult) {
            let educatorInfo = this.educatorinfo
            updateEducatorInfo(educatorInfo, payload).then((res) => {
              this.educatorinfo = res
              resolve({
                success: 'set visibility...'
              })
            })
          } else {
            resolve({
              error: 'Could not update visibility of subject...'
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** setSubjectVisibility had some problems : ',
            error
          )
          resolve({
            error: '*** ERROR *** setSubjectVisibility had some problems : ',
            errormsg: error
          })
        })
    }) // promise
  },
  setSubjectVisibilityCurriculum(payload) {
    /* Called from CurriculumSelectSubjectGroup different payload than function above */
    /*
          {"uid":"0x3cbb1d",
          "educator_schoolsubject_displayName":"Matematikk 4A",
          "educator_schoolsubject_grep_code":"MAT0004",
          "educator_schoolsubject_feide_id":"fc:gogroup:spusers.feide.no:u:NO856326499:4amat:2019-08-01:2020-06-15",
          "educator_schoolsubject_grep_kmskode":"KMS928",
          "educatorHasCommonSubjectGroup":{"uid":"0x3c6fa3"}}
          */
    console.log('setSubjectVisibilityCurriculum got called')
    return new Promise((resolve) => {
      let load = ` <${payload.uid}> <commonsubjectgroup_active> "${payload.commonsubjectgroup_active}" . `
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }

      this.postHTTP(
        'dgraph-api/dmutatenquad/hypatiaSetSubjectVisibility',
        pLoad
      )
        .then((response) => {
          console.log(
            '====== CREATE setSubjectVisibilityCurriculum GOT RESPONSE :'
          )
          console.log(JSON.stringify(response))
          if (response.data.dgraphresult) {
            let educatorInfo = this.educatorinfo
            updateEducatorInfo(educatorInfo, payload).then((res) => {
              this.educatorinfo = res
              resolve({
                success: 'set visibility. updated educatorInfo..'
              })
            })
          } else {
            resolve({
              error: 'Could not update visibility of subject...'
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** setSubjectVisibilityCurriculum had some problems : ',
            error
          )
          resolve({
            error:
              '*** ERROR *** setSubjectVisibilityCurriculum had some problems : ',
            errormsg: error
          })
        })
    }) // promise
  },
  setContribution(payload) {
    console.log('setContribution got called:')
    console.log(payload)
    return new Promise((resolve) => {
      let currentDate = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
      let load = `{
        "schoolclasscontributionsuid": "${payload.schoolclasscontributionsuid}",
        "contributionvalue": ${payload.contributionvalue},
        "contributiondate": "${currentDate}"
      }`
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('salearner/createLearnerContributionNQUADS/hypatia', pLoad)
        .then((response) => {
          console.log('====== CREATE LEARNER CONTRIBUTION GOT RESPONSE :')
          console.log(response.data)
          if (response.data.dgraphresult.success) {
            resolve({
              success: 'created contributen...'
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** setContribution had some problems : ',
            error
          )
          resolve({
            error: '*** ERROR *** setContribution had some problems : ',
            errormsg: error
          })
        })
    }) // promise
  },
  createAssessment(payload) {
    console.log('createAssessment got called:')
    console.log(payload)
    return new Promise((resolve) => {
      let currentDate = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
      let load = `{
        "subjectuid": "${payload.subjectuid}",
        "assessmentvalue": ${payload.assessmentvalue},
        "learned": "${payload.learned}",
        "done": "${payload.done}",
        "remember": "${payload.remember}",
        "plans": "${payload.plans}",
        "achievements": "${payload.achievements}",
        "diary": "${payload.diary}",
        "notestoteacher": "${payload.notestoteacher}",
        "createddate": "${currentDate}"
      }`
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('salearner/createLearnerAssessmentNQUADS/hypatia', pLoad)
        .then((response) => {
          console.log('====== CREATE LEARNER ASSESSMENT GOT RESPONSE :')
          console.log(response.data)
          if (response.data.dgraphresult.success) {
            resolve({
              success: 'created assessment...'
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** createAssessment had some problems : ',
            error
          )
          resolve({
            error: '*** ERROR *** createAssessment had some problems : ',
            errormsg: error
          })
        })
    }) // Promise
  },
  createHomeworkTime(payload) {
    console.log('createHomeworkTime got called:')
    console.log(payload)
    return new Promise((resolve) => {
      let currentDate = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
      let load = `{
        "learnerhomeworkuid": "${payload.learnerhomeworkuid}",
        "minuteshomework": "${payload.minuteshomework}",
        "homeworkdate": "${currentDate}"
      }`
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('salearner/createLearnerHomeworkNQUADS/hypatia', pLoad)
        .then((response) => {
          console.log('====== CREATE HOMEWORK GOT RESPONSE :')
          console.log(response.data)
          if (response.data.dgraphresult.success) {
            resolve({
              success:
                'created homework...' + response.data.dgraphresult.success
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** createHomeworkTime had some problems : ',
            error
          )
          resolve({
            error: '*** ERROR *** createHomeworkTime had some problems : ',
            errormsg: error
          })
        })
    }) // promise
  },
  createReadingTime(payload) {
    console.log('createReadingTime got called:')
    console.log(payload)
    return new Promise((resolve) => {
      let currentDate = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
      let load = `{
        "learnerreadinguid": "${payload.learnerreadinguid}",
        "minutesreading": "${payload.minutesreading}",
        "readingdate": "${currentDate}"
      }`
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('salearner/createLearnerReadingNQUADS/hypatia', pLoad)
        .then((response) => {
          console.log('====== CREATE READING GOT RESPONSE :')
          console.log(response.data)
          if (response.data.dgraphresult.success) {
            resolve({
              success: 'created reading...' + response.data.dgraphresult.success
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** createReadingTime had some problems : ',
            error
          )
          resolve({
            error: '*** ERROR *** createReadingTime had some problems : ',
            errormsg: error
          })
        })
    }) // promise
  },
  create_updateLearnerCompetencyAssessment(payload) {
    console.log('create_updateLearnerCompetencyAssessment got called:')
    console.log(payload)
    return new Promise((resolve) => {
      let currentDate = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
      /*
          'slidervalue': this.sliderValue,
          'cauid': learnerCompetencyAssessmentUid

          */
      let load = `{
        "competencyassessmentuid": "${payload.cauid}",
        "competencyassessmentslider": "${payload.slidervalue}",
        "competencyassessmentcreateddate": "${currentDate}",
        "competencyassessmentkmscode": "${payload.kmscode}",
        "competencyassessmentkmsuid": "${payload.kmsuid}"
      }`
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      console.log('PLOAD IS ', pLoad)
      this.postHTTP(
        'salearner/createLearnerCompetencyAssessmentNQUADS/hypatiaUpdateCompetencyAssessment',
        pLoad
      )
        .then((response) => {
          console.log('====== CREATE COMPETENCY ASSESSMENT GOT RESPONSE :')
          console.log(response.data)
          if (response.data.dgraphresult.success) {
            resolve({
              success:
                'created competency assessment...' +
                response.data.dgraphresult.success
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** createLearnerCompetencyAssessment had some problems : ',
            error
          )
          resolve({
            error:
              '*** ERROR *** createLearnerCompetencyAssessment had some problems : ',
            errormsg: error
          })
        })
    }) // promise
  },
  createCurriculumItem(payload) {
    console.log('createCurriculumItem got called: (%j)', payload)
    return new Promise((resolve) => {
      let load = `{
        "updatedcompetencygoal": "${payload.updatedcompetencygoal}",
        "weeknumbers": "${payload.weeknumbers}",
        "subjectuid": "${payload.subjectuid}",
        "grepkode": "${payload.grepkode}",
        "competencyitemuid": "${payload.competencyitemuid}",
        "competencyskills": "${payload.competencySkills}",
        "competencyfacts": "${payload.competencyFacts}",
        "competencyreflects": "${payload.competencyReflects}",
        "competencymasters": "${payload.competencyMasters}",
        "competencycriteria":  "${payload.competencyCriteria}"
      }`
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('v4leducator/createLocalCurriculum/hypatia', pLoad)
        .then((response) => {
          console.log('====== CREATE LOCAL CURRICULUM DATA :')
          console.log(JSON.stringify(response.data))
          if (response.data.success !== '') {
            resolve({
              success: response.data.newCurriculumuid,
              currItem: response.data
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** createCurriculumItem had some problems : ',
            error
          )
          resolve({
            error: '*** ERROR *** createCurriculumItem had some problems : ',
            errormsg: error
          })
        })
    }) // promise
  },
  getEducatorsCurriculumItems(payload) {
    console.log('getEducatorsCurriculumItems got called ! (%j)', payload)
    return new Promise((resolve, reject) => {
      let educatoruid = payload.educatoruid
      let query = `{
        query(func: uid(${educatoruid})) {
          educatorHasSubject {
            educatorHasCommonSubjectGroup {
              educatorcurriculum {
                uid
                educatorcurriculum_competency_weeks
                educatorcurriculum_competency_skills
                educatorcurriculum_competency_facts
                educatorcurriculum_competency_reflections
                educatorcurriculum_competency_masters
                educatorcurriculum_competency_criteria
                educatorcurriculum_competencygoal
                educatorcurriculum_grepkode
              }
            }
          }
        }
      }`
      let pLoad = {
        query: encodeLoad(query),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/hypatiaCurriculumItems', pLoad)
        .then((result) => {
          console.log('== getEducatorsCurriculumItems == returned')
          console.log(JSON.stringify(result.data.dgraphresult))
          // console.log(JSON.stringify(result.data))
          if (result.data.dgraphresult[0] !== undefined) {
            let educatorMap = new Map()
            let educatorCurriculum =
              result.data.dgraphresult[0].educatorHasSubject
            for (var i = 0; i < educatorCurriculum.length; i++) {
              let commonSubject =
                educatorCurriculum[i].educatorHasCommonSubjectGroup
                  .educatorcurriculum
              let curr = []
              let grepKode = ''
              console.log('commonsSubject(%s)', JSON.stringify(commonSubject))
              console.log('commonSubject.length (%i)', commonSubject.length)
              for (var k = 0; k < commonSubject.length; k++) {
                grepKode = commonSubject[k].educatorcurriculum_grepkode
                console.log('CURR (%s)', JSON.stringify(curr))
                console.log('====== CODE TO MAP === (%s)', grepKode)
                if (educatorMap.has(grepKode)) {
                  console.log('=== HAS MAP (%s)', JSON.stringify(educatorMap))
                  curr = educatorMap.get(grepKode)
                }
                curr.push(commonSubject[k])
                console.log('CURR-2 (%j)', curr)
                educatorMap.set(grepKode, curr)
                grepKode = ''
                curr = []
              }
              console.log('=========== EDUCATOR MAP ==========')
              console.log(JSON.stringify(educatorMap))
              /*
                  for (var j = 0; j < commonSubject.length; j++) {
                    let item = commonSubject[j]
                    let curriculumItem = item.educatorcurriculum
                    for (var k = 0; k < curriculumItem.length; k++) {
                      let curr = curriculumItem[k]
                      console.log('ITEM (%J)', item)
                      educatorMap.set(curr.educatorcurriculum_grepkode, curr)
                      console.log('====== CODE TO MAP === (%s)', curr.educatorcurriculum_grepkode)
                    }
                  }
                  */
            }
            console.log('=========== EDUCATOR MAP FINAL ==========')
            console.log(JSON.stringify(educatorMap))
            this.setEducatorCurriculum(educatorMap)
            resolve({
              success: 'Got educatorCurriculums...'
            })
          } else {
            // no data
            resolve({
              success: 'NO EDUCATOR SET CURRICULUM DATA'
            })
          }
        })
        .catch((error) => {
          reject({
            errorMsg:
              'getEducatorsCurriculumItems had some problems: (%o) ' + error
          })
        })
    }) // promise
  },
  /***
   **** GETTERS
   ****/
  getKmsDataForUser() {
    console.log('getKmsDataForUser - got called !')
    return new Promise((resolve, reject) => {
      let searchDate = this.getSearchDate()
      let learnerInfo = this.getActiveLearnerInfo()
      let kmsUid = learnerInfo.learnerHasCompetencyAssessment[0].uid
      let query = `{
      query(func: uid(${kmsUid})) @cascade {
        hasLearnerCompetencyAssessment  @filter(ge(learnercompetencyassessment_createddate, "${searchDate.fromDate}") and le(learnercompetencyassessment_createddate, "${searchDate.toDate}")) (orderasc: learnercompetencyassessment_createddate) {
          uid
          learnercompetencyassessment_grep_kms
          learnercompetencyassessment_value
          learnercompetencyassessment_createddate
        }
      }
    }`
      let pLoad = {
        query: encodeLoad(query),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/hypatiaKmsDataForUser', pLoad)
        .then((result) => {
          console.log('== getKmsDataForUser == returned')
          console.log(result)
          if (result.data.dgraphresult[0] !== undefined) {
            this.setLearnerKmsData(
              result.data.dgraphresult[0].hasLearnerCompetencyAssessment
            )
            resolve({
              success: 'set Grep KMS data successfully'
            })
          }
        })
        .catch((error) => {
          reject({
            errorMsg: 'getKmsDataForUser had some problems: ' + error.toString()
          })
        })
    }) // promise
  },
  getKMSDoughnutSummaryData(payload) {
    return new Promise((resolve, reject) => {
      console.log(
        'getKMSDoughnutSummaryData got called (%s), (%j)',
        this.educatorInfo.uid,
        payload
      )
      //  console.log(payload)
      //let userid = payload.educator_userid
      let userid = this.educatorInfo.uid
      //  let searchDate = this.educatorInfo
      //  let kmsCode = payload.kmsCode
      // let commonSubjectId = payload.commonSubjectId
      let query = `{
        var (func: uid(${userid})) {
          educatorHasSubject{
            educatorHasCommonSubjectGroup {
                   commonsubjectuid as uid
                }
              }
            }

            query(func: uid(commonsubjectuid), orderasc: commonsubjectgroup_displayname) @cascade {
              subjectUid :uid
              subjectName: commonsubjectgroup_displayname
              learnerCommonSubject: ~learnerHasCommonSubjectGroup {
              schoolSubject: learner_schoolsubject_displayName
              learner_schoolsubject_grep_code
              learner_schoolsubject_grep_kmskode
              learnerSubjectHasCompetencyAssessment {
                hasLearnerCompetencyAssessment {
                   lc_value as learnercompetencyassessment_value
                   learnercompetencyassessment_grep_km
                   learnercompetencyassessment_grep_kms_code
                   learnercompetencyassessment_grep_kms_tittel
                }
                lc_sum : sum(val(lc_value))
                  lc_avg as avg(val(lc_value))
              }
                learnerAvg : avg(val(lc_avg))
                learnerInfo: ~learnerHasSubject {
                  uid
                    learnerName: learner_displayName
                    learnerPicture: learner_picture
                  }
                }
              }
       }`
      let pLoad = {
        query: encodeLoad(query),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/hypatiaDougnutSummary', pLoad)
        .then((result) => {
          console.log('== getKMSDoughnutSummaryData == returned')
          console.log(result)
          if (result.data.dgraphresult[0] !== undefined) {
            this.setLearnersDoughnutKmsData(result.data.dgraphresult)
            resolve({
              success: result.data.dgraphresult
            })
          } else {
            resolve({
              nodata:
                '**ERROR** getKMSDoughnutSummaryData did not return any data !'
            })
          }
        })
        .catch((error) => {
          reject({
            errorMsg:
              '*** ERROR *** getKMSDoughnutSummaryData had some problems: ' +
              error
          })
        })
    }) // promise
  },
  getKMSSummaryData(payload) {
    return new Promise((resolve, reject) => {
      console.log(
        'getKMSSummaryData got called (%s), %j)',
        this.educatorInfo.uid,
        payload
      )
      //  console.log(payload)
      //  let userid = payload.educator_userid
      let userid = this.educatorInfo.uid
      let searchDate = this.educatorInfo
      //  let kmsCode = payload.kmsCode
      // let commonSubjectId = payload.commonSubjectId
      let query = `{
        var (func: uid(${userid})) {
          educatorHasSubject{
            educatorHasCommonSubjectGroup {
                   commonsubjectuid as uid
                }
              }
            }

          query(func: uid(commonsubjectuid), orderasc: commonsubjectgroup_displayname) @cascade {
            subjectUid: uid
            subjectName: commonsubjectgroup_displayname
            learnerCommonSubject: ~learnerHasCommonSubjectGroup {
            learner_schoolsubject_displayName
            learner_schoolsubject_grep_code
            learner_schoolsubject_grep_kmskode
            learnerSubjectHasCompetencyAssessment {
              hasLearnerCompetencyAssessment @filter(ge(learnercompetencyassessment_createddate, "${searchDate.fromDate}") and le(learnercompetencyassessment_createddate, "${searchDate.toDate}")) (orderasc: learnercompetencyassessment_createddate){
                learnercompetencyassessment_createddate
                learnercompetencyassessment_grep_km
                learnercompetencyassessment_grep_kms_code
                learnercompetencyassessment_grep_kms_tittel
                lc_value as learnercompetencyassessment_value
              }
              lc_sum : sum(val(lc_value))
              lc_avg as avg(val(lc_value))
            }
              learnerAvg : avg(val(lc_avg))
              lc_l_avg as  avg(val(lc_avg))
              learnerInfo: ~learnerHasSubject {
                uid
                 learnerName: learner_displayName
                 learnerPicture: learner_picture
              }
            }
            subjectAvg: avg(val(lc_l_avg))
          }
       }`
      let pLoad = {
        query: encodeLoad(query),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/hypatiaKmsSummaryData', pLoad)
        .then((result) => {
          console.log('== getKMSSummaryData == returned')
          console.log(result.data.dgraphresult)
          if (result !== undefined && result.data.error) {
            resolve({
              nodata: '**ERROR** getKMSSummaryData did not return any data !'
            })
          } else if (
            result !== undefined &&
            result.data.dgraphresult[0] !== undefined
          ) {
            this.setLearnerKmsData(result.data.dgraphresult)
            resolve({
              success: result.data.dgraphresult
            })
          } else {
            resolve({
              nodata: '**ERROR** getKMSSummaryData did not return any data !'
            })
          }
        })
        .catch((error) => {
          reject({
            errorMsg:
              '*** ERROR *** getKMSSummaryData had some problems: ' + error
          })
        })
    }) // promise
  },
  getSubjectsWordCloudData(payload) {
    return new Promise((resolve, reject) => {
      console.log(
        'getSubjectsWordCloudData got called (%s), %j)',
        this.educatorInfo.uid,
        payload
      )
      //  console.log(payload)
      let userid = this.educatorInfo.uid
      let searchDate = this.educatorInfo
      console.log('===== SEARCH DATES: ')
      console.log(JSON.stringify(searchDate))
      //  let kmsCode = payload.kmsCode
      // let commonSubjectId = payload.commonSubjectId
      let query = `{
        var (func: uid(${userid})) {
          uid
          educatorHasSubject @filter(eq(educator_schoolsubject_active, true)) {
            uid
            educator_schoolsubject_displayName
            educatorHasCommonSubjectGroup {
              learnerSubjectUid as uid

            }
          }
        }

        query(func: uid(learnerSubjectUid), orderasc: commonsubjectgroup_displayname) @cascade {
          subjectName: commonsubjectgroup_displayname
          learnerCommonSubjectGroup: ~learnerHasCommonSubjectGroup {
            subjectName: learner_schoolsubject_displayName
            hasLearnerSubjectAssessment @filter(ge(subjectassessment_createddate, "${searchDate.fromDate}") and le(subjectassessment_createddate, "${searchDate.toDate}")) (orderasc: subjectassessment_createddate) {
              subjectassessment_createddate
              learned: subjectassessment_learned
              done: subjectassessment_done
            }
          }
        }
      }`
      let pLoad = {
        query: encodeLoad(query),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/hypatiaWordCloud', pLoad)
        .then((result) => {
          console.log('== getSubjectsWordCloudData == returned')
          console.log(result)
          if (result.data.dgraphresult[0] !== undefined) {
            this.setSubjectWordCloudData(result.data.dgraphresult)
            resolve({
              success: result.data.dgraphresult
            })
          } else {
            resolve({
              nodata:
                '**ERROR** getSubjectsWordCloudData did not return any data !'
            })
          }
        })
        .catch((error) => {
          reject({
            errorMsg:
              '*** ERROR *** getSubjectsWordCloudData had some problems: ' +
              error
          })
        })
    }) // promise
  },
  getLearnersSubjectFromEducatorCommonGroup(payload) {
    console.log('getLearnersSubjectFromEducatorCommonGroup got called :')
    console.log(JSON.stringify(payload))
    return new Promise((resolve, reject) => {
      let uid = payload.commonschooluid
      let query = `
      {
        var (func: uid("${uid}")) {
          uid
          commonschoolgroup_displayname
            ~learnerHasCommonSchoolGroup {
              ~learnerHasSchoolGroup {
                learner_displayName
                learnerHasSubject {
                  learnerHasCommonSubjectGroup {
                    learnerCommonSubjectUid as uid
                  }
                }

              }
            }
        }

        query(func: uid(learnerCommonSubjectUid)) @cascade {
          uid
          commonsubjectgroup_active
          commonsubjectgroup_displayname
          commonsubjectgroup_grep_code
          commonsubjectgroup_grep_kmskode
        }
      }
      `
      let pLoad = {
        query: encodeLoad(query),
        p1: this.sid
      }
      this.postHTTP(
        'dgraph-api/dquery/hypatiaLearnersSubjectFromEducatorCommonGroup',
        pLoad
      )
        .then((result) => {
          console.log(
            '== getLearnersSubjectFromEducatorCommonGroup == returned'
          )
          console.log(result)
          if (result.data.dgraphresult[0] !== undefined) {
            resolve({
              success: 'OK',
              data: result.data.dgraphresult
            })
          } else {
            resolve({
              nodata:
                '**ERROR** getLearnersSubjectFromEducatorCommonGroup did not return any data !'
            })
          }
        })
        .catch((error) => {
          reject({
            erroMsg:
              '*** ERROR *** getLearnersSubjectFromEducatorCommonGroup had some problems: ' +
              error
          })
        })
    }) // promise
  },
  getLearningEnvironmentFromSchoolGroup(payload) {
    console.log('getLearningEnvironmentFromSchoolGroup got called :')
    console.log(JSON.stringify(payload))
    return new Promise((resolve) => {
      let uid = payload
      let query = `
      {
        query(func: uid(${uid})) @cascade {
          uid
          commonschoolgroup_displayname
          commonschoolgroup_id
          rulelevel1 {
            uid
            ruleitem
          }
          rulelevel2 {
            uid
            ruleitem
          }
          rulelevel3 {
            uid
            ruleitem
          }
        }
      }`
      let pLoad = {
        query: encodeLoad(query),
        p1: this.sid
      }
      this.postHTTP(
        'dgraph-api/dquery/hypatiaGetLearningEnvironmentFromSchoolGroup',
        pLoad
      )
        .then((result) => {
          console.log('== getLearningEnvironmentFromSchoolGroup == returned')
          console.log(JSON.stringify(result.data.dgraphresult))
          if (result.data.dgraphresult[0] !== undefined) {
            this.setLearningEnvironment(result.data.dgraphresult)
            let newData = false
            resolve({
              success: 'OK',
              data: result.data.dgraphresult,
              newData: newData
            })
          } else {
            resolve({
              nodata:
                '**ERROR** getLearningEnvironmentFromSchoolGroup did not return any data !'
            })
          }
        })
        .catch((error) => {
          resolve({
            error:
              '*** ERROR *** getLearningEnvironmentFromSchoolGroup had some problems: ',
            errorMsg: error
          })
        })
    }) // promise
  },
  createRuleLevel(payload) {
    console.log('createRuleLevel got called')
    console.log(JSON.stringify(payload))
    return new Promise((resolve) => {
      let level = ''
      switch (payload.type) {
        case 'sad':
          level = 'level1'
          break
        case 'ok':
          level = 'level2'
          break
        case 'good':
          level = 'level3'
          break
        default:
          level = 'level3'
      }
      let load = ''
      for (let i = 0; i < payload.listItem.length; i++) {
        let txt = payload.listItem[i].trim()
        if (txt !== '') {
          load =
            load +
            ` <${payload.groupUid}> <${level}> _:rulelevel${i}  . \n
                  _:rulelevel${i} <dgrap.type> "Ruleitem" . \n `
          load = load + ` _:rulelevel${i} <ruleitem> "${txt}" . \n`
        }
      }
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dmutatenquad/hypatiacreateRuleLevel', pLoad)
        .then((response) => {
          console.log('====== CREATE createRuleLevel GOT RESPONSE :')
          console.log(JSON.stringify(response.data))
          if (response.data.dgraphresult.success) {
            resolve({
              success: 'set rulelevel...'
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** createRuleLevel had some problems : ',
            error
          )
          resolve({
            error: '*** ERROR *** createRuleLevel had some problems : ',
            errormsg: error
          })
        })
    }) // promise
  },
  deleteRuleLevel(payload) {
    console.log('createRuleLevel got called')
    console.log(JSON.stringify(payload))
    return new Promise((resolve) => {
      let level = ''
      switch (payload.type) {
        case 'sad':
          level = 'rulelevel1'
          break
        case 'ok':
          level = 'rulelevel2'
          break
        case 'good':
          level = 'rulelevel3'
          break
        default:
          level = 'rulelevel33'
      }

      let load =
        `  <${payload.listUid}> * * . \n ` +
        `  <${payload.listRuleUid}> <${level}> <${payload.listUid}>  . \n `
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/ddeletenquad/hypatiaDeleteuleLevel', pLoad)
        .then((response) => {
          console.log('====== DELETE RuleLevel GOT RESPONSE :')
          console.log(response.data)
          if (response.data.dgraphresult === 'success') {
            resolve({
              success: 'delete ruleitem...'
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** deleteRuleLevel had some problems : ',
            error
          )
          resolve({
            error: '*** ERROR *** deleteRuleLevel had some problems : ',
            errormsg: error
          })
        })
    }) // promise
  },
  saveLearningEnvironmentList(payload) {
    /*
               {"uid":"0x3cbb1d",
               "educator_schoolsubject_displayName":"Matematikk 4A",
               "educator_schoolsubject_grep_code":"MAT0004",
               "educator_schoolsubject_feide_id":"fc:gogroup:spusers.feide.no:u:NO856326499:4amat:2019-08-01:2020-06-15",
               "educator_schoolsubject_grep_kmskode":"KMS928",
               "educatorHasCommonSubjectGroup":{"uid":"0x3c6fa3"}}
               */
    console.log('saveLearningEnvironmentList got called')
    console.log(JSON.stringify(payload))
    let level = ''
    switch (payload.type) {
      case 'sad':
        level = 'rulelevel1'
        break
      case 'ok':
        level = 'rulelevel2'
        break
      case 'good':
        level = 'rulelevel3'
        break
      default:
        level = 'rulelevel3'
    }
    return new Promise((resolve) => {
      console.log('LIST ITEMS LENGTH %s)', payload.listItems.length)
      let load = ''
      for (let i = 0; i < payload.listItems.length; i++) {
        let item = payload.listItems[i]
        console.log('------ ITEM ------ (%s)', JSON.stringify(item))
        if (item.ruleitem.trim() !== '') {
          if (item.uid === '') {
            load =
              load +
              ` <${payload.groupUid}> <${level}>  _:paylevel${i} . \n ` +
              ` _:paylevel${i} <dgraph.type> "Ruleitem" . \n `
            load = load + ` _:paylevel${i} <ruleitem> "${item.ruleitem}" . \n`
          } else {
            load = load + ` <${item.uid}> <ruleitem> "${item.ruleitem}" . \n`
          }
        }
      }
      if (load !== '') {
        // in case of empty values - we do not mutate
        let pLoad = {
          mutate: encodeLoad(load),
          p1: this.sid
        }
        this.postHTTP(
          'dgraph-api/dmutatenquad/hypatiasaveLearningEnvironmentList',
          pLoad
        )
          .then((response) => {
            console.log(
              '====== CREATE saveLearningEnvironmentList GOT RESPONSE :'
            )
            console.log(JSON.stringify(response.data))
            let newUid = getUids(response)
            console.log('new UID (%s)', newUid)
            if (newUid === '') {
              newUid = payload.listItems[0].uid
            }
            resolve({
              success: 'set rulelevel...',
              resultuid: newUid
            })
          })
          .catch((error) => {
            console.error(
              '*** ERROR *** saveLearningEnvironmentList had some problems : ',
              error
            )
            resolve({
              error:
                '*** ERROR *** saveLearningEnvironmentList had some problems : ',
              errormsg: error
            })
          })
      } // if (load)
    }) // promise
  },
  getPingServer() {
    return new Promise((resolve, reject) => {
      let serverUrl =
        this.serverUrl || localStorage.getItem('hypsaserver') || ''
      if (serverUrl === '') {
        toast.error(t('gui.noserverurl'))
        reject({
          error: 'no serverUrlSet'
        })
      } else {
        this.getHTTPSimple(
          checkRegistryURL(serverUrl) + 'kubeheartbeat/isalive'
        )
          .then((result) => {
            console.log('getPingServer er: (' + JSON.stringify(result) + ')')
            resolve(result.data)
          })
          .catch((error) => {
            console.error('*** ERROR *** getPingServer had some errors:', error)
            reject({
              errorMsg: '*** ERROR *** getPingServer had some errors:' + error
            })
          })
      }
    }) // promise
  },
  getServerSysInfo() {
    console.log('GET SERVER SYS INFO GOT CALLED !')
    return new Promise((resolve, reject) => {
      let serverUrl = this.serverUrl
      if (serverUrl === '') {
        serverUrl = localStorage.getItem('hypsaserver')
      }
      if (serverUrl === undefined || serverUrl === '') {
        reject({
          error: 'no serverUrlSet'
        })
      }
      let pLoad = {
        p1: this.sid
      }
      this.postHTTP('sysinfo/sysinfo', pLoad)
        .then((result) => {
          console.log('Sysinfo er: (' + JSON.stringify(result.data) + ')')
          this.serverSysInfo = result.data
          resolve(result.data)
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** getServerSysInfo had some errors:',
            error
          )
          reject({
            errorMsg: '*** ERROR *** getServerSysInfo had some errors:' + error
          })
        })
    }) //  Promise
  },
  fixServerURL() {
    console.log(
      '--------------- fixServerURL got called ! (%s)',
      this.serverUrl
    )
    let serverUrl = this.serverUrl
    if (serverUrl === undefined || serverUrl === '') {
      this.registryUrl = localStorage.getItem('hypregistry')
      this.serverUrl = localStorage.getItem('hypsaserver')
      console.log('--------------- setServer and setRegistry got called !')
    }
  },
  checkDBconnection() {
    return new Promise((resolve, reject) => {
      this.getHTTP('checkDBConnection')
        .then((result) => {
          console.log('== checkDBconnection == returned')
          console.log(result)
          if (result === 'OK') {
            resolve('OK')
          }
        })
        .catch((error) => {
          console.error('checkDBconnection had some problems: ', error)
          reject({ errorMsg: 'checkDBconnection had some problems: ' + error })
        })
    }) // promise
  },
  getLearnerInfo() {
    return new Promise((resolve) => {
      let userid = this.userid
      let query = ` {
              query(func: eq(learner_userid, "${userid}")) {
                uid
                learner_displayName
                learner_eduPersonPrimaryAffiliation
                learnerHasSubject (orderasc: learnersubject_name){
                  uid
                  learnersubject_name
                  learnersubject_schoolsubject_uid
                  learnersubject_grep_kms
                }
                learnerHasSchoolClass (orderasc: learnerschoolclass_name){
                  uid
                  learnerschoolclass_uid
                  learnerschoolclass_name
                  schoolClassHasContributions {
                    schoolclasscontributionuid: uid
                  }
                }
                learnerHasHomework {
                  uid
                }
                learnerHasReading {
                  uid
                }
                learnerHasCompetencyAssessment {
                  uid
                }
              }
            }
            `
      let pLoad = {
        query: encodeLoad(query),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/hypatiaLearnerInfo', pLoad)
        .then((result) => {
          console.log('== getLearnerInfo == returned')
          console.log(result)
          this.setLearnerInfo(result.data.dgraphresult[0])
          resolve({
            success: true
          })
        })
        .catch((error) => {
          console.error('getLearnerInfo had some problems', error)
          resolve({
            success: false,
            error: true,
            errorMsg: 'getLearnerInfor had some problems ' + error.toString()
          })
        })
    }) // promise
  },
  getLearnerSubjectHomeworkAll(payload) {
    console.log('getLearnerSubjectHomeworkALl, got called (%o)', payload)
    return new Promise((resolve, reject) => {
      let searchDate = this.educatorInfo
      let commonUid = payload.commonsubjectUid
      /* We are only interested in homework done between 08:00 yesterday and todays morning (08:00)
             When presenting the overview for the teacher.
             */
      let query = `{
        query(func: uid(${commonUid})){
          uid
          commonsubjectgroup_displayname
          learnerCommonSubject: ~learnerHasCommonSubjectGroup {
            learnerSubject: ~learnerHasSubject (orderasc: learner_displayName) {
              learner: learner_displayName
            }
            learner_schoolsubject_displayName
            hasLearnerSubjectHomework @filter(ge(subjecthomework_createddate, "${searchDate.fromDate}") and lt(subjecthomework_createddate, "${searchDate.toDate}")) (orderasc: subjecthomework_createddate) {
              uid
              time: subjecthomework_time
              subjecthomework_createddate
            }
          }
        }
      }`
      let pLoad = {
        query: encodeLoad(query),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/getLearnerSubjectHomeworkALl', pLoad)
        .then((result) => {
          console.log('== getLearnerSubjectHomeworkALl == returned')
          console.log(JSON.stringify(result.data.dgraphresult[0]))
          resolve(result.data.dgraphresult[0])
        })
        .catch((error) => {
          console.error('getLearnerSubjectHomeworkALl had some problems', error)
          reject({
            errorMsg:
              'getLearnerSubjectHomeworkALl had some problems (%o)' + error
          })
        })
    })
  },
  getLearnerSubjectHomework(payload) {
    console.log('getLearnerSubjectHomework, got called (%o)', payload)
    return new Promise((resolve, reject) => {
      let commonUid = payload.commonsubjectUid
      /* We are only interested in homework done between 08:00 yesterday and todays morning (08:00)
             When presenting the overview for the teacher.
             */
      let yesterday = moment().subtract(1, 'day').format('YYYY-MM-DDT08:00:00')
      let today = moment().format('YYYY-MM-DDT08:00:00')
      let query = `{
        query(func: uid(${commonUid})){
          uid
          commonsubjectgroup_displayname
          learnerCommonSubject: ~learnerHasCommonSubjectGroup {
            learnerSubject: ~learnerHasSubject (orderasc: learner_displayName) {
              learner: learner_displayName
            }
            learner_schoolsubject_displayName
            hasLearnerSubjectHomework @filter(ge(subjecthomework_createddate, "${yesterday}") and lt(subjecthomework_createddate, "${today}")) {
              uid
              time: subjecthomework_time
              subjecthomework_createddate
            }
          }
        }
      }`
      let pLoad = {
        query: encodeLoad(query),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/hypatiaLearnerSubjectHomework', pLoad)
        .then((result) => {
          console.log('== getLearnerSubjectHomework == returned')
          console.log(JSON.stringify(result.data.dgraphresult[0]))
          resolve(result.data.dgraphresult[0])
        })
        .catch((error) => {
          console.error('getLearnerSubjectHomework had some problems', error)
          reject({
            errorMsg: 'getLearnerSubjectHomework had some problems (%o)' + error
          })
        })
    })
  },
  /* ******************************* */
  /* ****** M U T A T I O N S ****** */
  /* ******************************* */
  hasReadNotesToTeacher(payload) {
    console.log('hasReadNotesToTeacher got called', JSON.stringify(payload))
    return new Promise((resolve, reject) => {
      // let mutate = `{ set { <${payload.uid}>   <learnercontribution_value>  "${payload.learnercontribution_value}"  . } }`
      let load = `<${payload.uid}> <subjectassessment_notestoteacher>  "${payload.subjectassessment_notestoteacher}" (read=true)  . `
      console.log(load)
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dmutatenquad/hypatiaNotesToTeacher', pLoad)
        .then((result) => {
          console.log('== hasReadNotesToTeacher == returned')
          console.log(result)
          resolve({
            success: result
          })
        })
        .catch((error) => {
          reject({
            errorMsg: 'hasReadNotesToTeacher - had some problems : ' + error
          })
        })
    }) // promise
  },
  /* ******************************* */
  /* ******      SOCIAL       ****** */
  /* ******************************* */
  getSchoolClasses(payload) {
    console.log('getSchoolClasses - got called !')
    console.log(JSON.stringify(payload))
    return new Promise((resolve, reject) => {
      let schoolUid = payload.schooluid
      let query = `{
        var (func: uid(${schoolUid})) {
          uid
          school_name
          schoolHasEducator {
            uid
            educator_displayName
            educatorHasSchoolGroup {
              uid
              educatorHasCommonSchoolGroup {
                commonUid as uid
              }
            }
          }
        }
        query(func: uid(commonUid)) {
          commonschoolclassuid: uid
          schoolclass_name: commonschoolgroup_displayname
        }
      }`
      let pLoad = {
        query: encodeLoad(query),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/hypatiaGetSocialClassResult', pLoad)
        .then((result) => {
          console.log('== getSchoolClasses == returned')
          console.log(result.data.dgraphresult)
          if (result.data.dgraphresult !== undefined) {
            console.log('====== RESULT IS :::: ', result.data.dgraphresult)
            this.setSchoolClasses(result.data.dgraphresult)
            resolve({
              success: 'getSchoolClasses'
            })
          } else {
            resolve({
              nodata: 'No social getSchoolClasses found.'
            })
          }
        })
        .catch((error) => {
          reject({ errorMsg: 'getSchoolClasses had some problems: ' + error })
          /*
            resolve({
              nodata: 'No social getSchoolClasses found.'
            })
            */
        })
    }) // promise
  },
  /* ******************************* */
  /* ******      M I S C      ****** */
  /* ******************************* */
  isLoggedIn() {
    console.log('isLoggedIn got called !! (%s)', this.accessTokenExpires)
    return new Promise((resolve) => {
      let tokenExpires = this.accessTokenExpires || ''
      // let date = new Date(0)
      // let expires = date.setUTCSeconds(tokenExpires)
      // console.log('isLoggedIn expires(%o) > now(%o) test(%o)', expires, Date.now(), (expires > Date.now()))
      //if (expires > Date.now()) {
      /*
      let eDate = dayjs(tokenExpires)
      console.log('TOKEN EXPIRES utc (%s)', eDate.utc(tokenExpires).format())
      console.log(
        'now expires utc (%s)',
        dayjs()
          .utc()
          .format()
      )
      console.log('test (%s)', dayjs().isSameOrBefore(dayjs.unix(tokenExpires)))
      */
      if (tokenExpires === '') {
        let jwtToken = this.idToken
        let decoded = jwt.decode(jwtToken)
        console.log('token: (%s)', JSON.stringify(decoded))
        tokenExpires = decoded.exp
        this.setAccessTokenExpires(tokenExpires)
      }
      console.log('IS LOGGED IN EXPIRY TIME (%s)', dayjs.unix(tokenExpires))
      if (dayjs().isSameOrBefore(dayjs.unix(tokenExpires))) {
        resolve(true) // true - we are logged
      } else {
        resolve(false) // false - we are  not logged in due to Time expired
      }
    })
  },
  getAvgSocialValue(payload) {
    // returns all responses from all learners in each class
    console.log('getAvgSocialValue - got called !')
    console.log(JSON.stringify(payload))
    return new Promise((resolve, reject) => {
      let commonuid = payload.schoolclass
      let questionid = payload.questionid
      let query = `{
        var (func: uid(${commonuid})) {
          uid
            ~learnerHasCommonSchoolGroup {
              uid
                ~learnerHasSchoolGroup {
                  luid as uid
                }
            }
        }
        query(func: uid(luid)) {
          learner_displayName
          learner
          learnerHasSocialresponse @filter(eq(learnersocial_questionid, "${questionid}")) {
            learnersocial_question
            learnersocial_questionid
            learnersocial_questionuid
            learnersocial_registered
            lres as learnersocial_response
          }
          learner_avg: avg(val(lres))
        }
      }`
      let pLoad = {
        query: encodeLoad(query),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/hypatiagetAvgSocialValue', pLoad)
        .then((result) => {
          console.log('== getAvgSocialValue == returned')
          // console.log(JSON.stringify(result.data.dgraphresult))
          if (result.data.dgraphresult !== undefined) {
            // console.log('====== RESULT IS :::: ', result.data.dgraphresult)
            resolve({
              success: 'getAvgSocialValue',
              result: result.data.dgraphresult
            })
          } else {
            resolve({
              nodata: 'No social getAvgSocialValue found.'
            })
          }
        })
        .catch((error) => {
          reject({ errorMsg: 'getAvgSocialValue had some problems: ' + error })
        })
    }) // promise
  },
  getSocialFromCommonGroup(payload) {
    // returns all responses from all learners in each class
    console.log('getSocialFromCommonGroup - got called !')
    console.log(JSON.stringify(payload))
    return new Promise((resolve, reject) => {
      let commonuid = payload.commongroupuid
      let query = `{
        var (func: uid(${commonuid})) {
          uid
            ~learnerHasCommonSchoolGroup {
              uid
                ~learnerHasSchoolGroup {
                  learnerUid as uid
                }
            }
        }
        query(func: uid(learnerUid)) {
          learneruid: uid
          learner_displayName
          learner_picture
          learnerHasSocialresponse(orderasc: learnersocial_questionid) {
            uid
            learnersocial_question
            learnersocial_questionid
            learnersocial_response
            learnersocial_registered
          }
        }
      }`
      let pLoad = {
        query: encodeLoad(query),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/hypatiagetSocialFromCommonGroup', pLoad)
        .then((result) => {
          console.log('== getSocialFromCommonGroup == returned')
          console.log(result.data.dgraphresult)
          if (result.data.dgraphresult !== undefined) {
            console.log('====== RESULT IS :::: ', result.data.dgraphresult)
            this.setSocialCommonGroup(result.data.dgraphresult)
            resolve({
              success: 'getSocialFromCommonGroup',
              result: result.data.dgraphresult
            })
          } else {
            resolve({
              nodata: 'No social getSocialFromCommonGroup found.'
            })
          }
        })
        .catch((error) => {
          reject({
            errorMsg: 'getSocialFromCommonGroup had some problems: ' + error
          })
        })
    }) // promise
  },
  getSchoolInfo() {
    console.log('getSchoolInfo got called: ' + this.educatorInfo.uid)
    return new Promise((resolve, reject) => {
      // let educatorUid = payload.educatorUid
      let educatorUid = this.educatorInfo.uid
      let dgraphQuery = `{
          query(func: uid(${educatorUid})) @normalize {
            uid
              ~schoolHasEducator {
                schoolUid: uid
                schoolName: school_name
                schoolNorEduOrgNIN: school_norEduOrgNIN
              }
          }
        }
        `
      let pLoad = {
        query: encodeLoad(dgraphQuery),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/getSchoolInfo', pLoad)
        .then((response) => {
          console.log('getSchoolInfo returned:')
          console.log(JSON.stringify(response.data))
          this.setSchoolInfo(response.data.dgraphresult[0])
          this.getSocial()
            .then((res) => {
              console.log('=== GOT SOCIAL QUESTIONS ')
              console.log(JSON.stringify(res))
              resolve({
                success: 'GetSchool and SchoolInfo returned successfully'
              })
            })
            .catch((err) => {
              console.log('*** ERRROR getting socialquestions *** ', err)
              resolve({
                error: 'GetSchool and SchoolInfo had some problems'
              })
            })
        })
        .catch((error) => {
          console.log('*** ERROR *** getSchoolInfo (%o) ', error)
          reject({ errorMsg: '*** *** ERROR *** getSchoolInfo (%o) ' + error })
        })
    })
  },
  getSocial() {
    console.log('getSocial - got called !')
    return new Promise((resolve) => {
      let schoolInfo = this.getSchoolInfo()
      let query = `{
       query(func: uid(${schoolInfo.schoolUid})) @cascade {
           uid
           schoolHasSocialquestion {
             uid
             socialquestion_question
             socialquestion_response
             socialquestion_questionid
             socialquestion_active
             socialquestion_registered
         }
       }
     }`
      let pLoad = {
        query: encodeLoad(query),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/hypatiaGetSocial', pLoad)
        .then((result) => {
          console.log('== getSocial == returned')
          console.log(result.data)
          if (result.data.dgraphresult[0] !== undefined) {
            this.setSocialQuestions(result.data.dgraphresult)
            resolve({
              success: 'set Social data successfully'
            })
          } else {
            resolve({
              nodata: 'No social questions registered.'
            })
          }
        })
        .catch(() => {
          resolve({
            nodata: 'No social questions registered.'
          })
        })
    }) // promise
  },
  checkCreateMunicipality(payload) {
    console.log(
      'checkCreateMunicipality got called (%s)!',
      payload.allGroups.length
    )
    return new Promise((resolve) => {
      let orgData = {}
      let groups = payload.allGroups
      for (let i = 0; i < groups.length; i++) {
        let group = groups[i]
        // console.log('GROUP ER:' + JSON.stringify(group))
        let groupOrgType = ''
        let gOrgType = group.orgType || []
        // console.log('GROUP ORG TYPE: ' + groupOrgType)
        for (let j = 0; j < gOrgType.length; j++) {
          groupOrgType = gOrgType[j]
          switch (groupOrgType) {
            case 'higher_education':
              orgData.schoolName = group.displayName
              orgData.schoolNorEduOrgNin = group.norEduOrgNIN
              console.log('GOT HIGHER EDUCATION ' + JSON.stringify(orgData))
              break
            case 'primary_and_lower_secondary_owner':
              orgData.municipalityName = group.displayName
              orgData.municipalityNorEduOrgNin = group.norEduOrgNIN
              console.log(
                'GOT SCHOOL MUNICIPALITY OWNER ' + JSON.stringify(orgData)
              )
              break
            case 'primary_and_lower_secondary':
              orgData.schoolName = group.displayName
              orgData.schoolNorEduOrgNin = getOrgNIN(group.id)
              console.log('GOT SCHOOL ' + JSON.stringify(orgData))
              break
            default:
            // console.log('GOT DEFAULT (%s)', groupOrgType)
          }
        }
      }
      //  console.log('======== ORGDATA :' + JSON.stringify(orgData))
      if (orgData.schoolName === undefined) {
        resolve({
          error: 'Could not find schoolname as part of allgroups info.'
        })
      }
      this.getMunicipality(orgData).then((result) => {
        console.log('GET MUNICIPALITY RETURNED', JSON.stringify(result))
        if (result.nodata) {
          this.createMunicipality(orgData).then((result) => {
            console.log('CREATE MUNICIPALITY RETURNED', JSON.stringify(result))
            if (result.error) {
              resolve({
                error: result.error
              })
            } else {
              resolve({
                success: 'createdMunicipality',
                municipalityUID: result.success.municipalityUID
              })
              toast.info(
                t('toast.createdmunicipality', {
                  municipality: orgData.municipalityName
                })
              )
            }
          })
        } else if (result.success) {
          console.log('GET MUNICIPALITY was success: (%j)', result)
          resolve({
            success: 'ok',
            data: result.data,
            municipalityUID: result.data.uid
          })
        } else {
          toast.error(
            t('toast.errorgetmunicipality', {
              municipality: orgData.municipalityName
            })
          )
          this.clientLogout()
          this.$router
            .push({
              name: 'NoSchoolRegistered'
            })
            .catch(() => {})
        }
      })
    })
  },
  checkCreateSchool(payload) {
    console.log('checkCreateSchool got called ! (%j)', payload)
    let orgData = {}
    let groups = payload.allGroups || []
    return new Promise((resolve) => {
      for (let i = 0; i < groups.length; i++) {
        let group = groups[i]
        //  console.log('GROUP ER:' + JSON.stringify(group))
        let groupOrgType = ''
        let oType = group.orgType || []
        if (oType.length > 0) {
          for (let j = 0; j < oType.length; j++) {
            groupOrgType = oType[j]
            switch (groupOrgType) {
              case 'primary_and_lower_secondary_owner':
                orgData.municipalityName = group.displayName
                orgData.municipalityNorEduOrgNin = group.norEduOrgNIN
                console.log('GOT SCHOOL OWNER ' + JSON.stringify(orgData))
                break
              case 'primary_and_lower_secondary':
                orgData.schoolName = group.displayName
                orgData.schoolNorEduOrgNin = getOrgNIN(group.id)
                console.log('GOT SCHOOL ' + JSON.stringify(orgData))
                break
              case 'higher_education':
                orgData.schoolName = group.eduOrgLegalName
                orgData.schoolNorEduOrgNin = group.norEduOrgNIN
                console.log('GOT HIGHER EDUCATION ' + JSON.stringify(orgData))
                break
              default:
              // console.log('GOT DEFAULT (%s)', groupOrgType)
            }
          }
        }
      }
      //  console.log('======== ORGDATA :' + JSON.stringify(orgData))
      if (orgData.schoolName === undefined) {
        resolve({
          error: 'Could not find schoolname as part of allgroups info.'
        })
      }
      if (payload.municipalityUID === '') {
        resolve({ error: 'NO MUNICIPALITY UID DEFINED ' })
      }
      orgData.municipalityUID = payload.municipalityUid
      this.getSchool(orgData).then((result) => {
        console.log('GET  SCHOOL RETURNED', JSON.stringify(result))
        if (result.nodata) {
          this.createSchool(orgData).then((result) => {
            console.log('CREATE SCHOOL RETURNED', JSON.stringify(result))
            toast.info(t('toast.createschool', { school: orgData.schoolName }))
            resolve({
              success: 'createdSchool',
              schoolUID: result.schoolUID
            })
          })
        } else if (result.success) {
          console.log(JSON.stringify(result))
          resolve({
            success: 'ok',
            data: result.data,
            schoolUID: result.data.uid
          })
        } else {
          toast.error(t('toast.errorgetschool', { school: orgData.schoolName }))
          this.clientLogout()
          this.$router
            .push({
              name: 'NoSchoolRegistered'
            })
            .catch(() => {})
        }
      })
    })
  },
  createMunicipality(payload) {
    console.log('createmunicipality got called !')
    return new Promise((resolve) => {
      let now = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
      let newID = Date.now()
      let load = ` {
                      "municipalityname": "${payload.municipalityName}",
                      "norEduOrgNIN": "${payload.municipalityNorEduOrgNin}",
                      "now": "${now}",
                      "newID": "${newID}"
                    }
                    `
      console.log('PLOAD IS ', load)
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('saadmin/addmunicipalityNQUADS/hypatia', pLoad)
        .then((response) => {
          console.log(
            '====== ADD MUNICIPALITY GOT RESPONSE :' +
              JSON.stringify(response.data.dgraphresult)
          )
          resolve({
            success: response.data.dgraphresult
          })
        })
        .catch((error) => {
          console.error('addMunicipality had an error: ' + error)
          resolve({
            error: 'Could not create Municipality.'
          })
        })
    }) // promise
  },
  createSchool(payload) {
    console.log('createSchool got called !')
    console.log(JSON.stringify(payload))
    return new Promise((resolve) => {
      let now = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
      let newID = Date.now()
      let eduOrgNIN =
        payload.schoolNorEduOrgNin || payload.municipalityNorEduOrgNin
      let load = ` {
                      "municipalityUID": "${payload.municipalityUID}",
                      "schoolname": "${payload.schoolName || payload.municipalityName}",
                      "norEduOrgNIN": "${eduOrgNIN}",
                      "now": "${now}",
                      "newID": "${newID}"
                    }
                    `
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('saadmin/addschoolNQUADS/hypatia', pLoad)
        .then((response) => {
          console.log(
            '====== ADD MUNICIPALITY GOT RESPONSE :' +
              JSON.stringify(response.data.dgraphresult)
          )
          resolve({
            success: response.data.dgraphresult
          })
        })
        .catch((error) => {
          console.error('addMunicipality had an error: ' + error)
        })
    }) // promise
  },
  getMunicipality(payload) {
    console.log('getMunicipality got called! (%j)', payload)
    return new Promise((resolve) => {
      let load = `{
          query(func: eq(municipality_norEduOrgNIN, "${payload.municipalityNorEduOrgNin || payload.schoolNorEduOrgNin}")) @cascade {
            uid
            municipality_name
            municipality_norEduOrgNIN
        }
      }`
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/hypatiaGetMunicipality', pLoad).then(
        (response) => {
          console.log(
            'GET ONE MUNICIPALITY response.data: ' +
              JSON.stringify(response.data)
          )
          if (response.data.dgraphresult.error) {
            resolve({
              nodata: true
            })
          } else if (
            response.data.dgraphresult !== undefined &&
            response.data.dgraphresult.length > 0
          ) {
            resolve({
              success: true,
              data: response.data.dgraphresult[0],
              municipalityUID: response.data.dgraphresult[0].uid
            })
          }
        }
      )
    }) // promise
  },
  getSchool(payload) {
    console.log('getSchool got called!')
    console.log(payload)
    return new Promise((resolve) => {
      let load = `{
          query(func: eq(school_norEduOrgNIN, "${payload.schoolNorEduOrgNin}")) @cascade {
            uid
            school_name
            school_norEduOrgNIN
        }
      }`
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/hypatiaGetSchool', pLoad).then(
        (response) => {
          console.log(
            'GET SCHOOL response.data: ' + JSON.stringify(response.data)
          )
          if (response.data.dgraphresult.noresult) {
            resolve({
              success: false,
              error: true,
              nodata: true
            })
          } else if (
            response.data.dgraphresult !== undefined &&
            response.data.dgraphresult.length > 0
          ) {
            resolve({
              success: true,
              data: response.data.dgraphresult[0],
              municipalityUID: response.data.dgraphresult[0].uid
            })
          }
        }
      )
    }) // promise
  },
  addCurriculumToEducator(payload) {
    console.log('addCurriculumToEducator got called (%J)', payload)
    let grepKode = payload.grepKode
    let teachedUid = payload.teachedUid
    let coveredUid = payload.coveredUid
    let educator = this.educatorinfo
    let teachedCurriculum = educator.educatorHasTeached
    if (teachedCurriculum === undefined) {
      educator.educatorHasTeached = {}
      educator.educatorHasTeached.uid = teachedUid
      educator.educatorHasTeached.curriculumCovered = []
    }
    let educatorCurriculum = educator.educatorHasTeached.curriculumCovered || []
    let newValue = {
      curriculumTeachedCode: grepKode,
      curriculumTeachedCodeRegistered: '0000-00-00T00:00:00',
      uid: coveredUid
    }
    // console.log("EDUCATOR CURRICULUM LENGTH (%i)", educatorCurriculum.length)
    if (educatorCurriculum.length > 0) {
      educatorCurriculum.push(newValue)
    } else {
      educatorCurriculum = []
      educatorCurriculum.push(newValue)
    }
    // console.log(JSON.stringify(educator.educatorHasTeached))
    educator.educatorHasTeached.curriculumCovered = educatorCurriculum
    this.educatorinfo = educator
    // console.log(educatorCurriculum)
  },
  /* *********** Vurdering for Læring V4L ************ */
  createNewWrittenAssignment(payload) {
    console.log('createNewWrittenAssignment got called', payload)
    return new Promise((resolve) => {
      let load = ``
      if (payload.uid) {
        // Is Update
        let explanation = encodeLoad(payload.assignmentExplanation || '')
        let reviewguidance = encodeLoad(
          payload.writtenassignment_reviewguidance || ''
        )
        let competencyGoals = payload.competencyGoals.toString() || ''
        load = `{
        "uid": "${payload.uid}",
        "commonSubjectUid":"${payload.commonSubjectUid}",
        "writtenassignment_id" : "${payload.writtenassignment_id}",
        "writtenassignment_start_date": "${
          payload.writtenassignment_start_date
        }",
        "writtenassignment_end_date": "${
          payload.writtenassignment_end_date || ''
        }",
        "writtenassignment_language": "${
          payload.writtenassignment_language || ''
        }",
        "writtenassignment_learningdiary":"${
          payload.writtenassignment_afl_type === 'learningdiary'
        }",
        "writtenassignment_title": "${payload.writtenassignment_title}",
        "writtenassignment_num_words": "${
          payload.writtenassignment_num_words || 0
        }",
        "writtenassignment_duration": "${
          payload.writtenassignment_duration || 0
        }",
        "writtenassignment_explanation": "${explanation}",
        "writtenassignment_reviewguidance": "${reviewguidance}",
        "writtenassignment_assessedby_type": "${
          payload.writtenassignment_assessedby_type
        }",
        "writtenassignment_success_criteria": "${payload.successCriteria}",
        "writtenassignment_template_document": "${payload.writingFrame}",
        "writtenassignment_afl_type": "${payload.writtenassignment_afl_type}",
        "writtenassignment_editor": "${payload.writtenassignment_editor}",
        "writtenassignment_aiContext": "${payload.aiContext || ''}",
        "writtenassignment_aiGenre": "${payload.aiGenre || ''}",
        "writtenassignment_aiSentiment": "${payload.aiSentiment || ''}",
        "writtenassignment_aiPrompt": "${payload.aiPrompt || ''}",
        "writtenassignment_aiPrompt1": "${payload.aiPrompt1 || ''}",
        "writtenassignment_aiPrompt2": "${payload.aiPrompt2 || ''}",
        "writtenassignment_aiSuccesscriteria": "${payload.aiSuccesscriteria || ''}",
        "writtenassignment_aiEducatorsubjectcontext": "${payload.aiEducatorSubjectContext || ''}",
        "writtenassignment_aiEducatorsubjectprompt": "${payload.aiEducatorSubjectPrompt || ''}",
        "writtenassignment_competencyGoals": "${competencyGoals}",
        "writtenassignmentHasGuidance": "${
          payload.writtenassignmentHasGuidance
        }"
      }`
        /*
                "writtenassignment_aiContext": "${payload.writtenassignment_aiContext || ''}",
                "writtenassignment_aiGenre": "${payload.writtenassignment_aiGenre || ''}",
                "writtenassignment_aiSentiment": "${payload.writtenassignment_aiSentiment || ''}",
                "writtenassignment_aiPrompt": "${payload.writtenassignment_aiPrompt || ''}",
                "writtenassignment_aiPrompt1": "${payload.writtenassignment_aiPrompt1 || ''}",
                "writtenassignment_aiPrompt2": "${payload.writtenassignment_aiPrompt2 || ''}",
                "writtenassignment_aiSuccesscriteria": "${payload.writtenassignment_aiSuccesscriteria || ''}",
                "writtenassignment_aiEducatorsubjectcontext": "${payload.writtenassignment_aiEducatorsubjectcontext || ''}",
                "writtenassignment_aiEducatorsubjectprompt": "${payload.writtenassignment_aiEducatorsubjectprompt || ''}",
        */
      } else {
        // Is NEW
        load = `{
          "commonSubjectUid":"${payload.commonSubjectUid}",
          "writtenassignment_id" : "",
          "writtenassignment_start_date": "",
          "writtenassignment_end_date": "",
          "writtenassignment_language": "",
          "writtenassignment_learningdiary": "false",
          "writtenassignment_title": "",
          "writtenassignment_num_words": "",
          "writtenassignment_duration": "",
          "writtenassignment_explanation": "",
          "writtenassignment_reviewguidance": "",
          "writtenassignment_assessedby_type": "",
          "writtenassignment_success_criteria": "",
          "writtenassignment_template_document": "",
          "writtenassignment_afl_type": "",
          "writtenassignment_editor": "",
          "writtenassignment_aiContext": "",
          "writtenassignment_aiGenre": "",
          "writtenassignment_aiSentiment": "",
          "writtenassignment_aiPrompt": "",
          "writtenassignment_aiPrompt1": "",
          "writtenassignment_aiPrompt2": "",
          "writtenassignment_aiSuccesscriteria": "",
          "writtenassignment_aiEducatorsubjectcontext": "",
          "writtenassignment_aiEducatorsubjectprompt": "",
          "writtenassignment_competencyGoals": "",
          "writtenassignmentHasGuidance": ""
        }`
      }

      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('v4l/addWrittenAssignment/createWrittenAssignment', pLoad)
        .then((response) => {
          console.log('====== CREATE createNewWrittenAssignment GOT RESPONSE :')
          console.log(response)
          if (response.data.dgraphresult.success) {
            this.populateEducatorInfo().then(() => {
              resolve({
                success: true,
                successMsg: 'created written assignment...'
              })
            })
          } else {
            resolve({
              success: false,
              error: true,
              errormsg: '*** PROBLEMS *** Could not createNewWrittenAssignment'
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** createNewWrittenAssignment had some problems : ',
            error
          )
          resolve({
            success: false,
            error: true,
            errormsg: error
          })
        })
    })
  },
  deleteWrittenAssignment(payload) {
    console.log(' deleteWrittenAssignment got called')
    console.log(JSON.stringify(payload))
    return new Promise((resolve) => {
      let load =
        `  <${payload.uid}> * * . \n` +
        `<${payload.commonSubjectUid}> <hasWrittenAssignment> <${payload.uid}> .`
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/ddeletenquad/hypatiaDeleteWA', pLoad)
        .then((response) => {
          console.log('====== DELETE Written assignment returned:')
          console.log(response)
          if (response.data.dgraphresult.success) {
            this.populateEducatorInfo().then(() => {
              resolve({
                success: true,
                successMsg: 'delete written assignment...'
              })
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR ***  Written assignment had some problems : ',
            error
          )
          resolve({
            error: true,
            success: false,
            errormsg:
              '*** ERROR ***  Written assignment had some problems : ' + error
          })
        })
    }) // promise
  },
  createNewWrittenGuidance(payload) {
    console.log('creteWrittenAssignemnt got called', payload)
    return new Promise((resolve) => {
      let load = ``
      if (payload.uid) {
        // Is Update
        load = `{
        "uid": "${payload.uid}",
        "writtenGuidance_id" : "${payload.writtenguidance_id}",
        "writtenGuidance_type": "${payload.writtenguidance_type}",
        "writtenGuidance_at": "${payload.writtenguidance_at}",
        "writtenGuidance_explanation": "${payload.writtenguidance_explanation}"
      }`
      } else {
        // Is NEW
        load = `{
          "writtenAssignmentUid":"${payload.writtenAssignmentUid}",
          "writtenGuidance_id" : "",
          "writtenGuidance_type": "",
          "writtenGuidance_at": "",
          "writtenGuidance_explanation": ""
        }`
      }

      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('v4l/addWrittenGuidance/createNewWrittenGuidance', pLoad)
        .then((response) => {
          console.log(
            '====== CREATE createNewWrittenGuidance GOT RESPONSE : (%j)',
            response.data
          )
          if (response.data.dgraphresult.success) {
            this.populateEducatorInfo().then(() => {
              resolve({
                success: true,
                successMsg: 'created written guidance...',
                writtenGuidanceUid:
                  response.data.dgraphresult.writtenGuidanceUid
              })
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** createNewWrittenGuidance had some problems : ',
            error
          )
          resolve({
            success: false,
            error: true,
            errormsg: error.toString()
          })
        })
    })
  },
  deleteGuidanceItem(payload) {
    console.log(' deleteGuidanceItem got called (%j)', payload)
    return new Promise((resolve) => {
      let load =
        `  <${payload.guidanceUid}> * * . \n` +
        `<${payload.assignmentUid}> <writtenassignmentHasGuidance> <${payload.guidanceUid}> .`
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/ddeletenquad/hypatiaDeleteGuidance', pLoad)
        .then((response) => {
          console.log('====== DELETE deleteGuidanceItem returned:')
          console.log(response)
          if (response.data.dgraphresult.success) {
            this.populateEducatorInfo().then(() => {
              resolve({
                success: true,
                successMsg: 'delete written Guidance...'
              })
            })
          } else {
            resolve({
              error: true,
              errorMsg: response,
              success: false
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR ***  deleteGuidance had some problems : ',
            error
          )
          resolve({
            error: true,
            success: false,
            errormsg:
              '*** ERROR ***  deleteGuidance had some problems : ' + error
          })
        })
    }) // promise
  },
  getTodaysActiveAssignments() {
    console.log('getTodaysActiveAssignments got called')
    let educatorInfo = this.educatorinfo
    let todaysAssignments = []
    return new Promise((resolve) => {
      let educatorSubjects = educatorInfo.educatorHasSubject || []
      for (let i = 0; i < educatorSubjects.length; i++) {
        let subject = educatorSubjects[i]
        let commonSubject = subject.educatorHasCommonSubjectGroup || {}
        let writtenAssignments = commonSubject.hasWrittenAssignment || []
        for (let j = 0; j < writtenAssignments.length; j++) {
          let assignment = writtenAssignments[j]
          // let learnerAssignments = assignment.learnerAssignments || []
          // let todaysAssignmentUid = ''
          /*
          for (let k = 0; k < learnerAssignments.length; k++) {
            let learnerAssignment = learnerAssignments[k]
            console.log(
              'TODAYS ACTIVE ASSIGNMENT DATE TEST (%j)',
              learnerAssignment
            )
            */
          /*
            console.log(
              '(%s) - (%s)',
              (dayjs().isBetween(
                assignment.writtenassignment_start_date,
                assignment.writtenassignment_end_date
              ) &&
                dayjs(
                  learnerAssignment.learnerwrittenassignment_started
                ).isBetween(
                  assignment.writtenassignment_start_date,
                  assignment.writtenassignment_end_date
                )) ||
                dayjs().isSame(
                  learnerAssignment.learnerwrittenassignment_ended,
                  'day'
                )
            )
            */
          if (
            dayjs().isBetween(
              assignment.writtenassignment_start_date,
              assignment.writtenassignment_end_date
            )
          ) {
            todaysAssignments.push(assignment)
            // todaysAssignmentUid = assignment.uid
            console.log(
              'WE HAVE ACTIVE TODAYS ASSIGNMENT !!! (%J)',
              todaysAssignments
            )
            // break
          }
          //          } // FOR LOOP COMMENTED OUT
          // if (todaysAssignmentUid === assignment.uid) break
          /*
          We need to break out of both lopps when an active assignment is found ...
          To avoid adding the same assignment several times
          */
        }
      }
      if (todaysAssignments.length > 0) {
        resolve({
          success: true,
          data: todaysAssignments
        })
      } else {
        resolve({
          success: false,
          noAssignmentsToday: true
        })
      }
    }) // promise
  },
  getTodaysAssignmentsActiveLearnersWriting_movedtoserver(payload) {
    console.log('getTodaysAssignmentsActiveLearnersWriting (%s)', payload.uid)
    console.log(payload)
    let assignmentUid = payload.uid
    let startDay = dayjs(payload.writtenassignment_start_date).format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    let endDay = dayjs(payload.writtenassignment_end_date).format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    let aflstep = payload.aflvalue || -1
    let payLoadAddition = ''
    if (aflstep === 1 || aflstep === -1) {
      payLoadAddition = `
        subjectAssignment: ~hasLearnerSubjectAssignment {
          subjectUid: uid
           subject: learner_schoolsubject_displayName
           feideId: learner_schoolsubject_feide_id
           learnerInfo: ~learnerHasSubject {
             learnerUid: uid
             learnerName: learner_displayName
          }
        }`
    }
    if (aflstep === 3) {
      payLoadAddition = `subjectAssignment: ~learnerwrittenassignment_next_assignment @normalize {
        ~hasLearnerSubjectAssignment {
            subjectUid: uid
            subject: learner_schoolsubject_displayName
            feideId: learner_schoolsubject_feide_id
            learnerInfo: ~learnerHasSubject {
              learnerUid: uid
              learnerName: learner_displayName
            }
          }
      }`
    }
    if (aflstep === 5) {
      payLoadAddition = `
        subjectAssignment: ~learnerwrittenassignment_next_assignment  @normalize
            {
              ~learnerwrittenassignment_next_assignment {
                ~hasLearnerSubjectAssignment {
                  subjectUid: uid
                  subject: learner_schoolsubject_displayName
                  feideId: learner_schoolsubject_feide_id
                  learnerInfo: ~learnerHasSubject {
                  learnerUid: uid
                  learnerName: learner_displayName
                }
              }
            }
          }`
    }
    return new Promise((resolve) => {
      let load = `{
        query(func: uid(${assignmentUid})) {
          uid
          activeAssessments: ~learnerwrittenassignment_assignment
           @filter(ge(learnerwrittenassignment_started, "${startDay}")
               and le(learnerwrittenassignment_started, "${endDay}") and eq(learnerwrittenassignment_aflstep, ${aflstep})) {
            assignmentUid: uid
            duration: learnerwrittenassignment_duration
            aflstep: learnerwrittenassignment_aflstep
            htmlBid: learnerwrittenassignment_htmlBid
            langAIBid: learnerwrittenassignment_langAIBid
            llmBid: learnerwrittenassignment_llmBid
            aflBid: learnerwrittenassignment_aflfeedback_blob
            numWords: learnerwrittenassignment_num_words
            numWrittenWords: learnerwrittenassignment_numWrittenWords
            time_left: learnerwrittenassignment_time_left
            timeLeft: learnerwrittenassignment_timeLeft
            started: learnerwrittenassignment_started
            isAssessed: learnerwrittenassignment_isAssessed
            ended: learnerwrittenassignment_ended
            ${payLoadAddition}
          }
        }
      }`
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/activeWriting', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult || []
          console.log(
            '======= RESPONSE DATA FROM getTodaysAssignmentsActiveLearnersWriting ======'
          )
          console.log(responseData)
          if (responseData.length > 0) {
            resolve({
              success: true,
              data: responseData[0].activeAssessments || []
            })
          } else if (responseData.noresult) {
            console.log('no data from query')
            resolve({
              success: true,
              data: [],
              nodata: true
            })
          } else if (responseData.error) {
            this.clientLogout()
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** getTodaysAssignmentsActiveLearnersWriting had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getTodaysAssignmentsActiveLearnersWriting had some problems: ' +
              error
          })
        })
    }) // promise
  },
  getTodaysAssignmentsActiveLearnersWriting(payload) {
    console.log('getTodaysAssignmentsActiveLearnersWriting (%s)', payload.uid)
    console.log(payload)
    return new Promise((resolve) => {
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: this.sid
      }
      this.postHTTP('v4l/getTodaysActiveLearnersWriting/activeWriting', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult || []
          console.log(
            '======= RESPONSE DATA FROM getTodaysAssignmentsActiveLearnersWriting ======'
          )
          console.log(responseData)
          if (responseData.data.length > 0) {
            let responses = responseData.data
            let sortedList = responses.sort((a, b) => {
              if (a.subjectAssignment && b.subjectAssignment) {
                let nameA = a.subjectAssignment[0].learnerName.toUpperCase()
                let nameB = b.subjectAssignment[0].learnerName.toUpperCase()
                if (nameA < nameB) {
                  return -1
                }
                if (nameA > nameB) {
                  return 1
                }
                return 0
              }
            })
            resolve({
              success: true,
              data: sortedList
              //  data: responseData.data || []
            })
          } else if (responseData.noresult) {
            console.log('no data from query')
            resolve({
              success: true,
              data: [],
              nodata: true
            })
          } else if (responseData.error) {
            this.clientLogout()
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** getTodaysAssignmentsActiveLearnersWriting had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getTodaysAssignmentsActiveLearnersWriting had some problems: ' +
              error
          })
        })
    }) // promise
  },
  getCompleteLearnerWrittenAssignment(payload) {
    console.log('getCompleteLearnerWrittenAssignment got called (%j)', payload)
    return new Promise((resolve) => {
      let assignmentUid = payload.writtenAssignmentUid
      let load = `
      {
        query(func: uid("${assignmentUid}")) {
          uid
    			learnerwrittenassignment_aflfeedback_blob
        	learnerwrittenassignment_time_left
        	learnerwrittenassignment_isAssessed
        	learnerwrittenassignment_htmlBid
          learnerwrittenassignment_langAIBid
          learnerwrittenassignment_llmBid
          learnerwrittenassignment_rawText
        	learnerwrittenassignment_aflstep
        	learnerwrittenassignment_numWrittenWords
        	learnerwrittenassignment_duration
        	learnerwrittenassignment_num_words
        	learnerwrittenassignment_started
        	learnerwrittenassignment_ended
    			learnerwrittenassignment_hasFeedback {
						uid
    			  writtenfeedback_assessedby
      			writtenfeedback_assessedby_type
      			writtenfeedback_assessed_date
      			writtenfeedback_type
      			writtenfeedback_comment
      			writtenfeedback_proposal
      			writtenfeedback_orgtext
      			writtenfeedback_location
          }
    			learnerwrittenassignment_hasIndividualFeedback {
						uid
    			  writtenfeedback_assessedby
      			writtenfeedback_assessedby_type
      			writtenfeedback_assessed_date
      			writtenfeedback_type
      			writtenfeedback_comment
      			writtenfeedback_proposal
      			writtenfeedback_orgtext
      			writtenfeedback_location
          }
		    learnerwrittenassignment_assignment  {
						uid
            writtenassignment_learningdiary
            writtenassignment_afl_type
          	writtenassignment_title
            writtenassignment_competencyGoal {
            	uid
            	educatorcurriculum_competencygoal
            	educatorcurriculum_grepkode
          	}
          	writtenassignmentHasGroupFeedback {
            	uid
            	writtenfeedback_assessedby_type
            	writtenfeedback_assessed_date
            	writtenfeedback_type
            	writtenfeedback_proposal
          	}
          }
       }
    }`
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/learnerassessmentcomplete', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseD = response.data.dgraphresult
          let responseData = responseD[0] || {}
          console.log(
            '======= RESPONSE DATA FROM getCompleteLearnerWrittenAssignment ======'
          )
          console.log(responseData)
          // let learner = responseData[0]
          // let learnerSubject = learner.learnerHasSubject || []
          // let learnerAssessments = subject.hasLearnerSubjectAssignment || []
          // let subject = learnerSubject[0] || {}
          // let learnerAssessments = assignments[0] || {}
          // let learnerAssessments = assignment.learnerwrittenassignment_assignment
          resolve({
            success: true,
            //            data: learnerAssessments
            data: responseData
          })
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** getCompleteLearnerWrittenAssignment had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getCompleteLearnerWrittenAssignment had some problems: ' +
              error
          })
        })
    }) // promise
  },
  findNotAssessedLearnerAssignments(payload) {
    console.log('findNotAssessedLearnerAssignments got called (%J)', payload)
    return new Promise((resolve) => {
      //   let learnerUid = payload.learnerUid
      //   let feideId = payload.feideId
      let assignmentUid = payload.assignmentUid
      let load = `{
        query(func: uid(${assignmentUid})) {
          writtenassignment_learningdiary
          writtenassignment_title
          writtenassignment_start_date
          writtenassignment_end_date
          writtenassignment_language
          writtenassignment_explanation
          writtenassignment_reviewguidance
          writtenassignment_isAssessed
          learnerWrittenAssignment: ~learnerwrittenassignment_assignment
          @filter (eq(learnerwrittenassignment_isAssessed, false) and le(learnerwrittenassignment_aflstep, 0)) {
            uid
            learnerwrittenassignment_duration
            learnerwrittenassignment_ended
            learnerwrittenassignment_started
            learnerwrittenassignment_numWrittenWords
            learnerwrittenassignment_num_words
            learnerwrittenassignment_htmlBid
            learnerwrittenassignment_langAIBid
            learnerwrittenassignment_rawText
            learnerwrittenassignment_aflfeedback_blob
            learnerwrittenassignment_isAssessed
            learnerwrittenassignment_assignment
          }
          writtenassignment_competencyGoal {
            uid
            educatorcurriculum_competencygoal
            educatorcurriculum_grepkode
          }
          writtenassignmentHasGroupFeedback {
            uid
            writtenfeedback_assessedby_type
            writtenfeedback_assessed_date
            writtenfeedback_type
            writtenfeedback_proposal
          }
        }
      }`
      /*
      let load2 = `{
          query(func: uid(${assignmentUid})) {
            uid
            learner_displayName
            learnerHasSubject
            @filter(eq(learner_schoolsubject_feide_id,"${feideId}")) {
              uid
              learner_schoolsubject_displayName
              learner_schoolsubject_feide_id
              hasLearnerSubjectAssignment
              @filter (eq(learnerwrittenassignment_isAssessed, false) and le(learnerwrittenassignment_aflstep, 0))
               {
              uid
              learnerwrittenassignment_duration
              learnerwrittenassignment_ended
              learnerwrittenassignment_started
              learnerwrittenassignment_numWrittenWords
              learnerwrittenassignment_num_words
              learnerwrittenassignment_htmlBid
              learnerwrittenassignment_langAIBid
              learnerwrittenassignment_rawText
              learnerwrittenassignment_isAssessed
              learnerwrittenassignment_assignment
                {
                  uid
                  writtenassignment_learningdiary
                  writtenassignment_title
                  writtenassignment_start_date
                  writtenassignment_end_date
                  writtenassignment_explanation
                  writtenassignment_reviewguidance
                  writtenassignment_isAssessed
                  writtenassignment_competencyGoal {
                    uid
                    educatorcurriculum_competencygoal
                    educatorcurriculum_grepkode
                  }
                  writtenassignmentHasGroupFeedback {
                    uid
                    writtenfeedback_assessedby_type
                    writtenfeedback_assessed_date
                    writtenfeedback_type
                    writtenfeedback_proposal
                  }
                }
              learnerwrittenassignment_hasFeedback {
                  uid
                  writtenfeedback_assessedby
                  writtenfeedback_assessedby_type
                  writtenfeedback_assessed_date
                  writtenfeedback_type
                  writtenfeedback_comment
                  writtenfeedback_proposal
                  writtenfeedback_orgtext
                  writtenfeedback_location
                }
              learnerwrittenassignment_hasIndividualFeedback {
                  uid
                  writtenfeedback_assessedby_type
                  writtenfeedback_assessed_date
                  writtenfeedback_type
                  writtenfeedback_proposal
                }
              }
            }
          }
        }`
        */
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/notassessed', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult
          console.log(
            '======= RESPONSE DATA FROM findNotAssessedLearnerAssignments ======'
          )
          console.log(responseData)
          // let learner = responseData[0]
          // let learnerSubject = learner.learnerHasSubject || []
          // let learnerAssessments = subject.hasLearnerSubjectAssignment || []
          // let subject = learnerSubject[0] || {}
          // let learnerAssessments = assignments[0] || {}
          // let learnerAssessments = assignment.learnerwrittenassignment_assignment
          this.setNotAssessed(responseData || [])
          resolve({
            success: true,
            //            data: learnerAssessments
            data: responseData[0]
          })
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** findNotAssessedLearnerAssignments had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check findNotAssessedLearnerAssignments had some problems: ' +
              error
          })
        })
    }) // promise
  },

  checkSpellingAndGrammer(payload) {
    console.log('checkSpellingAndGrammer got called')
    console.log(payload)
    console.log('ActiveAssignemnt')
    let assignment = payload.writtenAssignment || {}
    let la = assignment.learnerwrittenassignment_assignment || {}
    let language =
      la.writtenassignment_afl_type ||
      payload.writtenassignment_afl_type ||
      'auto'
    return new Promise((resolve) => {
      let rawText = payload.learnerwrittenassignment_rawText || ''
      let pLoad = {
        mutate: rawText,
        language: language,
        p1: this.sid
      }
      this.postHTTP('v4l/checkEnglishGrammar/grammarCheck', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult
          console.log(
            '======= RESPONSE DATA FROM checkSpellingAndGrammer ======'
          )
          console.log(JSON.stringify(responseData))
          resolve({
            success: true,
            data: responseData
          })
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** checkSpellingAndGrammer had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check checkSpellingAndGrammer had some problems: ' +
              error
          })
        })
    }) // promise
  },
  /*
  checkPlagiarism( payload) {
    console.log('checkPlagiarism got called (%j)', payload)
    return new Promise((resolve) => {
      let rawText = payload.learnerwrittenassignment_rawText
      let uniqueSegment = dayjs().format('HH_mm_ss_SSS')
      let scanid = `scanid_${uniqueSegment}`
      this.setPlagiarismScanId(scanid)
      let filename = `file_${uniqueSegment}.txt`
      console.log('SCAN ID (%s)', scanid)
      let pLoad = {
        mutate: rawText,
        scanid: scanid,
        filename: filename,
        p1: this.sid
      }
      this.postHTTP('m1/checkPlagiarism/plagiarismCheck', pLoad)
        .then((response) => {
          console.log(
            '===========================checkPlagiarism= RESPONSE ER ======= (%j)',
            response
          )
          let responseData = response.data.dgraphresult
          console.log(
            '======= RESPONSE DATA FROM checkPlagiarism ====== (%j)',
            responseData
          )
          if (responseData.success) {
            resolve({
              success: true,
              data: responseData
            })
          } else if (responseData.noLogin) {
            resolve({
              success: false,
              noLogin: true
            })
          } else {
            resolve({
              success: false,
              error: true
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** checkPlagiarism had some problems ! : ' + error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check checkPlagiarism had some problems: ' + error
          })
        })
    }) // promise
  },
  checkPlagiarismReport() {
    console.log('checkPlagiarismReport got called')
    return new Promise((resolve) => {
      let scanId = this.getPlagiarismScanId()
      let pLoad = {
        p1: this.sid,
        scanid: scanId
      }
      this.postHHTTP('m1/getplagiarismreport/plagiarismResultCheck', pLoad)
        .then((response) => {
          console.log(
            '===========================checkPlagiarismReport= RESPONSE ER ======= (%j)',
            response
          )
          let responseData = response.data || {}
          console.log(
            '======= RESPONSE DATA FROM checkPlagiarismReport ====== (%j)',
            responseData
          )
          resolve({
            success: true,
            data: responseData.load
          })
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** checkPlagiarismReport had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check checkPlagiarismReport had some problems: ' +
              error
          })
        })
    }) // promise
  },
  deletePlagiarismPDF(payload) {
    // Delete PDF stored in Google Basket ?
    console.log('deletePlagiarismPDF got called (%s)', payload)
    return new Promise((resolve) => {
      let pLoad = {
        fileName: payload,
        p1: this.sid
      }
      this.postHTTP('v4lbucket/deletePDF/deletePdf', pLoad)
        .then(() => {
          this.setPlagiarismData({})
          resolve({
            success: true
          })
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** deletePlagiarismPDF had some problems ! : ' + error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check deletePlagiarismPDF had some problems: ' +
              error
          })
        })
    }) // promise
  },
  getPDF( payload) {
    // GET PDF stored in Google Basket ?
    console.log('getPDF got called (%s)', payload)
    return new Promise((resolve) => {
      let pLoad = {
        filename: payload,
        p1: this.sid
      }
      tbis.postHTTP('v4lbucket/getPDF/getpdf', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult
          console.log('======= RESPONSE DATA FROM getPDF ======')
          console.log(JSON.stringify(responseData))
          this.setPlagiarismData({}).finally(() => {
            resolve({
              success: true
              // data: responseData
            })
          })
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** deletePlagiarismPDF had some problems ! : ' + error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check deletePlagiarismPDF had some problems: ' +
              error
          })
        })
    }) // promise
  },
   */
  saveAssignmentComment(payload) {
    console.log('saveAssignmentComment called (%j)', payload)
    return new Promise((resolve) => {
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: this.sid
      }
      this.postHTTP('v4l/saveAssignmentComment/saveComment', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult
          console.log('======= RESPONSE DATA FROM saveAssignmentComment ======')
          console.log(JSON.stringify(responseData))
          this.findFeedbackToAssignment(payload).then((result) => {
            console.log('findFeedbackResult (%j)', result)
            let resultData = result.data[0]
            console.log(
              'RESULT FROM findFeedbackToAssignment(%j)',
              resultData.learnerwrittenassignment_hasFeedback || []
            )

            this.addAssignmentComments(
              //   resultData.learnerwrittenassignment_hasFeedback
              resultData.learnerwrittenassignment_hasFeedback || [] // should log error - this should not happen...
            )
          })
          resolve({
            success: true,
            data: responseData
          })
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** saveAssignmentComment had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check saveAssignmentComment had some problems: ' +
              error
          })
        })
    }) // promise
  },
  loadFeedback(payload) {
    return new Promise((resolve) => {
      payload.learnerwrittenassignmentuid =
        payload.learnerwrittenassignmentuid || payload.uid
      console.log(
        'loading feedback (%s) (%j)',
        payload.learnerwrittenassignmentuid,
        payload
      )
      this.findFeedbackToAssignment(payload).then((result) => {
        let resultData = result.data[0] || {}
        console.log(
          'RESULT FROM loadFeedback(%j)',
          resultData.learnerwrittenassignment_hasFeedback || []
        )
        this.addAssignmentComments(
          resultData.learnerwrittenassignment_hasFeedback || []
        )
        resolve({ success: true })
      })
    }) // promise
  },
  deleteFeedback(payload) {
    console.log('deleteFeedback GotCalled (%J)', payload)
    return new Promise((resolve) => {
      let load = `<${payload.learnerwrittenassignmentuid}> <learnerwrittenassignment_hasFeedback> <${payload.assessmentfeedbackUid}> . \n<${payload.assessmentfeedbackUid}> * * .\n`
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/ddeletenquad/hypatiaDeleteFeedback', pLoad)
        .then((response) => {
          console.log('====== DELETE deleteFeedback GOT RESPONSE :')
          console.log(response.data)
          this.findFeedbackToAssignment(payload).then((result) => {
            let resultData = result.data[0]
            console.log(
              'RESULT FROM findFeedbackToAssignment(%j)',
              resultData.learnerwrittenassignment_hasFeedback || []
            )
            this.addAssignmentComments(
              resultData.learnerwrittenassignment_hasFeedback || []
            )
          })
          resolve({
            success: true
          })
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** deleteFeedback had some problems : ',
            error
          )
          resolve({
            success: false,
            error: '*** ERROR *** deleteFeedback had some problems : ',
            errormsg: error
          })
        })
    }) // promise
  },
  getNumberUnAssessed() {
    return new Promise((resolve) => {
      let educatorInfo = this.educatorinfo
      let load = `{
        var (func: uid(${educatorInfo.uid})) {
                 uid
                 educator_displayName
                 educatorHasSubject {
                   uid
                   educatorHasCommonSubjectGroup {
                     uid
                     commonsubjectgroup_displayname
                       hasWrittenAssignment {
                         uid
                         writtenassignment_learningdiary
                         writtenassignment_title
                         ~learnerwrittenassignment_assignment
           @filter(eq(learnerwrittenassignment_isAssessed,false) and gt(learnerwrittenassignment_ended, "2002-01-01"))
                         {
                           notAssessed as uid
                           learnerwrittenassignment_htmlBid
                           learnerwrittenassignment_langAIBid
                           learnerwrittenassignment_isAssessed
                         }

                       }

                 }

               }
           }
         query(func: uid(notAssessed)) {
           unAssessed : count(uid)
         }
       }`
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/numberUnassessed', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult
          console.log('======= RESPONSE DATA FROM getNumberUnAssessed ======')
          console.log(JSON.stringify(responseData))
          resolve({
            success: true,
            unassessed: responseData[0].unAssessed
          })
        })
        .catch((error) => {
          console.log(
            '*** ERROR **** getNumberUnAssessed had some problems ! : ' + error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getNumberUnAssessed had some problems: ' +
              error
          })
        })
    })
  },
  getNumberOfUnassignedSubjectAssignments(payload) {
    return new Promise((resolve) => {
      let commonSubjectUid = payload.subjectUid
      let load = ` {
        var(func: uid(${commonSubjectUid})) {
          uid
             ~learnerHasCommonSubjectGroup {
            hasLearnerSubjectAssignment  {
              assessed as uid
            }
          }
        }
        query(func: uid(assessed))
            @filter(eq(learnerwrittenassignment_isAssessed, false) and
            gt(learnerwrittenassignment_ended, "2002-01-01T00:00:00Z")){
                numUnassessed: count( uid)
            }
        }
      `
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/numberSubjectUnassessed', pLoad)
        .then((response) => {
          console.log('============================ RESPONSE ER =======')
          console.log(response)
          let responseData = response.data.dgraphresult
          console.log(
            '======= RESPONSE DATA FROM getNumberOfUnassignedSubjectAssignments ======'
          )
          console.log(JSON.stringify(responseData))
          resolve({
            success: true,
            unassessed: responseData[0].numUnassessed
          })
        })
        .catch((error) => {
          console.log(
            '*** ERROR **** getNumberOfUnassignedSubjectAssignments had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getNumberOfUnassignedSubjectAssignments had some problems: ' +
              error
          })
        })
    })
  },
  finishedAssessingWrittenWork(payload) {
    console.log('finishedAssessingWrittenWork (%J)', payload)
    return new Promise((resolve) => {
      // let assignment = payload.learnerwrittenassignment_assignment || {}
      let educatorinfo = this.educatorinfo || {}
      let load = `{
        "learnerAssignmentUid": "${payload.learnerAssignmentUid}",
        "educatorUid": "${educatorinfo.uid}"
      }`
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP(
        'v4l/setFinishedAssessingWrittenWork/finishedAssessing',
        pLoad
      )
        .then((response) => {
          console.log('====== SET finishedAssessingWrittenWork GOT RESPONSE :')
          console.log(response.data)
          resolve({
            success: true
          })
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** finishedAssessingWrittenWork had some problems : ',
            error
          )
          resolve({
            success: false,
            error:
              '*** ERROR *** finishedAssessingWrittenWork had some problems : ',
            errormsg: error
          })
        })
    }) // promise
  },
  sendCommonMessage(payload) {
    console.log('sendCommonMessage called (%j)', payload)
    return new Promise((resolve) => {
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: this.sid
      }
      this.postHTTP('v4l/sendCommonMessage/sendCommonMsg', pLoad)
        .then((response) => {
          // let responseData = response.data.dgraphresult
          console.log(
            '======= RESPONSE DATA FROM sendCommonMessage ====== (%j)',
            response
          )
          //    console.log(JSON.stringify(responseData))
          if (response.data.dgraphresult.success) {
            resolve({
              success: true,
              successMsg: 'Message sent successfully'
            })
          } else {
            resolve({
              success: false,
              error: true,
              errorMsg:
                response.data.dgraphresult.errorMsg ||
                '** ERROR ** NO MESSAGE SET ?'
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** sendCommonMessage had some problems ! : ' + error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check sendCommonMessage had some problems: ' +
              error
          })
        })
    }) // promise
  },
  sendIndividualMessage(payload) {
    console.log('sendIndividualMessage called (%j)', payload)
    return new Promise((resolve) => {
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: this.sid
      }
      this.postHTTP('v4l/sendIndividualMessage/sendIndividualeMsg', pLoad)
        .then((response) => {
          // let responseData = response.data.dgraphresult
          console.log(
            '======= RESPONSE DATA FROM sendIndividualMessage ====== (%j)',
            response
          )
          if (response.data.dgraphresult.success) {
            resolve({
              success: true,
              successMsg: 'Message sent successfully'
            })
          } else {
            resolve({
              success: false,
              error: true,
              errorMsg:
                response.data.dgraphresult.errorMsg ||
                '** ERROR ** NO MESSAGE SET ?'
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** sendIndividualMessage had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check sendIndividualMessage had some problems: ' +
              error
          })
        })
    }) // promise
  },
  sendIndividualMarkedMessage(payload) {
    console.log('sendIndividualMessage called (%j)', payload)
    return new Promise((resolve) => {
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: this.sid
      }
      this.postHTTP(
        'v4l/sendIndividualMarkedMessage/sendIndividualeMarkedMsg',
        pLoad
      )
        .then((response) => {
          // let responseData = response.data.dgraphresult
          console.log(
            '======= RESPONSE DATA FROM sendIndividualMarkedMessage ====== (%j)',
            response
          )
          if (response.data.dgraphresult.success) {
            resolve({
              success: true,
              successMsg: 'Message sent successfully'
            })
          } else {
            resolve({
              success: false,
              error: true,
              errorMsg:
                response.data.dgraphresult.errorMsg ||
                '** ERROR ** NO MESSAGE SET ?'
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** sendIndividualMarkedMessage had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check sendIndividualMarkedMessage had some problems: ' +
              error
          })
        })
    }) // promise
  },
  setForceEndingAssignment(payload) {
    console.log('setForceEndingAssignment got called (%J)', payload)
    return new Promise((resolve) => {
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: this.sid
      }
      this.postHTTP('v4l/forceEndingAssignment/forceEnd', pLoad)
        .then((response) => {
          // let responseData = response.data.dgraphresult
          console.log(
            '======= RESPONSE DATA FROM setForceEndingAssignment ====== (%j)',
            response
          )
          if (response.data.dgraphresult.success) {
            this.populateEducatorInfo()
            resolve({
              success: true,
              successMsg: 'Ended assignment forcefully'
            })
          } else {
            resolve({
              success: false,
              error: true,
              errorMsg:
                response.data.dgraphresult.errorMsg ||
                '** ERROR ** NO MESSAGE SET ?'
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** setForceEndingAssignment had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check setForceEndingAssignment had some problems: ' +
              error
          })
        })
    }) // promise
  },
  getUnassessedAssignments() {
    return new Promise((resolve) => {
      let educatorInfo = this.educatorinfo
      let educatorUid = educatorInfo.uid
      let load = `{
        var (func: uid(${educatorUid})) {
          uid
          educatorHasSubject {
            uid
            educatorHasCommonSubjectGroup {
              uid
              hasWrittenAssignment @filter(eq(writtenassignment_assessedby_type, "learner")){
                 uid
                ~learnerwrittenassignment_assignment
                      @filter(eq(learnerwrittenassignment_isAssessed,false) and
                        gt(learnerwrittenassignment_ended,"2012-01-01T01:01:01Z")) {
                          learnerwrittenassignment_assignment {
                        assignmentUid as uid

                    }
                }
              }
            }
          }
        }

        query(func: uid(assignmentUid)) {
            uid
            writtenassignment_start_date
            writtenassignment_end_date
            writtenassignment_language
            writtenassignment_learningdiary
            writtenassignment_title
            writtenassignment_assessedby_type
            writtenassignment_afl_type
        }
      }
      `
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/unassessedassignments', pLoad)
        .then((response) => {
          console.log('============================ RESPONSE ER =======')
          console.log(response)
          let responseData = response.data
          console.log(
            '======= RESPONSE DATA FROM getUnassessedAssignments ======'
          )
          console.log(JSON.stringify(responseData))
          if (responseData.noresult) {
            resolve({
              success: true,
              data: []
            })
          } else if (responseData.success) {
            resolve({
              success: true,
              data: responseData.dgraphresult || []
            })
          } else {
            // error
            resolve({
              success: false,
              error: true,
              errorMsg: 'no data from query',
              data: []
            })
          }
        })
        .catch((error) => {
          console.log(
            '*** ERROR **** getUnassessedAssignments had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getUnassessedAssignments had some problems: ' +
              error
          })
        })
    })
  },
  getLearnersToAssignment(payload) {
    return new Promise((resolve) => {
      let assignmentUid = payload.assignmentUid
      let load = ` {
        var (func: uid(${assignmentUid})) {
            uid
           ~learnerwrittenassignment_assignment {
              ~hasLearnerSubjectAssignment {
                learner: ~learnerHasSubject {
                  learnerUid as uid
                }
              }
            }
        }
        query(func: uid(learnerUid)) {
          uid
          learner_displayName
          learner_picture
          learner_schoolClassName
          learner_eduPersonPrincipalName
        }
      }
      `
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/learnersToAssignment', pLoad)
        .then((response) => {
          console.log('============================ RESPONSE ER =======')
          console.log(response)
          let responseData = response.data.dgraphresult
          console.log(
            '======= RESPONSE DATA FROM getLearnersToAssignment ======'
          )
          console.log(responseData)
          resolve({
            success: true,
            data: responseData
          })
        })
        .catch((error) => {
          console.log(
            '*** ERROR **** getLearnersToAssignment had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getLearnersToAssignment had some problems: ' +
              error
          })
        })
    })
  },
  setPeerReviewLearnersMap(payload) {
    console.log('setPeerReviewLearnersMap got called (%J)', payload)
    return new Promise((resolve) => {
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: this.sid
      }
      this.postHTTP(
        'v4l/setPeerReviewLearners/peerReviewMapLearners',
        pLoad
      ).then((response) => {
        // let responseData = response.data.dgraphresult
        console.log(
          '======= RESPONSE DATA FROM setPeerReviewLearnersMap ====== (%j)',
          response
        )
        if (response.data.dgraphresult.success) {
          this.populateEducatorInfo()
          resolve({
            success: true,
            successMsg: 'Mapped learners for peer review successfully'
          })
        } else {
          resolve({
            success: false,
            error: true,
            errorMsg:
              response.data.dgraphresult.errorMsg ||
              '** ERROR ** NO MESSAGE SET ?'
          })
        }
      })
    }) // promise
  },
  getLearnerAssessedAssignments() {
    return new Promise((resolve) => {
      let educatorInfo = this.educatorinfo
      let educatorUid = educatorInfo.uid
      let load = `{
        var (func: uid(${educatorUid})) {
          uid
          educatorHasSubject {
            uid
            educatorHasCommonSubjectGroup {
              uid
              hasWrittenAssignment @filter(eq(writtenassignment_assessedby_type, "learner")){
                 uid
                ~learnerwrittenassignment_assignment
                      @filter(eq(learnerwrittenassignment_isAssessed,true) and
                        ge(learnerwrittenassignment_ended,"2002-01-01T01:01:01Z")) {
                          learnerwrittenassignment_assignment {
                        assignmentUid as uid

                    }
                }
              }
            }
          }
        }

        query(func: uid(assignmentUid)) {
            uid
            writtenassignment_start_date
            writtenassignment_end_date
            writtenassignment_language
            writtenassignment_learningdiary
            writtenassignment_title
            writtenassignment_assessedby_type
            writtenassignment_afl_type
        }
      }
      `
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/learnerassessedassignments', pLoad)
        .then((response) => {
          console.log('============================ RESPONSE ER =======')
          console.log(response)
          let responseData = response.data.dgraphresult
          console.log(
            '======= RESPONSE DATA FROM getLearnerAssessedAssignments ======'
          )
          console.log(responseData)
          if (responseData.noresult) {
            resolve({
              success: true,
              data: []
            })
          } else if (responseData.length > 0) {
            resolve({
              success: true,
              data: responseData
            })
          } else {
            // error
            resolve({
              success: false,
              error: true,
              errorMsg: 'no data from query',
              data: []
            })
          }
        })
        .catch((error) => {
          console.log(
            '*** ERROR **** getUnassessedAssignments had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getUnassessedAssignments had some problems: ' +
              error
          })
        })
    })
  },
  getLearnersPeerReview(payload) {
    return new Promise((resolve) => {
      let assignmentUid = payload.assignmentUid
      let load = ` {
        query(func: uid("${assignmentUid}")) @filter(eq(writtenassignment_assessedby_type, "learner")) {
          uid
           writtenassignment_assessedby_type
           writtenassignment_afl_type
           writtenassignment_learningdiary
           writtenassignment_title
           writtenassignment_start_date
           writtenassignment_end_date
           writtenassignment_language
           writtenassignment_duration
           writtenassignment_competencyGoal {
            uid
            educatorcurriculum_competencygoal
            educatorcurriculum_grepkode
          }
          writtenassignmentHasGroupFeedback {
            uid
             writtenfeedback_proposal
             writtenfeedback_assessed_date
             writtenfeedback_type
          }
          writtenassignmentHasGuidance {
            uid
            writtenguidance_type
             writtenguidance_at
             writtenguidance_explanation
          }
          learnerAssignment: ~learnerwrittenassignment_assignment {
             uid
             learnerwrittenassignment_duration
             learnerwrittenassignment_assessedDate
             learnerwrittenassignment_htmlBid
             learnerwrittenassignment_langAIBid
             learnerwrittenassignment_llmBid
             learnerwrittenassignment_aflfeedback_blob
             learnerwrittenassignment_rawText
             learnerwrittenassignment_started
             learnerwrittenassignment_ended
             learnerwrittenassignment_num_words
            learnerwrittenassignment_numWrittenWords
            learnerwrittenassignment_hasIndividualFeedback (orderasc: writtenfeedback_assessed_date) {
               uid
              writtenfeedback_assessed_date
              writtenfeedback_assessedby_type
             writtenfeedback_location
             writtenfeedback_comment
              writtenfeedback_proposal
              writtenfeedback_orgtext
              writtenfeedback_type
            }
           learnerwrittenassignment_hasFeedback (orderdesc: writtenfeedback_type, orderasc: writtenfeedback_assessed_date) {
             uid
             writtenfeedback_assessed_date
             writtenfeedback_assessedby_type
             writtenfeedback_location
             writtenfeedback_comment
             writtenfeedback_proposal
             writtenfeedback_orgtext
             writtenfeedback_type
             learnerFeedback: writtenfeedback_assessedby  {
              uid
             learner_displayName
            }
          }
          learnerSubjectAssignment: ~hasLearnerSubjectAssignment {
            uid
            learner_schoolsubject_displayName
            learnerSubject: ~learnerHasSubject {
               learner_displayName
             }
          }
        }
        }
      }
      `
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHHTTP('dgraph-api/dquery/learnersPeerReview', pLoad)
        .then((response) => {
          console.log(
            '============================ RESPONSE ER getLearnersPeerReview ======='
          )
          console.log(response)
          let responseData = response.data.dgraphresult
          console.log('======= RESPONSE DATA FROM getLearnersPeerReview ======')
          console.log(responseData)
          resolve({
            success: true,
            data: responseData
          })
        })
        .catch((error) => {
          console.log(
            '*** ERROR **** getLearnersPeerReview had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getLearnersPeerReview had some problems: ' +
              error
          })
        })
    })
  },
  getEducatorsPeerReview(payload) {
    return new Promise((resolve) => {
      let assignmentUid = payload.assignmentUid
      let load = ` {
        query(func: uid("${assignmentUid}")) @filter(eq(writtenassignment_assessedby_type, "educator")) {
          uid
           writtenassignment_assessedby_type
           writtenassignment_afl_type
           writtenassignment_learningdiary
           writtenassignment_title
           writtenassignment_start_date
           writtenassignment_end_date
           writtenassignment_language
           writtenassignment_duration
           writtenassignment_competencyGoal {
            uid
            educatorcurriculum_competencygoal
            educatorcurriculum_grepkode
          }
          writtenassignmentHasGroupFeedback {
            uid
             writtenfeedback_proposal
             writtenfeedback_assessed_date
             writtenfeedback_type
          }
          writtenassignmentHasGuidance {
            uid
             writtenguidance_type
             writtenguidance_at
             writtenguidance_explanation
          }
          learnerAssignment: ~learnerwrittenassignment_assignment @filter(eq(learnerwrittenassignment_isAssessed, true)) {
             uid
             learnerwrittenassignment_isAssessed
             learnerwrittenassignment_duration
             learnerwrittenassignment_assessedDate
             learnerwrittenassignment_htmlBid
             learnerwrittenassignment_langAIBid
             learnerwrittenassignment_llmBid
             learnerwrittenassignment_aflfeedback_blob
             learnerwrittenassignment_rawText
             learnerwrittenassignment_started
             learnerwrittenassignment_ended
             learnerwrittenassignment_num_words
             learnerwrittenassignment_numWrittenWords
             learnerwrittenassignment_hasIndividualFeedback (orderasc: writtenfeedback_assessed_date) {
               uid
              writtenfeedback_assessed_date
              writtenfeedback_assessedby_type
              writtenfeedback_location
              writtenfeedback_comment
              writtenfeedback_proposal
              writtenfeedback_orgtext
              writtenfeedback_type
            }
           learnerwrittenassignment_hasFeedback (orderdesc: writtenfeedback_type, orderasc: writtenfeedback_assessed_date) {
             uid
             writtenfeedback_assessed_date
             writtenfeedback_assessedby_type
             writtenfeedback_location
             writtenfeedback_comment
             writtenfeedback_proposal
             writtenfeedback_orgtext
             writtenfeedback_type
             learnerFeedback: writtenfeedback_assessedby  {
              uid
              educator_displayName
            }
          }
          learnerSubjectAssignment: ~hasLearnerSubjectAssignment {
            uid
            learner_schoolsubject_displayName
            learnerSubject: ~learnerHasSubject {
               learner_displayName
             }
          }
        }
        }
      }
      `
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/educatorspeerreviewed', pLoad)
        .then((response) => {
          console.log(
            '============================ RESPONSE ER getLearnersPeerReview ======='
          )
          console.log(response)
          let responseData = response.data.dgraphresult
          console.log('======= RESPONSE DATA FROM getLearnersPeerReview ======')
          console.log(responseData)
          resolve({
            success: true,
            data: responseData
          })
        })
        .catch((error) => {
          console.log(
            '*** ERROR **** getLearnersPeerReview had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getLearnersPeerReview had some problems: ' +
              error
          })
        })
    })
  },

  getLearnersForEducatorsInSubject(payload) {
    return new Promise((resolve) => {
      let commonSubjectUid = payload.commonSubjectUid
      let load = ` {
        var(func: uid(${commonSubjectUid})) {
            uid
            ~learnerHasCommonSubjectGroup {
              ~learnerHasSubject {
                luid as uid
               }
           }
        }
        query(func: uid(luid))  {
          uid
          learner_schoolClassName
          learner_displayName
          learner_picture
          }
      }
      `
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/learnerstocommonsubject', pLoad)
        .then((response) => {
          console.log('============================ RESPONSE ER =======')
          console.log(response)
          let responseData = response.data.dgraphresult
          console.log(
            '======= RESPONSE DATA FROM getLearnersForEducatorsInSubject ======'
          )
          console.log(responseData)
          resolve({
            success: true,
            data: responseData
          })
        })
        .catch((error) => {
          console.log(
            '*** ERROR **** getLearnersForEducatorsInSubject had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getLearnersForEducatorsInSubject had some problems: ' +
              error
          })
        })
    })
  },
  getLearnersToSubject(payload) {
    console.log('getLearnersToSubject got called')
    return new Promise((resolve) => {
      let subjectUid = payload.subjectUid
      let load = `{
          query(func: uid(${subjectUid})) @cascade @normalize {
           	educatorHasCommonSubjectGroup {
				      commonSubject: ~learnerHasCommonSubjectGroup {
                feideId: learner_schoolsubject_feide_id
				        learners: ~learnerHasSubject {
              		learnerUid: uid
				          learnerName: learner_displayName
                  learnerHasSubject {
                    hasLearnerSubjectAssignment
                    @filter(eq(learnerwrittenassignment_isAssessed, false) and
                            gt(learnerwrittenassignment_ended, "2002-01-01T00:00:00Z"))
                    {
                        ended: learnerwrittenassignment_ended
                        started: learnerwrittenassignment_started
                    }
                }
                }
              }
            }
          }
        }`
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/learnerSubject', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult
          console.log('======= RESPONSE DATA FROM getLearnersToSubject ======')
          console.log(responseData)
          if (responseData.noresult) {
            resolve({
              success: true,
              noresult: true,
              data: []
            })
          } else if (responseData.error) {
            resolve({
              success: false,
              error: true,
              errorMsg: responseData.error
            })
          } else {
            resolve({
              success: true,
              error: false,
              data: responseData
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** getLearnersToSubject had some problems ! : ' + error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getLearnersToSubject had some problems: ' +
              error
          })
        })
    }) // promise
  },
  findFeedbackToAssignment(payload) {
    console.log('findFeedbackToAssignment got called (%j)', payload)
    return new Promise((resolve) => {
      // let assignmentUid = payload.assignmentUid
      let learnerwrittenassignmentUid = payload.learnerwrittenassignmentuid
      let load = `{
        query(func: uid(${learnerwrittenassignmentUid})) @cascade {
          learnerwrittenassignment_hasFeedback {
            uid
            writtenfeedback_assessedby
            writtenfeedback_assessedby_type
            writtenfeedback_assessed_date
            writtenfeedback_type
            writtenfeedback_comment
            writtenfeedback_proposal
            writtenfeedback_orgtext
            writtenfeedback_location
            }
          }
        }
    `
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/findFeedback', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult
          console.log(
            '======= RESPONSE DATA FROM findFeedbackToAssignment ======'
          )
          console.log(responseData)
          if (responseData.noresult) {
            resolve({
              success: true,
              noresult: true,
              data: []
            })
          } else if (responseData.error) {
            resolve({
              success: false,
              error: true,
              errorMsg: responseData.error
            })
          } else {
            resolve({
              success: true,
              error: false,
              data: responseData
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** getAFLLearerAssignments had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getAFLLearerAssignments had some problems: ' +
              error
          })
        })
    }) // promise
  },
  getAFLLearerAssignments_with_educator_assignments(payload) {
    console.log('getAFLLearerAssignments got called')
    return new Promise((resolve) => {
      let assignmentUid = payload.assignmentUid
      let load = `{
        query(func: uid(${assignmentUid})) @cascade {
          uid
    			writtenassignment_title
  				writtenassignment_afl_type
    			writtenassignment_start_date
    			writtenassignment_end_date
          writtenassignment_language
          learnerAFLAssignment: ~learnerwrittenassignment_assignment @filter(eq(learnerwrittenassignment_aflstep,1)) 						{
            uid
            learnerwrittenassignment_isAssessed
      			learnerwrittenassignment_htmlBid
            learnerwrittenassignment_langAIBid
            learnerwrittenassignment_llmBid
            learnerwrittenassignment_aflfeedback_blob
            learnerwrittenassignment_rawText
      			learnerwrittenassignment_num_words
      			learnerwrittenassignment_numWrittenWords
      			learnerwrittenassignment_ended
      			learnerwrittenassignment_started
      			learnerwrittenassignment_aflstep
      			learnerwrittenassignment_aflfeedback_blob
            learnerwrittenassignment_hasFeedback {
              uid
              writtenfeedback_assessedby
              writtenfeedback_assessedby_type
              writtenfeedback_assessed_date
              writtenfeedback_type
              writtenfeedback_comment
              writtenfeedback_proposal
              writtenfeedback_orgtext
              writtenfeedback_location
              }
      			learnerwrittenassignment_next_assignment {
							uid
      	      learnerwrittenassignment_isAssessed
        			learnerwrittenassignment_htmlBid
              learnerwrittenassignment_langAIBid
              learnerwrittenassignment_llmBid
              learnerwrittenassignment_aflfeedback_blob
              learnerwrittenassignment_num_words
      				learnerwrittenassignment_numWrittenWords
      				learnerwrittenassignment_ended
      				learnerwrittenassignment_started
      				learnerwrittenassignment_aflstep
      				learnerwrittenassignment_aflfeedback_blob
      				learnerwrittenassignment_next_assignment {
        				uid
              	learnerwrittenassignment_isAssessed
      					learnerwrittenassignment_htmlBid
                learnerwrittenassignment_langAIBid
                learnerwrittenassignment_llmBid
                learnerwrittenassignment_aflfeedback_blob
                learnerwrittenassignment_rawText
      					learnerwrittenassignment_num_words
      					learnerwrittenassignment_numWrittenWords
      					learnerwrittenassignment_ended
      					learnerwrittenassignment_started
      					learnerwrittenassignment_aflstep
      					learnerwrittenassignment_aflfeedback_blob
                learnerwrittenassignment_hasFeedback {
                  uid
                  writtenfeedback_assessedby
                  writtenfeedback_assessedby_type
                  writtenfeedback_assessed_date
                  writtenfeedback_type
                  writtenfeedback_comment
                  writtenfeedback_proposal
                  writtenfeedback_orgtext
                  writtenfeedback_location
                  }
								}
      			}
		      learnerSubjectInfo: ~hasLearnerSubjectAssignment {
            uid
            learner_schoolsubject_feide_id
            learnerInfo: ~learnerHasSubject {
              uid
              learner_displayName
              learner_eduPersonPrincipalName
            }
          }
			  }
	     }
      }`
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/learerAssignments', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult
          console.log(
            '======= RESPONSE DATA FROM getAFLLearerAssignments_with_educator_assignments ======'
          )
          console.log(responseData)
          if (responseData.noresult) {
            resolve({
              success: true,
              noresult: true,
              data: []
            })
          } else if (responseData.error) {
            resolve({
              success: false,
              error: true,
              errorMsg: responseData.error
            })
          } else {
            resolve({
              success: true,
              error: false,
              data: responseData
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** getAFLLearerAssignments_with_educator_assignments had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getAFLLearerAssignments_with_educator_assignments had some problems: ' +
              error
          })
        })
    }) // promise
  },
  getAFLLearerAssignments_before_speed_optimized_notused(payload) {
    console.log('getAFLLearerAssignments got called')
    return new Promise((resolve) => {
      let assignmentUid = payload.assignmentUid
      let load = `{
        var(func: uid(${assignmentUid})) @cascade {
          uid
    			 ~learnerwrittenassignment_assignment @filter(eq(learnerwrittenassignment_aflstep,1)) {
            learnerAssignmentUid as uid
           }
        }
        query(func: uid(learnerAssignmentUid)) @cascade {
            uid
            learnerwrittenassignment_isAssessed
      			learnerwrittenassignment_htmlBid
            learnerwrittenassignment_langAIBid
            learnerwrittenassignment_llmBid
            learnerwrittenassignment_aflfeedback_blob
            learnerwrittenassignment_num_words
      			learnerwrittenassignment_numWrittenWords
      			learnerwrittenassignment_started
      			learnerwrittenassignment_aflstep
      			learnerwrittenassignment_aflfeedback_blob
      			learnerwrittenassignment_next_assignment {
        			uid
				      learnerwrittenassignment_isAssessed
      				learnerwrittenassignment_htmlBid
              learnerwrittenassignment_langAIBid
              learnerwrittenassignment_llmBid
              learnerwrittenassignment_aflfeedback_blob
              learnerwrittenassignment_num_words
      				learnerwrittenassignment_numWrittenWords
      				learnerwrittenassignment_started
      				learnerwrittenassignment_aflstep
      				learnerwrittenassignment_aflfeedback_blob
      				learnerwrittenassignment_next_assignment {
        				uid
              	learnerwrittenassignment_isAssessed
      					learnerwrittenassignment_htmlBid
                learnerwrittenassignment_langAIBid
                learnerwrittenassignment_llmBid
                learnerwrittenassignment_aflfeedback_blob
                learnerwrittenassignment_rawText
                learnerwrittenassignment_langAIBid
      					learnerwrittenassignment_num_words
      					learnerwrittenassignment_numWrittenWords
      					learnerwrittenassignment_started
      					learnerwrittenassignment_aflstep
      					learnerwrittenassignment_aflfeedback_blob
                }
							}
		        learnerSubjectInfo: ~hasLearnerSubjectAssignment {
              uid
              learner_schoolsubject_feide_id
              learnerInfo: ~learnerHasSubject {
                uid
                learner_displayName
                learner_eduPersonPrincipalName
              }
          }
			  }
      }`
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/learerAssignments', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult
          console.log(
            '======= RESPONSE DATA FROM getAFLLearerAssignments ======'
          )
          console.log(responseData)
          if (responseData.noresult) {
            resolve({
              success: true,
              noresult: true,
              data: []
            })
          } else if (responseData.error) {
            resolve({
              success: false,
              error: true,
              errorMsg: responseData.error
            })
          } else {
            resolve({
              success: true,
              error: false,
              data: responseData
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** getAFLLearerAssignments had some problems ! : ' +
              error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getAFLLearerAssignments had some problems: ' +
              error
          })
        })
    }) // promise
  },
  getAFLLearnerAssignments(payload) {
    console.log('getAFLLearnerAssignments got called')
    return new Promise((resolve) => {
      let load = { assignmentUid: payload.assignmentUid }
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(load)),
        p1: this.sid
      }
      this.postHTTP(
        'v4l/getAFLLearnerAssignments/getAfLAssignments',
        pLoad
      ).then((response) => {
        console.log(
          '======= RESPONSE DATA FROM getAFLLearnerAssignments ====== (%j)',
          response
        )
        if (response.data.dgraphresult.success) {
          resolve({
            success: true,
            data: response.data.dgraphresult
          })
        } else {
          resolve({
            success: false,
            error: true,
            errorMsg: '** ERROR ** NO DATA FOUND...'
          })
        }
      })
    }) // promise
  },
  summarizeAllFeedback(payload) {
    console.log('summarizeAllFeedback got called')
    return new Promise((resolve) => {
      let assignmentUid = payload.uid
      let load = `{"assignmentUid": "${assignmentUid}"}`
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('v4l/summarizeall/sumFeedback', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult
          console.log('======= RESPONSE DATA FROM summarizeAllFeedback ======')
          console.log(responseData)
          if (responseData.noresult) {
            resolve({
              success: true,
              nodata: true,
              data: []
            })
          } else if (responseData.error) {
            resolve({
              success: false,
              error: true,
              errorMsg: responseData.error
            })
          } else {
            resolve({
              success: true,
              error: false,
              data: responseData
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** summarizeAllFeedback had some problems ! : ' + error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check summarizeAllFeedback had some problems: ' +
              error
          })
        })
    }) // promise
  },
  getDataFromInfoUrl(payload) {
    console.log('getUrlData got called ')
    console.log(payload)
    //  console.log(JSON.stringify(payload))
    return new Promise((resolve) => {
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: this.sid
      }
      this.postHTTP('v4l/getDataFromInfoUrl/infoUrl', pLoad)
        .then((result) => {
          console.log('getDataFromInfoUrl returned')
          console.log(result.data.dgraphresult)
          resolve({
            success: true,
            html: decodeLoad(result.data.dgraphresult.htmlData)
          })
        })
        .catch((error) => {
          console.log('COULD NOT GET URL DATA', error)
          resolve({
            success: false,
            error: true,
            errorMsg: JSON.stringify(error)
          })
        })
    }) // promise
  },
  getLanguageInformation(htmlid) {
    console.log('getLanguageInformation got called (%s)', htmlid)
    //let htmlidLength = htmlid.length || -1
    return new Promise((resolve) => {
      if (htmlid === '') {
        resolve({
          success: false,
          error: true,
          noinput: true,
          errorMsg: 'Hard to read a file with no name...'
        })
        return
      }
      if (htmlid === undefined || htmlid.length < 3) {
        resolve({
          success: false,
          error: true,
          nodata: true,
          errorMsg: 'No html data yet - most likely new record'
        })
        return
      }
      let pLoad = {
        b64html: encodeLoad(htmlid),
        p1: this.sid
      }
      this.postHTTP('v4l/getlanginfo/langInfo', pLoad).then((response) => {
        // console.log('RESPOSE FROM GET 64 is (%j)', response)
        let responseData = response.data.dgraphresult || {}
        if (responseData.success) {
          resolve({
            success: true,
            sentiment: responseData,
            sentimentScore: responseData.sentimentScore
          }) // CHANGE SUCCSS TO TRUE !!
        } else {
          resolve({
            success: false,
            error: true,
            errorMsg: responseData.errorMsg
          }) // CHANGE SUCCSS TO TRUE !!
        }
      })
    }) // promise
  },
  saveRubricAssignment(payload) {
    console.log('saveRubricAssignment got called (%j)', payload)
    return new Promise((resolve) => {
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: this.sid
      }
      this.postHTTP('v4l/saveRubricAssignment/saveRubric', pLoad).then(
        (response) => {
          console.log('RESPOSE FROM saveRubric is (%j)', response)
          let responseData = response.data.dgraphresult || {}
          if (responseData.success) {
            resolve({
              success: true
            })
          }
        }
      )
    }) //promise
  },
  saveEditorContentEvaluate(payload) {
    console.log('saveEditorContentEvaluate got called ! (%j)', payload)
    // let encHtmlText = ''
    /*
      When we in the editor load document, do save, and then submit the initial encoded html
      is not replaced with decoded hmlt, so we end up with a double encoded text that is why
      we test to check if the content already is base64 encoded.
      The same happened if save was pressed more than once without any edits..
      */
    let encRawText = encodeLoad(payload.rawText || '')
    return new Promise((resolve) => {
      let htmlLoad = {
        html: encodeLoad(payload.information_content),
        bucket_file: payload.htmlBid || ''
      }
      this.storeB64HTML(htmlLoad).then((result) => {
        console.log('RESULT FROM STORE B64HTML is (%j)', result)
        if (result.success) {
          payload.htmlBid = result.htmlBid
          payload.rawText = encRawText
          // payload.information_content = 'IKKE I BRUK' // not used since content is stored in bucket
          let pLoad = {
            mutate: encodeLoad(JSON.stringify(payload)),
            p1: this.sid
          }
          this.postHTTP(
            'v4l/saveEditorContentEvaluate/saveEditorContentEvaluate',
            pLoad
          )
            .then((response) => {
              let data = response.data || {}
              console.log('>>>> RESULT SAVE EDITOR >>>>>')
              console.log(data)
              if (response.data.error) {
                this.clientLogout()
                this.$router.push({ name: 'MustLogin' }).catch(() => {})

                return
              }
              if (data.dgraphresult.success) {
                resolve({
                  success: true,
                  learnerAssignmentUid: data.dgraphresult.learnerAssignmentUid,
                  htmlBid: result.htmlBid
                })
              } else {
                resolve({
                  success: false,
                  error: true,
                  errorMsg: JSON.stringify(response)
                })
              }
            })
            .catch((error) => {
              resolve({
                success: false,
                error: true,
                errorMsg: error.toString()
              })
            })
        } else {
          // store b64html success = false
          console.log('*** ERROR *** COULD NOT STORE B64HTML in bucket !')
          resolve({
            success: false,
            error: true,
            errorMsg: '*** ERROR *** COULD NOT STORE B64HTML in bucket '
          })
        }
      })
    }) // promise
  },
  async storeB64HTML(payload) {
    // console.log('storeB64HTML got called (%j)', payload)
    return new Promise((resolve) => {
      let pLoad = {
        b64html: payload.html,
        bucket_file: payload.bucket_file,
        p1: this.sid
      }
      if (payload.html === '') {
        resolve({ success: true, htmlBid: '' })
      } else {
        this.postHTTP('v4lbucket/b64htmlupload/uploadHTMLB64', pLoad).then(
          (response) => {
            console.log('RESPOSE FROM SAVE 64 is (%j)', response)
            let responseData = response.data.dgraphresult || {}
            if (response.data.error) {
              this.clientLogout()
              this.$router.push({ name: 'MustLogin' }).catch(() => {})

              return
            }
            if (responseData.success) {
              resolve({
                success: true,
                htmlBid: responseData.bucketid || 'NO bucket ID'
              }) // CHANGE SUCCSS TO TRUE !!
            } else {
              resolve({
                success: false,
                error: true,
                errorMsg: responseData.errorMsg
              }) // CHANGE SUCCSS TO TRUE !!
            }
          }
        )
      } // else content
    }) // promise
  },
  async getB64HTML(htmlid) {
    console.log('getB64HTML got called (%s)', htmlid)
    //let htmlidLength = htmlid.length || -1
    return new Promise((resolve) => {
      if (htmlid === '') {
        resolve({
          success: false,
          b64htmlcontent: 'e30=',
          error: true,
          noinput: true,
          errorMsg: 'Hard to read a file with no name...'
        })
        return
      }
      if (htmlid === undefined || htmlid.length < 3) {
        resolve({
          success: false,
          error: true,
          nodata: true,
          errorMsg: 'No html data yet - most likely new record'
        })
        return
      }
      let pLoad = {
        b64html: htmlid,
        p1: this.sid
      }
      this.postHTTP('v4lbucket/getb64html/getB64HTML', pLoad).then(
        (response) => {
          console.log('RESPOSE FROM GET 64 is (%j)', response)
          let responseData = response.data.dgraphresult || {}
          if (responseData.success) {
            resolve({
              success: true,
              b64htmlcontent: responseData.b64htmlcontent || 'nothing got'
            }) // CHANGE SUCCSS TO TRUE !!
          } else {
            resolve({
              success: false,
              error: true,
              errorMsg: responseData.errorMsg
            }) // CHANGE SUCCSS TO TRUE !!
          }
        }
      )
    }) // promise
  },
  getLearnerAssignment(payload) {
    console.log('getLearnerAssignment got called ')
    return new Promise((resolve) => {
      let assignmentUid = payload.assignmentUid
      let load = `{
        query(func: uid(${assignmentUid})) {
          uid
          learnerwrittenassignment_duration
          learnerwrittenassignment_assessedby
          learnerwrittenassignment_started
          learnerwrittenassignment_ended
          learnerwrittenassignment_isAssessed
          learnerwrittenassignment_htmlBid
          learnerwrittenassignment_langAIBid
          learnerwrittenassignment_llmBid
          learnerwrittenassignment_aflfeedback_blob
          learnerwrittenassignment_time_left
          learnerwrittenassignment_rawText
          learnerwrittenassignment_hasIndividualFeedback
          learnerwrittenassignment_assignment {
            uid
            writtenassignment_afl_type
            writtenassignment_assessedby_type
            writtenassignment_duration
            writtenassignment_num_words
            writtenassignment_explanation
            writtenassignment_start_date
            writtenassignment_end_date
            writtenassignment_language
            writtenassignment_template_document
            writtenassignment_success_criteria
            writtenassignment_title
            writtenassignment_editor
            writtenassignment_aiContext
            writtenassignment_aiGenre
            writtenassignment_aiSentiment
            writtenassignment_aiPrompt
            writtenassignment_aiPrompt1
            writtenassignment_aiPrompt2
            writtenassignment_aiSuccesscriteria
            writtenassignment_aiEducatorsubjectcontext
            writtenassignment_aiEducatorsubjectprompt
            }
        }
      }`
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/learnerAssignment', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult
          console.log('======= RESPONSE DATA FROM getLearnerAssignment ======')
          console.log(responseData)
          if (responseData.noresult) {
            resolve({
              success: true,
              noresult: true,
              data: []
            })
          } else if (responseData.error) {
            resolve({
              success: false,
              error: true,
              errorMsg: responseData.error
            })
          } else {
            resolve({
              success: true,
              error: false,
              data: responseData
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** getLearnerAssignment had some problems ! : ' + error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getLearnerAssignment had some problems: ' +
              error
          })
        })
    }) // promise
  },
  getCompetencyItems(payload) {
    console.log('getCompetencyItems got called (%j)', payload)
    return new Promise((resolve) => {
      let commonSubjectUid = payload.educatorHasCommonSubjectGroup.uid
      let load = `{
        query(func: uid(${commonSubjectUid})) {
      	  educatorcurriculum {
						uid
            educatorcurriculum_competency_facts
            educatorcurriculum_competency_masters
            educatorcurriculum_competency_reflections
            educatorcurriculum_competency_skills
            educatorcurriculum_competency_criteria
            educatorcurriculum_competency_weeks
            educatorcurriculum_competencygoal
            educatorcurriculum_grepkode
          }
        }
      }`
      let pLoad = {
        query: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('dgraph-api/dquery/competencyGoals', pLoad)
        .then((response) => {
          // console.log('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult
          console.log('======= RESPONSE DATA FROM getCompetencyItems ======')
          console.log(responseData)
          if (responseData.noresult) {
            resolve({
              success: true,
              noresult: true,
              data: []
            })
          } else if (responseData.error) {
            resolve({
              success: false,
              error: true,
              errorMsg: responseData.error
            })
          } else {
            resolve({
              success: true,
              error: false,
              data: responseData
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** getCompetencyItems had some problems ! : ' + error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check getCompetencyItems had some problems: ' +
              error
          })
        })
    }) // promise
  },
  resetLearnerWrittenAssignmentEndTime(payload) {
    console.log('resetLearnerWrittenAssignmentEndTime got called')
    console.log(JSON.stringify(payload))
    return new Promise((resolve) => {
      let load = {
        learnerwrittenassignmentuid: payload.assignmentUid
      }

      let pLoad = {
        mutate: encodeLoad(JSON.stringify(load)),
        p1: this.sid
      }
      this.postHTTP('v4l/resetwrittenassignmenttime/resetassignmenttime', pLoad)
        .then((response) => {
          console.log(
            '====== DELETE resetLearnerWrittenAssignmentEndTime returned:'
          )
          console.log(response)
          if (response.data.dgraphresult.success) {
            resolve({
              success: true
            })
          } else {
            resolve({
              error: true,
              success: false
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR ***  resetLearnerWrittenAssignmentEndTime had some problems : ',
            error
          )
          resolve({
            error: true,
            success: false,
            errormsg:
              '*** ERROR ***  resetLearnerWrittenAssignmentEndTime had some problems : ' +
              error
          })
        })
    }) // promise
  },
  summarizeFeedbackGroup(payload) {
    console.log('summarizeFeedbackGroup got called')
    return new Promise((resolve) => {
      let assignmentUid = payload.uid
      let load = `{"assignmentUid": "${assignmentUid}"}`
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('v4l/summarizegroup/sumGroupFeedback', pLoad)
        .then((response) => {
          // console.info('============================ RESPONSE ER =======')
          let responseData = response.data.dgraphresult
          console.log('======= RESPONSE DATA FROM summarizeAllFeedback ======')
          console.log(responseData)
          if (responseData.noresult) {
            resolve({
              success: true,
              nodata: true,
              data: []
            })
          } else if (responseData.error) {
            resolve({
              success: false,
              error: true,
              errorMsg: responseData.error
            })
          } else {
            resolve({
              success: true,
              error: false,
              data: responseData.data
            })
          }
        })
        .catch((error) => {
          console.error(
            '*** ERROR **** summarizeAllFeedback had some problems ! : ' + error
          )
          resolve({
            success: false,
            error: true,
            errorMsg:
              '*** ERROR *** check summarizeAllFeedback had some problems: ' +
              error
          })
        })
    }) // promise
  },
  logIsLoggedIn(payload) {
    //  console.log('logIsLoggedIn got called: (%s)', payload)
    return new Promise((resolve) => {
      let load = `{
        "username": "${payload}",
        "loginType": "Educator"
      }`
      let pLoad = {
        mutate: encodeLoad(load),
        p1: this.sid
      }
      this.postHTTP('v4ladmin/logLoggedin/loggedin', pLoad)
      toast.info(t('gui.loggedin'))
      this.setLogInProcess(false)
      resolve({ success: true })
    }) // promise
  },
  /*
  setAFLAssignmentsMap( payload) {
    console.log('setAFLAssignmentsMap DISPATCH got called (%j)', payload)
    this.aflassignmentsmap = payload
  },
  */
  /* *************************************** */
  /*        FETCH HTTP                       */
  /* *************************************** */
  async postHTTPLong(url, loadData) {
    return new Promise((resolve, reject) => {
      let accessClientToken = this.serverAccessToken
      let serverUrl = this.serverUrl || localStorage.getItem('hypsaserver')
      console.log('postHTTPLong URL (%s)', checkRegistryURL(serverUrl) + url)
      let options = {
        method: 'POST',
        url: checkRegistryURL(serverUrl) + url,
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        credentials: 'include',
        responseType: 'json', // default
        responseEncoding: 'utf8', // default
        maxRedirects: 3, // default
        'Accept-Encoding': 'gzip, compress',
        body: JSON.stringify(loadData),
        headers: {
          Authorization: `Bearer ${accessClientToken}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          v4lclient: 'bettereducationforall' // for Cloud armor firewall rules
        }
      }
      fetch(options.url, options)
        .then((response) => {
          console.log('POST LONG HTTP RESPONSE (%J)', response)
          console.log(response)
          response
            .json()
            .then((result) => {
              console.log('DATA (%j)', result)
              resolve({ success: true, data: result })
            })
            .catch((err) => {
              console.error('FETCH JSON', err)
            })
        })
        .catch((error) => {
          toast.error(t('toast.errornetwork'))
          console.error('*** ERROR *** postHTTPLong', {
            errorMsg: error.toString()
          })
          reject({ success: false, error: true, errorMsg: error.toString() })
        })
    }) // promise
  },
  async postHTTPAI(url, loadData) {
    return new Promise((resolve, reject) => {
      let accessClientToken = this.serverAccessToken
      let serverUrl = this.serverUrl || localStorage.getItem('hypsaserver')
      if (location.hostname === 'v4leducator.localhost') {
        serverUrl = serverUrl.replace('8081', '8087')
      }
      console.log('postHTTPLong URL (%s)', checkRegistryURL(serverUrl) + url)
      let options = {
        method: 'POST',
        url: checkRegistryURL(serverUrl) + url,
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        credentials: 'include',
        timeout: 120000, // 12min
        responseType: 'json', // default
        responseEncoding: 'utf8', // default
        maxRedirects: 5, // default
        'Accept-Encoding': 'gzip, compress',
        body: JSON.stringify(loadData),
        headers: {
          Authorization: `Bearer ${accessClientToken}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          v4lclient: 'bettereducationforall' // for Cloud armor firewall rules
        }
      }
      fetch(options.url, options)
        .then((response) => {
          console.log('POST LONG HTTP RESPONSE (%J)', response)
          console.log(response)
          response
            .json()
            .then((result) => {
              console.log('DATA (%j)', result)
              resolve({ success: true, data: result })
            })
            .catch((err) => {
              console.error('FETCH JSON', err)
            })
        })
        .catch((error) => {
          toast.error(t('toast.errornetwork'))
          console.error('*** ERROR *** postHTTPLong')
          console.log(error)
          reject({ success: false, error: true, errorMsg: error.toString() })
        })
    }) // promise
  },
  async postHTTP(url, loadData) {
    return new Promise((resolve, reject) => {
      let accessClientToken = this.serverAccessToken
      let serverUrl = this.serverUrl || localStorage.getItem('hypsaserver')
      // console.log('postHTTP URL (%s)', checkRegistryURL(serverUrl) + url,)
      // console.log('LoadDATA (%j)', JSON.stringify(loadData))
      let options = {
        method: 'POST',
        url: checkRegistryURL(serverUrl) + url,
        mode: 'cors',
        credentials: 'include',
        timeout: 60000, // 6min
        responseType: 'json', // default
        responseEncoding: 'utf8', // default
        maxRedirects: 3, // default
        'Accept-Encoding': 'gzip, compress',
        body: JSON.stringify(loadData),
        headers: {
          Authorization: `Bearer ${accessClientToken}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          v4lclient: 'bettereducationforall' // for Cloud armor firewall rules
        }
      }
      fetch(options.url, options)
        .then((response) => {
          console.log('POST HTTP RESPONSE (%s)', response.ok)
          // console.log(response)
          response
            .json()
            .then((result) => {
              console.log('DATA (%j)', result)
              resolve({ success: true, data: result })
            })
            .catch((err) => {
              console.error('FETCH JSON', err)
            })
        })
        .catch((error) => {
          console.error('*** ERROR *** postHTTP', {
            errorMsg: error.toString()
          })
          reject({ success: false, error: true, errorMsg: error.toString() })
        })
    }) // promise
  },
  async getHTTP(url) {
    return new Promise((resolve, reject) => {
      let accessClientToken = this.serverAccessToken
      let serverUrl = this.serverUrl || localStorage.getItem('hypsaserver')
      console.log('GET HTTP URL (%s)', checkRegistryURL(serverUrl) + url)
      let options = {
        method: 'GET',
        url: checkRegistryURL(serverUrl) + url,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          v4lclient: 'bettereducationforall' // for Cloud armor firewall rules
        },
        mode: 'cors',
        // timeout: 30000, // 30s // controlled by the browser
        responseType: 'json', // default
        responseEncoding: 'utf8', // default
        maxRedirects: 3, // default
        'Accept-Encoding': 'gzip, compress'
        // body: "{foo: 'bar'}" //JSON.stringify(loadData),
      }

      fetch(options.url, options)
        .then((response) => {
          console.log('RESPONSE IS (%j)', response)
          response
            .json()
            .then((result) => {
              console.log('RESULT FROM JSON IS (%j)', result)
              resolve({ success: true, data: result })
            })
            .catch((err2) => {
              console.error('RESPONSE JSON', err2)
            })
        })
        .catch((error) => {
          console.error('*** ERROR *** getHTTP', { errorMsg: error.toString() })
          reject({ success: false, error: true, errorMsg: error.toString() })
        })
    }) // promise
  },
  async getHTTPSimple(url) {
    return new Promise((resolve, reject) => {
      console.log('GET getHTTPSimple URL (%s)', url)
      let options = {
        method: 'GET',
        url: url,
        headers: {
          //  'Content-Type': 'application/json'
        },
        mode: 'cors',
        // timeout: 30000, // 30s // controlled by the browser
        responseType: 'json', // default
        responseEncoding: 'utf8', // default
        maxRedirects: 3, // default
        'Accept-Encoding': 'gzip, compress'
        // body: "{foo: 'bar'}" //JSON.stringify(loadData),
      }
      fetch(options.url, options)
        .then((response) => {
          console.log('RESPONSE IS (%j)', response)
          response
            .json()
            .then((result) => {
              console.log('RESULT FROM JSON IS (%j)', result)
              resolve({ success: true, data: result })
            })
            .catch((err2) => {
              console.error('RESPONSE JSON', err2)
            })
        })
        .catch((error) => {
          console.error('*** ERROR *** getHTTPSimple', {
            errorMsg: error.toString()
          })
          console.info(error)
          reject({ success: false, error: true, errorMsg: error.toString() })
        })
    }) // promise
  },
  checkLocalhostServer(url) {
    if (location.hostname === 'v4leducator.localhost') {
      return url.replace('8081', '8087')
    } else {
      return url
    }
  },
  /* ***** OLD MUTATIONS FROM VUEX ***** */
  setPicture(payload) {
    this.picture = payload
  },
  setServerSysInfo(payload) {
    console.log(
      'SET SERVER SYS INFO GOT CALLED (' + JSON.stringify(payload) + ')'
    )
    this.serversysinfo = payload
  },
  setSid(sid) {
    this.sid = sid
  },
  setEducatorUid(payload) {
    this.educatoruid = payload
  },
  setLoginStatus(loginStatus) {
    if (this.eduParams.loggedIn !== undefined) {
      this.eduParams.loggedIn = loginStatus
    } else if (this.eduParams.displayName !== '') {
      console.error('*** ERROR **** : We do not have EDU PARAMS SET !')
      router.push({
        name: 'Error',
        params: {
          errHeading: 'We have lost the login information',
          errMessage:
            'You need to login again to continue, we are sorry for the inconvenience...'
        }
      })
    }
  },
  logout(state) {
    this.$reset()
    localStorage.removeItem('lid')
    localStorage.removeItem('sid')
    localStorage.removeItem('ssa')
    localStorage.removeItem('tokenexpire')
    localStorage.removeItem('verifierState')
    // localStorage.clear()
  },
  setAccessToken(token) {
    this.accessToken = token
  },
  setServerAccessToken(token) {
    this.serverAccessToken = token
  },
  setIdToken(idToken) {
    console.log('>>>>>>>>>>>> SET ID TOKEN >>>>>>>>>>>>>>>>>')
    console.log(idToken)
    this.idtoken = idToken
  },
  setAccessTokenExpires(tokenExpires) {
    this.accessTokenExpires = tokenExpires
  },
  setDisplayName(displayName) {
    this.displayName = displayName
  },
  nullDisplayName(state) {
    this.displayName = ''
  },
  setUserid(userid) {
    this.userid = userid
  },
  setAffiliation(affiliation) {
    this.affiliation = affiliation
  },
  setGroups(groups) {
    this.groups = groups
  },
  setLoggedIn(loggedin) {
    this.loggedin = loggedin
  },
  setBGroups(payload) {
    this.bgroups = payload
  },
  setUGroups(payload) {
    this.ugroups = payload
  },
  setOrgGroups(payload) {
    this.orggroups = payload
  },
  setFeideOrganisations(payload) {
    this.feideorganisations = payload
  },
  setEducatorInfo(payload) {
    this.educatorinfo = payload
  },
  nullFeideOrganisations(state) {
    this.feideorganisations = {}
  },
  setPublicKey(publicKey) {
    this.publicKey = publicKey
  },
  setAppSecret(appSecret) {
    this.appsecret = appSecret
  },
  setLearnerInfo(payload) {
    this.learnerinfo = payload
  },
  setGrepKompetanseMaal(payload) {
    this.grepKompetanseMaal = payload
  },
  setLearnerKmsData(payload) {
    this.learnerkmsdata = payload
  },
  setShowIntro(value) {
    this.showIntro = value
  },
  setLearnersHomeworkTime(payload) {
    console.log('setLearnersHomeworkTime got called:')
    console.log(payload)
    this.learnerhomeworktime = payload
  },
  setLearnersReadingTime(payload) {
    console.log('setLearnersReadingTime got called:')
    console.log(payload)
    this.learnerreadingtime = payload
  },
  setLearnersSchoolGroupAssesment(payload) {
    this.learnerschoolgroupassessment = payload
  },
  setLearnersSubjectFromSchoolClassGroup(payload) {
    this.learnersubjectschoolclass = payload
  },
  setLearnersSubjectAssessment(payload) {
    this.learnersubjectassessment = payload
  },
  setLearnersDoughnutKmsData(payload) {
    this.setlearnersdoughnutkms = payload
  },
  setSubjectWordCloudData(payload) {
    this.setsubjectwordcloud = payload
  },
  setSearchDate(payload) {
    this.searchdate = payload
  },
  setNewGroupSubject(payload) {
    this.groupSubject = payload
  },
  setLearnersSubjectInformationContribution(payload) {
    this.learnerssubjectinformationcontribution = payload
  },
  setUnreadNotesLearner(payload) {
    this.unreadnoteslearner = payload
  },
  setUnreadNotesSubject(payload) {
    this.unreadnotessubject = payload
  },
  setNumberOfUnreadNotes(payload) {
    this.numberofunreadnotes = payload
  },
  setEducatorCurriculum(payload) {
    this.educatorcurriculum = payload
  },
  setProudOfData(payload) {
    this.proudof = payload
  },
  setEnvironment(payload) {
    this.environment = payload
  },
  deleteLearningEnvironment(state) {
    this.learningenvironment = []
  },
  setLearningEnvironment(payload) {
    this.learningenvironment.push(payload)
  },
  setLearningEnvironmentNull(state) {
    this.learningenvironment = []
  },
  setSchoolClasses(payload) {
    this.schoolclasses = payload
  },
  setSocialCommonGroup(payload) {
    this.socialcommongroup = payload
  },
  setSocialQuestions(payload) {
    this.socialquestions = payload
  },
  setSchoolInfo(payload) {
    this.schoolinfo = payload
  },
  setLearnerSocialMap(payload) {
    this.learnersocialmap = payload
  },
  setLang(payload) {
    this.lang = payload
  },
  setLicense(payload) {
    this.license = payload
  },
  /* V4L */
  saveWrittenAssignmentToBeAssessed(payload) {
    console.log('==== ASSIGNEMENT TO BE ASSESSED <<<<<<<')
    console.log(payload)
    this.writtenassignmenttobeassessed = payload
  },
  addAssignmentComments(payload) {
    console.log('addAssignmentComments GOT CALLED is (%j)', payload)
    this.assignmentcomments = []
    if (Array.isArray(payload)) {
      this.assignmentcomments = payload
    } else {
      this.assignmentcomments.push(payload)
    }
    console.log(
      'addAssignmentComments length is (%s)',
      this.assignmentcomments.length
    )
  },
  deleteAssignmentComments(payload) {
    console.log('deleteAssignemntComments got called (%J)', payload)
    console.log(this.assignmentcomments)
    let newList = []
    if (this.assignmentcomments.length > 0) {
      for (let i = 0; i < this.assignmentcomments.length; i++) {
        let singleComment = this.assignmentcomments[i]
        if (
          singleComment.markedText !== payload.markedText &&
          singleComment.markedIndex !== payload.markedIndex
        ) {
          newList.push(singleComment)
        } else if (
          singleComment.location !== payload.location &&
          singleComment.orgtext !== payload.orgtext
        ) {
          newList.push(singleComment)
        }
      }
    }
    this.assignmentcomments = []
    this.assignmentcomments = newList
  },
  addGrammarFeedback(payload) {
    console.log('addGrammarFeedback got called (%J)', payload)
    this.grammarfeedback.push(payload)
  },
  deleteGrammarFeedback(payload) {
    console.log('deleteGrammarFeedback got called (%J)', payload)
    // console.log(state.grammarfeedback)
    let newList = []
    if (this.grammarfeedback.length > 0) {
      for (let i = 0; i < this.grammarfeedback.length; i++) {
        let singleComment = this.grammarfeedback[i]
        if (
          singleComment.offset === payload.offset &&
          singleComment.length === payload.length
        ) {
          console.log(
            'we have match and deletes (%s) (%s)',
            singleComment.offset,
            payload.length
          )
        } else {
          newList.push(singleComment)
        }
      }
    }
    console.log(newList)
    this.grammarfeedback = []
    this.grammarfeedback = newList
  },
  setNotAssessed(payload) {
    this.notassessed = payload
  },
  setLearnerAssess(payload) {
    console.log('===== SET LEARNER ASSESSS MUTATION (%j)', payload)
    this.learnerassess.push(payload)
  },
  clearLearnerAssess() {
    this.learnerassess = []
  },
  setOnlyV4L(payload) {
    this.onlyV4L = payload
  },
  /*
  setPlagiarismScanId(payload) {
    this.plagiarismscanid = payload
  },
  setPlagiarismData( payload) {
    this.plagiarismdata = payload
  },
  */
  setOpenGuidancePanel(payload) {
    this.openguidancepanel = payload
  },
  addOne() {
    this.addone++
  },
  setLearnerWrittenAssignmentUid(payload) {
    this.learnerwrittenassignment = payload
  },
  resetAssessedAssignment() {
    this.learnerassess = []
    this.grammarfeedback = []
    this.assignmentcomments = []
    this.writtenassignmenttobeassessed = {}
  },
  setAFLAssignmentsMap(payload) {
    console.log('setAFLAssignmentsMap got called(%j)', payload)
    let id = payload.id
    let data = payload.data
    this.aflassignmentsmap.set(id, data)
    console.log(this.aflassignmentsmap)
  },
  setAi4AFL(payload) {
    let educator_userid = payload.educator_userid
    this.ai4afl = educator_userid.includes('@haldenskole.no')
    /*
      For testing we also need to check for testusers.
      Since HIOF team are using testusers...
    */
    if (!this.ai4afl) {
      this.ai4afl = educator_userid.includes('@testusers.feide.no')
    }
  },
  setCallback(payload) {
    this.callback = payload
  },
  setCallbackState(payload) {
    this.callbackstate = payload
  },
  setLogInProcess(payload) {
    this.loginprocess = payload
  },
  setExperimental(payload) {
    this.experimental = payload
  },
  /* ************************************** */
  /*        NEW DESIGN FROM NICHLAS         */
  /* ************************************** */
  setActiveNavItem(payload) {
    this.activeNav = payload
  },
  toggleTheme() {
    this.theme = 'light'
  },
  setThemeLight() {
    this.theme = 'light'
  },
  setThemeDark() {
    this.theme = 'dark'
  }
}

/** SUPPORT FUNCTIONS ***/

async function updateEducatorInfo(educatorInfo, payload) {
  return new Promise((resolve) => {
    let updatedUid = payload.uid
    let updatedStatus = payload.commonsubjectgroup_active
    console.log('UPDATE EDUCATOR INFO GOT CALLED !')
    for (let i = 0; i < educatorInfo.educatorHasSubject.length; i++) {
      if (
        updatedUid ===
        educatorInfo.educatorHasSubject[i].educatorHasCommonSubjectGroup.uid
      ) {
        educatorInfo.educatorHasSubject[
          i
        ].educatorHasCommonSubjectGroup.commonsubjectgroup_active =
          updatedStatus
        break
      }
    }
    resolve(educatorInfo)
  })
  //let schoolSubjects = educatorInfo.
}

function createJwtTokenForClient(payload) {
  console.log('createJwtTokenForClient got called')
  console.log(JSON.stringify(payload))
  let aStore = adminStore()
  return new Promise((resolve, reject) => {
    try {
      let tokenPayload = {
        userid: payload.userid,
        affiliation: payload.eduPersonAffiliation,
        accessToken: payload.encodedAccessToken,
        payload: payload
        //  expires: payload.expires
      }
      // console.log('tokenPayload : ', tokenPayload)
      getServerSecret()
        .then((result) => {
          console.log('GET SERVER SECRET RETURNED : (%j)', result)
          let publicKey = result.publicKey
          let serverSecret = result.secret
          aStore.setSid(result.sid)
          tokenPayload.serverSecret = result.secret
          tokenPayload.sessionid = result.sid
          let hypJWE = new CreateHypatiaJWE(
            tokenPayload,
            serverSecret,
            null,
            publicKey
          )
          let encrypted = ''

          hypJWE
            .setUp()
            .then(() => {
              hypJWE
                .encrypt(JSON.stringify(tokenPayload))
                .then((encrypts) => {
                  encrypted = encrypts
                  console.log(tokenPayload)
                  console.log('==== ENCRYPTED ====')
                  console.log(encrypted)
                })
                .then(() => {
                  let cryptedPayload = {
                    cryptedPayload: encrypted
                  }
                  console.log('CRYPTED PAYLOAD (%s)', result.sid)
                  console.log(cryptedPayload)
                  hypJWE.sign(cryptedPayload, serverSecret).then((token) => {
                    resolve({
                      encryptedPayload: encrypted,
                      accessToken: token,
                      publicKey: publicKey,
                      secret: serverSecret,
                      sid: result.sid
                    })
                  }) // sign
                }) // encrypt
                .catch((error) => {
                  console.error(
                    '*** ERROR *** problems encrypting the payload : ' + error
                  )
                  reject({
                    error: true,
                    errorMsg:
                      '*** ERROR *** problems encrypting the payload : ' + error
                  })
                }) // encrypt catch
            }) // setup
            .catch((error) => {
              console.error(
                '*** ERROR *** hypJWE.setup had some problems',
                error
              )
              reject(
                new Error('*** ERROR *** hypJWE.setup had some problems', error)
              )
            })
          /*
          hypJWE.decrypt(encrypted)
            .then(decrypted => {
              console.log('==== DECRYPTED ====')
              console.log(decypted)
            })
            */
        }) // secret
        .catch((error) => {
          console.error(
            '*** ERROR *** problems getting the secret from the server : ' +
              error
          )
          reject({
            error: true,
            errorMsg:
              '*** ERROR *** problems getting the secret from the server : ' +
              error
          })
        }) // getServerSecret - catch
    } catch (error) {
      console.error(
        '*** ERROR **** createJwtTokenForClient, had some problems ' + error
      )
      reject({
        errorMsg:
          '*** ERROR **** createJwtTokenForClient, had some problems ' + error
      })
    }
  }) // promise
}

/* Parses the string 'fc:org:spusers.feide.no:unit:NO856326499' and returns NO856326499 */
function getOrgNIN(orgNin) {
  let strSplit = orgNin.split(':')
  if (strSplit.length !== 5) {
    console.error('WRONG NUMBER OF PARAMS IN getOrgNIN !')
    throw new Error('WRONG NUMBER OF PARAMS IN getOrgNIN  ')
  }
  return strSplit[4]
}

async function getServerSecret() {
  //  console.log('GET SERVER Secret GOT CALLED !')
  //  console.log('=== getServerSecret ')
  return new Promise((resolve, reject) => {
    let aStore = adminStore()
    aStore
      .getHTTP('sysinfo/jwtsecret')
      .then((result) => {
        console.log('JwtSecret er: (%j)', result)
        //  localStorage.setItem('hypsaserversecret', result.data.secret)
        console.log('publicKey: ' + result.data.publicKey)
        resolve(result.data) // {'secret': something }
      })
      .catch((error) => {
        console.error('*** ERROR *** getServerSECRET had some errors:', error)
        reject(
          new Error({
            error: error
          })
        )
      })
  }) // promise
}

function getUids(result) {
  console.log('getUids got called ')
  console.log(JSON.stringify(result.data.dgraphresult.arr_[0]))
  let newItem = result.data.dgraphresult.arr_
  if (newItem !== undefined) {
    console.log('NEW ITEM (%s)', JSON.stringify(newItem))
    let ni = newItem[0]
    if (ni !== undefined) {
      return ni[1]
    }
    return ''
  }
}

function isHigherEd(payload) {
  console.log('IS HIGHER ED GOT CALLED (%j)', payload)
  let oGroups = this.oGroups
  if (oGroups.length > 0) {
    for (let i = 0; i < oGroups.length; i++) {
      let group = oGroups[i]
      if (group.orgType.length > 0) {
        for (let j = 0; j < group.orgType.length; j++) {
          let og = group.orgType[j]
          if (og === 'higher_education') {
            toast.info('--- NWE HAVE HE --- (' + group.displayName + ')')
            return true
          }
        }
      }
    }
  }
  return false
}
